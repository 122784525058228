import {
    LOGGING_USER,
    FOUND_RESTRICTED,
    LOGIN_SUCCESS
  } from "../constants";
  
  export function loginFormMiddleware({ dispatch }) {
    return function (next) {
      return function (action) {
        if (action.type === LOGGING_USER) {
          console.log(action);
          console.log(action.user.email);
          switch (action.user.email) {
            case "loading":
              return dispatch({ type: FOUND_RESTRICTED, payload: { form_state: 'loading', title: '', message: '' } });
            case "error":
              return dispatch({ type: FOUND_RESTRICTED, payload: { form_state: 'error', title: 'Error', message: 'Please include a valid username and a password' } });
            case "success":
              return dispatch({ type: FOUND_RESTRICTED, payload: { form_state: 'success', title: '', message: '' } });
            default:
              return next(action);
          }
        }
        return next(action);
      };
    };
  }

  export async function  _generate_email(url='',personalizations={}) {
    const newUrl = 'https://ra.seippf.org/mail/sendgrid.php';
    const response = await fetch(newUrl, {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'       
      },
      redirect: 'follow',
      referrer: 'no-referrer', 
      body: JSON.stringify({"personalizations": personalizations})
    });
    return await response.json();
  }
