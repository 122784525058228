import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DashboardMain from './Dashboard/DashboardMain';
import Sections from "./Dashboard/Sections";
import UserMain from './Dashboard/Users/UserMain';
import { logoutUser } from '../../services/CacheOperations';
import { Image } from 'semantic-ui-react';
import { hist } from './../../App';
import SubmissionsMain from './Dashboard/Users/SubmissionsMain';
import { withNamespaces } from "react-i18next";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "dashboard"
    };
    console.log(localStorage.RAT_LANG)
  }

  handleLinkClick = e => {
    console.log("Click handled");
    console.log(e.target.name);
    switch (e.target.name) {
      case 'dashboard':
        window.location.href = '/';
        break;
      case 'sections':
        window.location.href = '/sections';
        break;
      case 'logout':
        logoutUser();
        window.location.href = '/';
        break;
      case 'users':
        window.location.href = '/users';
        break;
      case 'submissions':
        window.location.href = '/submissions';
        break;
      default:
        break;
    }
  };



  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        {/* Begin Sidebar */}
        <div className="admin-side" id="admin-side">
          <a className="admin-logo text-center" style={{ display: "block", backgroundColor: "#fff", padding: "15px 5px" }}>
            <Image className="adm-logo" style={{ width: "100%", margin: "0 auto", maxWidth:"340px" }} src={require('../../assets/images/admin_site_logo.png')} fluid />
          </a>
          <ul data-simplebar>
            <li>
              <a name="dashboard" onClick={this.handleLinkClick} style={{fontSize:"18px"}}>
                <i className="fas fa-home fa-fw"></i>{t("Dashboard")}
              </a>
            </li>
            <li>
              <a name="users" onClick={this.handleLinkClick} style={{fontSize:"18px"}}>
                <i className="fas fa-user fa-fw"></i>{t("User Profiles")}
              </a>
            </li>
            <li>
              <a name="submissions" onClick={this.handleLinkClick} style={{fontSize:"18px"}}>
                <i className="fa fa-paper-plane fa-fw"></i>{t("Submissions")}
              </a>
            </li>
            <li>
              <a name="manual" href="http://ra.seippf.org/adminguide/" target="_blank" style={{fontSize:"18px"}}>
                <i className="fas fa-file fa-fw"></i>{t("User Manual")}
              </a>
            </li>
          </ul>
        </div>
        {/* End Sidebar */}

        {/* Begin Mobile Header */}
        <div class="admin-mobile-headder uk-hidden@m">
          <span
            uk-toggle="target: #admin-side; cls: admin-side-active"
            class="uk-padding-small uk-text-white uk-float-right"
          >
            <i class="fas fa-bars"></i>
          </span>
          <a className="uk-logo">
            <Image className="ippf-logo adm-logo" style={{ width: "160px" }} src={require('../../assets/images/ippf-logo.png')} fluid />
          
          </a>
          {/* <a class="uk-logo" href="/">
            {" "}
            <i class="fas fa-graduation-cap"> </i> RAT{" "}
          </a> */}
        </div>
        {/* End Mobile Header */}

        {/* Begin Main Content */}
        <Router>
          <Route exact path="/" component={DashboardMain} />
          <Route path="/sections" component={Sections} />
          <Route path='/users' component={UserMain} />
          <Route path='/submissions' component={SubmissionsMain} />
        </Router>
        {/* End Main Content */}
      </div>
    );
  }
}

export default withNamespaces()(Home);
