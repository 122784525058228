import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from 'react-redux';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from "../../actions/user-actions"
import { USER_LOGIN, RESET_PASSWORD, VERIFY_PASSCODE, UPDATE_PASSWORD } from "../../queries/UserQueries";
import { ADD_MA_USER } from "../../queries/UserQueries";

import { _generate_email } from '../../middleware/index';
import zxcvbn from 'zxcvbn';
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    const { minStrength = 3, thresholdLength = 7 } = props;

    this.minStrength = typeof minStrength === 'number'
      ? Math.max(Math.min(minStrength, 4), 0)
      : 3;

    this.thresholdLength = typeof thresholdLength === 'number'
      ? Math.max(thresholdLength, 7)
      : 7;
    this.state = {
      email: "",
      pcode: "",
      loading: false,
      loadingPasscode: false,
      updatingPassword: false,
      formSwitcher: 0,
      userID: "",
      password: "",
      confPassword: '',
      strength: 0,
      minStrength: 3,
      thresholdLength: 7,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
  }
  updatePassword = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ updatingPassword: true });
    const { password, confPassword, userID } = this.state;
    const { t, i18n } = this.props;
    console.log(userID);
    if (password !== confPassword) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG=='ar' ? (
          <div style={{paddingRight:"15px"}}>
            <span>{t("Passwords do not match!")}</span>
          </div>
        ) : (
          <div>
            <span>{t("Passwords do not match!")}</span>
          </div>
        )

      });
      this.setState({ updatingPassword: false });
      return;
    }

    if(this.state.password.length <= this.state.thresholdLength ){
      this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: 
        localStorage.RAT_LANG=="ar" ? (
            <div style={{paddingRight:"20px"}}> { this.props.t('password_validity') } </div>
          ):(
            <div> { this.props.t('password_validity') } </div>
          )
          
                 
      });
      this.setState({ loading: false });
      return;
  }
  
    this.reset_password(password, userID).then(result => {
      console.log(result);

      this.setState({
        updatingPassword: false,
      });
      setTimeout(() => {
        this.props.history.push('/');
      }, 3000);
      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: localStorage.RAT_LANG=='ar'? (
          <div style={{paddingRight:"15px"}} ><span>{t("Password Updated Successfully!")}</span></div>
        ):(
          <div><span>{t("Password Updated Successfully!")}</span></div>
        )
      });

    }).catch(error => {
      if (error) {
        this.setState({ updatingPassword: false });
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message:
            <span>{t("There was an error while trying to update password.")}</span>

        });
      }
    });
  }
  checkPasscode = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ loadingPasscode: true });
    const { pcode, userID } = this.state;
    const { t, i18n } = this.props;
    const passscode = parseInt(pcode);
    this.verify_passcode(passscode, userID).then(result => {
      console.log(result);
      this.setState({ loadingPasscode: false });
      if (result.status === true) {
        this.setState({
          formSwitcher: 2
        });
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message: localStorage.RAT_LANG == 'ar' ? (
            <div style={{ paddingRight: "15px" }}>
              <span >{t("Passcode verified!")}</span>
            </div>
          ) : (
            <div>
              <span >{t("Passcode verified!")}</span>
            </div>
          )
        });
      } else {
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: localStorage.RAT_LANG=='ar'? (
            <div style={{paddingRight:"15px"}}> <span>{t("Invalid Passcode!")}</span></div>
          ): (
            <div> <span>{t("Invalid Passcode!")}</span></div>
          )
        });
      }
    }).catch(error => {
      this.setState({ loadingPasscode: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG=='ar'? (
          <div style={{paddingRight:"15px"}}><span>{t("Invalid Passcode!")}</span></div>
        ):(
          <div><span>{t("Invalid Passcode!")}</span></div>
        )
      });
      console.error('onRejected function called: ' + error.message);
    });
  }

  handleSubmit = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    this.setState({ loading: true });
    const { email } = this.state;
    const { t, i18n } = this.props;
    this.request_reset_password(email).then(result => {
      const email = result.userEmail;
      const name = result.userName;
      const passscode = result.psscode;
      const userId = result.userId;
      // const userLang = result.languagePreference ? result.languagePreference : 'en';
      const personalizations = {
        "personalizations": [
          {
            "to": [
              {
                "email": email,
                "name": name
              }
            ],
            "bcc": [
              // {
              //   "email": "chameera.lakshitha212@gmail.com",
              //   "name": "Lakshitha"
              // },
              {
                "email": "rishi@fpasrilanka.org",
                "name": "Rishikeshan Thiyagaraja"
              },
              {
                "email": "narthana.se@gmail.com",
                "name": "Narthana Selvachothy"
              }
              // {
              //   "email": "manjula@fpasrilanka.org",
              //   "name": "Manjula Chathuranga Liyanage"
              // }
            ],
            "dynamic_template_data": {
              "name": name,
              "psscode": passscode
            },
            "subject": "SE Readiness Assessment Tool – Password reset"
          }
        ],
        // COMMENT KEY - C-01/06/2021
        // "template_id": "d-9303bc38988f408fb9276ddd1d957cb7"
        "template_id": localStorage.RAT_LANG === 'en' ? "d-9303bc38988f408fb9276ddd1d957cb7"
          : localStorage.RAT_LANG === 'ar' ? "d-68dac9f0649349a88e1d28e6947f0e52"
            : localStorage.RAT_LANG === 'fr' ? "d-aa3d4e41d0204900be9b0a8c9d4713ca"
              : localStorage.RAT_LANG === 'sp' ? "d-0747b555718c42bfabd063dfcb2ff58a"
                : "d-9303bc38988f408fb9276ddd1d957cb7"
      };
      // "template_id": "d-fa9e354fd453451f989158c6dcbccacb"
      try {
        const data = _generate_email('https://ra.seippf.org/mail/mail.php', personalizations);
        this.setState({
          loading: false,
          formSwitcher: 1,
          userID: userId
        })
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message: <div className={localStorage.RAT_LANG == "sp" ? "Spanish_style" : ""} style={localStorage.RAT_LANG == "ar" ? (styles.success_alert_Ar) : (styles.success_alert)} >
            <p style={{ margin: "0", paddingBottom: "5px" }}> {t("A passcode has been sent to your email.")} </p>
            <p style={{ margin: "0" }}>{t("Please check your email & follow the instructions.")}</p>
          </div>
          //  "A passcode has been sent to your email. Please check your email & follow the instructions."
        });
      } catch (error) {
        console.error(error);
      }
    }).catch(error => {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "",
        message: <span style={localStorage.RAT_LANG == "ar" ? (styles.success_alert_Ar):(styles.success_alert)} >{t("Incorrect email!")}</span>
      });
      console.error('onRejected function called: ' + error.message);
    })
  };


  _handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  passwordChange = (event) => {
    this.setState({
      password: event.target.value,
      strength: zxcvbn(event.target.value).score,
    })
  };

  reset_password = async (password, userID) => {
    const result = await this.props.client.mutate({
      mutation: UPDATE_PASSWORD,
      variables: { password, userID },
    });
    return result.data.updatePassword;
  };

  verify_passcode = async (passcode, userId) => {
    const result = await this.props.client.query({
      query: VERIFY_PASSCODE,
      variables: { passcode, userId },
    });
    return result.data.checkPasscode;
  };

  request_reset_password = async (email) => {
    const result = await this.props.client.mutate({
      mutation: RESET_PASSWORD,
      variables: { email },
    });
    return result.data.resetPasswordRequest;
  };


  loginUser = async () => {
    const { email, password } = this.state;
    const result = await this.props.client.mutate(
      {
        mutation: USER_LOGIN,
        variables: { email, password },
      })
    return result;
  }


  render() {
    const { formErrorStatus, formSuccessState, validator, children, t, i18n, ...restProps } = this.props;
    const { formSwitcher, strength, password } = this.state;
    const passwordLength = password.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;

    const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();
    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();

    return (
      <div>
        <div className="login_container uk-margin-medium-top" style={{ paddingLeft: 0 }}>
          {/* <br /> */}
          <div>
            {(formSwitcher === 0) ? (
              <Form error success onSubmit={this.handleSubmit} >
                <h2 className="uk-text-bold uk-text-center loginTitle">{t("Forgot Your Password")} </h2>
                {/* <div className="uk-form-label Login_text"> Official Email Address<i style={styles.icon} className="icon red">*</i></div> */}
                <div className="uk-margin">
                  <input
                    name="email"
                    id="email"
                    value={this.state.email}
                    className="uk-input uk-form-width-large"
                    placeholder={t("Official Email Address")}
                    type="email"
                    onChange={this._handleChange}
                    required
                  />
                </div>
                <div className="uk-margin uk-text-center">
                  <input
                    id="btn-resetPassword"
                    className="uk-button ui positive button uk-width-2-3"
                    type="submit"
                    value={t("Send me a passcode")}
                    style={{ marginBottom: "10px", fontSize:"16px" }}
                    loading={this.state.loading}
                  />
                </div>
                {(formErrorStatus.status) ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                  // <Message
                  //   error
                  //   content={formErrorStatus.message}
                  // />
                ) : ((formSuccessState.status) ? (
                  <div className="success_alert">{formSuccessState.message}</div>
                  // <Message
                  //   success
                  //   content={formSuccessState.message}
                  // />
                ) : (''))}
              </Form>
            ) : ("")}

            {(formSwitcher === 1) ? (

              <Form error success onSubmit={this.checkPasscode} >
                <h2 className="uk-text-bold uk-text-center loginTitle">{t("Type Your Passcode")} </h2>
                {/* <p> Don't worry re-setting your password is easy.</p> */}
                {/* <div className="uk-form-label Login_text"> Your Passcode<i style={styles.icon} className="icon red">*</i></div> */}
                <div className="uk-margin">
                  <input
                    name="pcode"
                    id="pcode"
                    value={this.state.pcode}
                    className="uk-input uk-form-width-large"
                    placeholder={t("Passcode")}
                    type="number"
                    onChange={this._handleChange}
                    required
                  />
                </div>
                <div className="uk-margin uk-text-center">
                  <input
                    id="btn-resetPassword"
                    className="uk-button ui positive button uk-margin uk-width-2-3"
                    type="submit"
                    value={t("Check my passcode")}
                    style={{ marginBottom: "10px", fontSize:"16px" }}
                    loading={this.state.loadingPasscode}
                  />
                </div>
                {(formErrorStatus.status) ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                  // <Message
                  //   style={{ margin: 0 }}
                  //   error
                  //   content={formErrorStatus.message}
                  // />
                ) : ((formSuccessState.status) ? (
                  <div className="success_alert">{formSuccessState.message}</div>
                  // <Message
                  //   style={{ margin: 0 }}
                  //   success
                  //   content={formSuccessState.message}
                  // />
                ) : (''))}
              </Form>
            ) : ("")}

            {(formSwitcher === 2) ? (

              <Form error success onSubmit={this.updatePassword} >
                <h2 className="uk-text-bold uk-text-center loginTitle">{t("Update Your Password")} </h2>
                <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
                  <span style={{ fontSize: "12px", color: "#ffffff" }} className="d-block form-hint">{t("To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.")}</span>
                  {children}
                  <div className={strengthClass}>
                    <div className="strength-meter-fill" data-strength={strength}></div>
                  </div>
                </Form.Field>
                <div style={{ marginBottom: "12px" }}>
                  <input
                    name="password"
                    id="password"
                    value={this.state.password}
                    className="uk-input uk-form-width-large"
                    placeholder={t("New Password")}
                    type="password"
                    onChange={this.passwordChange}
                    required
                  />
                </div>

                {/* <div className="uk-form-label Login_text"> Confirm Password<i style={styles.icon} className="icon red">*</i></div> */}
                <div style={{ marginBottom: "12px" }}>
                  <input
                    name="confPassword"
                    id="confPassword"
                    value={this.state.confPassword}
                    className="uk-input uk-form-width-large"
                    placeholder={t("Password")}
                    type="password"
                    onChange={this._handleChange}
                    required
                  />
                </div>
                <div className="uk-text-center" style={{ marginBottom: "6px" }}>
                  <input
                    id="btn-resetPassword"
                    className={localStorage.RAT_LANG == "fr" ? "uk-button ui positive button uk-margin uk-width-2-3  uk-text-center French_updatePW" : "uk-button ui positive button uk-margin uk-width-2-3  uk-text-center"}
                    type="submit"
                    value={t("Update my password")}
                    // style={{ marginBottom: "10px" }}
                    style={{fontSize:"16px"}}
                    loading={this.state.loadingPasscode}
                  />
                </div>
                {(formErrorStatus.status) ? (
                  <div className="danger_alert">{formErrorStatus.message}</div>
                ) : ((formSuccessState.status) ? (
                  <div className="success_alert">{formSuccessState.message}</div>
                ) : (''))}
              </Form>
            ) : ("")}
            {this.state.loading ? (<div class="spinner"></div>) : ("")}
            {this.state.loadingPasscode ? (<div class="spinner"></div>) : ("")}
            {this.state.updatingPassword ? (<div class="spinner"></div>) : ("")}

            {(formSwitcher === 2) ? ("") : (
              <div>
                <p className="uk-text-center Login_text" style={{ marginTop: 0 }}>
                  {t("Already Registered?")} <button onClick={() => { window.location.href = '/' }} class="uk-button uk-button-text Login_text verticalAlign"> {t("Log in Here")} </button>
                </p>
                <p className="uk-text-center Login_text" style={{ marginTop: 0 }}>
                  {t("Don't Have an Account Yet?")} <button onClick={() => { window.location.href = '/register' }} class="uk-button uk-button-text Login_text verticalAlign"> {t("Register Here")} </button>
                </p>
              </div>
            )}

          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  icon: {
    width: "0px"
  },
  success_alert: {
    textAlign: "left"
  },
  success_alert_Ar: {
    textAlign: "right",
    paddingRight: "20px"
  },
};
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ForgotPassword))));


