import React, { Component } from "react";
import { Form, Dropdown, Message, Button } from "semantic-ui-react";
import { connect } from 'react-redux';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from '../../actions/user-actions';
import { ADD_MA_USER } from "../../queries/UserQueries";
import ReactPasswordStrength from 'react-password-strength';
import {
  FIRST_NAME,
  LAST_NAME,
  AUTH_TOKEN,
  USER_NAME
} from "../../constants/index";
import { _generate_email } from '../../middleware';
import zxcvbn from 'zxcvbn';
import { withNamespaces } from "react-i18next";

var countryOptions = [];
// central office ma name = International Planned Parenthood Federation
var maOptions = [];

const maList = {
  "ctr1": [
    { "key": "ma1", "value": "Association Béninoise pour la Promotion de la Famille", "text": "Association Béninoise pour la Promotion de la Famille" },
    // { "key": "ma10", "value": "Test2", "text": "Test2 " }
  ],
  "ctr2": [
    { "key": "ma2", "value": "Botswana Family Welfare Association", "text": "Botswana Family Welfare Association" }
  ],
  "ctr3": [
    { "key": "ma3", "value": "Association Burkinabé pour le Bien-Etre Familial", "text": "Association Burkinabé pour le Bien-Etre Familial" }
  ],
  "ctr4": [
    { "key": "ma4", "value": "Association Burundaise pour le Bien-Etre Familial", "text": "Association Burundaise pour le Bien-Etre Familial" }
  ],
  "ctr5": [
    { "key": "ma5", "value": "Cameroon National Association for Family Welfare", "text": "Cameroon National Association for Family Welfare" }
  ],
  "ctr6": [
    { "key": "ma6", "value": "Associação Caboverdiana para a Proteção da Familia", "text": "Associação Caboverdiana para a Proteção da Familia" }
  ],
  "ctr7": [
    { "key": "ma7", "value": "Association Centrafricaine pour le Bien-Etre Familial", "text": "Association Centrafricaine pour le Bien-Etre Familial" }
  ],
  "ctr8": [
    { "key": "ma8", "value": "Association Tchadienne pour le Bien-Etre Familial", "text": "Association Tchadienne pour le Bien-Etre Familial" }
  ],
  "ctr9": [
    { "key": "ma9", "value": "Association Comorienne pour le Bien-Etre de la Famille", "text": "Association Comorienne pour le Bien-Etre de la Famille" }
  ],
  "ctr10": [
    { "key": "ma10", "value": "Association Congolaise pour le Bien-Etre Familial", "text": "Association Congolaise pour le Bien-Etre Familial" }
  ],
  "ctr11": [
    { "key": "ma11", "value": "Association pour le Bien-Etre Familial/Naissances Désirables", "text": "Association pour le Bien-Etre Familial/Naissances Désirables" }
  ],
  "ctr12": [
    { "key": "ma12", "value": "Association Ivoirienne pour le Bien-Etre Familial", "text": "Association Ivoirienne pour le Bien-Etre Familial" }
  ],
  "ctr13": [
    { "key": "ma13", "value": "Family Life Association of Eswatini", "text": "Family Life Association of Eswatini" }
  ],
  "ctr14": [
    { "key": "ma14", "value": "Family Guidance Association of Ethiopia", "text": "Family Guidance Association of Ethiopia" }
  ],
  "ctr15": [
    { "key": "ma15", "value": "Planned Parenthood Association of Ghana", "text": "Planned Parenthood Association of Ghana" }
  ],
  "ctr16": [
    { "key": "ma16", "value": "Associação Guineense para o Bem Estar Familiar", "text": "Associação Guineense para o Bem Estar Familiar" }
  ],
  "ctr17": [
    { "key": "ma17", "value": "Association Guinéenne pour le Bien-Etre Familial", "text": "Association Guinéenne pour le Bien-Etre Familial" }
  ],
  "ctr18": [
    { "key": "ma18", "value": "Lesotho Planned Parenthood Association", "text": "Lesotho Planned Parenthood Association" }
  ],
  "ctr19": [
    { "key": "ma19", "value": "Fianakaviana Sambatra", "text": "Fianakaviana Sambatra" }
  ],
  "ctr20": [
    { "key": "ma20", "value": "Family Planning Association of Malawi", "text": "Family Planning Association of Malawi" }
  ],
  "ctr21": [
    { "key": "ma21", "value": "Association Malienne pour la Protection et la Promotion de la Famille", "text": "Association Malienne pour la Protection et la Promotion de la Famille" }
  ],
  "ctr22": [
    { "key": "ma22", "value": "Associação Moçambicana para Desenvolvimento da Família", "text": "Associação Moçambicana para Desenvolvimento da Família" }
  ],
  "ctr23": [
    { "key": "ma23", "value": "Namibia Planned Parenthood Association", "text": "Namibia Planned Parenthood Association" }
  ],
  "ctr24": [
    { "key": "ma24", "value": "Association Nigérienne pour le Bien-Etre Familial", "text": "Association Nigérienne pour le Bien-Etre Familial" }
  ],
  "ctr25": [
    { "key": "ma25", "value": "Planned Parenthood Federation of Nigeria", "text": "Planned Parenthood Federation of Nigeria" }
  ],
  "ctr26": [
    { "key": "ma26", "value": "Planned Parenthood Association of Sierra Leone", "text": "Planned Parenthood Association of Sierra Leone" }
  ],
  "ctr27": [
    { "key": "ma27", "value": "Uzazi na Malezi Bora Tanzania", "text": "Uzazi na Malezi Bora Tanzania" }
  ],
  "ctr28": [
    { "key": "ma28", "value": "Association Togolaise pour le Bien-Etre Familial", "text": "Association Togolaise pour le Bien-Etre Familial" }
  ],
  "ctr29": [
    { "key": "ma29", "value": "Reproductive Health Uganda", "text": "Reproductive Health Uganda" }
  ],
  "ctr30": [
    { "key": "ma30", "value": "Planned Parenthood Association of Zambia", "text": "Planned Parenthood Association of Zambia" }
  ],
  "ctr31": [
    { "key": "ma31", "value": "Mauritius Family Planning & Welfare Association", "text": "Mauritius Family Planning & Welfare Association" }
  ],
  "ctr32": [
    { "key": "ma32", "value": "Associação Santomense para Promoção Familiar", "text": "Associação Santomense para Promoção Familiar" }
  ],
  "ctr33": [
    { "key": "ma33", "value": "Reproductive Health Network Kenya", "text": "Reproductive Health Network Kenya" }
  ],
  "ctr34": [
    { "key": "ma34", "value": "Community Healthcare Initiative", "text": "Community Healthcare Initiative" }
  ],
  "ctr35": [
    { "key": "ma35", "value": "Soul City Institute, Not for Profit Company", "text": "Soul City Institute, Not for Profit Company" }
  ],
  "ctr36": [
    { "key": "ma36", "value": "Health Development Initiative", "text": "Health Development Initiative" }
  ],
  "ctr37": [
    { "key": "ma37", "value": "Action pour le Développement", "text": "Action pour le Développement" }
  ],
  "ctr38": [
    { "key": "ma38", "value": "Alliance of Solidarity for the Family", "text": "Alliance of Solidarity for the Family" }
  ],
  "ctr39": [
    { "key": "ma39", "value": "Reproductive Health Association of South Sudan", "text": "Reproductive Health Association of South Sudan" }
  ],
  "ctr40": [
    { "key": "ma40", "value": "My Age Zimbabwe", "text": "My Age Zimbabwe" }
  ],
  "ctr41": [
    { "key": "ma41", "value": "Association Algérienne pour la Planification Familiale", "text": "Association Algérienne pour la Planification Familiale" }
  ],
  "ctr42": [
    { "key": "ma42", "value": "Bahrain Reproductive Health Association", "text": "Bahrain Reproductive Health Association" }
  ],
  "ctr43": [
    { "key": "ma43", "value": "Egyptian Family Planning Association", "text": "Egyptian Family Planning Association" }
  ],
  "ctr44": [
    { "key": "ma44", "value": "Lebanese Association for Family Health", "text": "Lebanese Association for Family Health" }
  ],
  "ctr45": [
    { "key": "ma45", "value": "Association Mauritanienne pour la Promotion de la Famille", "text": "Association Mauritanienne pour la Promotion de la Famille" }
  ],
  "ctr46": [
    { "key": "ma46", "value": "Association Marocaine de Planification Familiale", "text": "Association Marocaine de Planification Familiale" }
  ],
  "ctr47": [
    { "key": "ma47", "value": "Rahnuma-Family Planning Association of Pakistan", "text": "Rahnuma-Family Planning Association of Pakistan" }
  ],
  "ctr48": [
    { "key": "ma48", "value": "Palestinian Family Planning and Protection Association", "text": "Palestinian Family Planning and Protection Association" }
  ],
  "ctr49": [
    { "key": "ma49", "value": "Somaliland Family Health Association", "text": "Somaliland Family Health Association" }
  ],
  "ctr50": [
    { "key": "ma50", "value": "Sudan Family Planning Association", "text": "Sudan Family Planning Association" }
  ],
  "ctr51": [
    { "key": "ma51", "value": "Syrian Family Planning Association", "text": "Syrian Family Planning Association" }
  ],
  "ctr52": [
    { "key": "ma52", "value": "Association Tunisienne de la Santé de la Reproduction", "text": "Association Tunisienne de la Santé de la Reproduction" },
    { "key": "ma153", "value": "Center of Arab Women for Training and Research", "text": "Center of Arab Women for Training and Research" },


  ],
  "ctr53": [
    { "key": "ma53", "value": "Yemeni Association for Reproductive Health", "text": "Yemeni Association for Reproductive Health" }
  ],
  "ctr54": [
    { "key": "ma54", "value": "Safwa Charity Organization", "text": "Safwa Charity Organization" }
  ],
  "ctr55": [
    { "key": "ma55", "value": "Institute for Family Health", "text": "Institute for Family Health" }
  ],
  "ctr56": [
    { "key": "ma56", "value": "Reproductive Health Association of Cambodia", "text": "Reproductive Health Association of Cambodia" }
  ],
  "ctr57": [
    { "key": "ma57", "value": "China Family Planning Association", "text": "China Family Planning Association" }
  ],
  "ctr58": [
    { "key": "ma58", "value": "Cook Islands Family Welfare Association", "text": "Cook Islands Family Welfare Association" }
  ],
  "ctr59": [
    { "key": "ma59", "value": "Reproductive & Family Health Association of Fiji", "text": "Reproductive & Family Health Association of Fiji" }
  ],
  "ctr60": [
    { "key": "ma60", "value": "Family Planning Association of Hong Kong", "text": "Family Planning Association of Hong Kong" }
  ],
  "ctr61": [
    { "key": "ma61", "value": "The Indonesian Planned Parenthood Association", "text": "The Indonesian Planned Parenthood Association" }
  ],
  "ctr62": [
    { "key": "ma62", "value": "Kiribati Family Health Association", "text": "Kiribati Family Health Association" }
  ],
  "ctr63": [
    { "key": "ma63", "value": "Family Health Association of Korea", "text": "Family Health Association of Korea" }
  ],
  "ctr64": [
    { "key": "ma64", "value": "Korea Population, Health and Welfare Association", "text": "Korea Population, Health and Welfare Association" }
  ],
  "ctr65": [
    { "key": "ma65", "value": "Federation of Reproductive Health Associations Malaysia", "text": "Federation of Reproductive Health Associations Malaysia" }
  ],
  "ctr66": [
    { "key": "ma66", "value": "Mongolian Family Welfare Association", "text": "Mongolian Family Welfare Association" }
  ],
  "ctr67": [
    { "key": "ma67", "value": "New Zealand Family Planning", "text": "New Zealand Family Planning" }
  ],
  "ctr68": [
    { "key": "ma68", "value": "The Family Planning Organization of the Philippines Inc.", "text": "The Family Planning Organization of the Philippines Inc." }
  ],
  "ctr69": [
    { "key": "ma69", "value": "Samoa Family Health Association", "text": "Samoa Family Health Association" }
  ],
  "ctr70": [
    { "key": "ma70", "value": "Solomon Islands Planned Parenthood Association", "text": "Solomon Islands Planned Parenthood Association" }
  ],
  "ctr71": [
    { "key": "ma71", "value": "Planned Parenthood Association of Thailand", "text": "Planned Parenthood Association of Thailand" }
  ],
  "ctr72": [
    { "key": "ma72", "value": "Tonga Family Health Association", "text": "Tonga Family Health Association" }
  ],
  "ctr73": [
    { "key": "ma73", "value": "Tuvalu Family Health Association", "text": "Tuvalu Family Health Association" }
  ],
  "ctr74": [
    { "key": "ma74", "value": "Vanuatu Family Health Association", "text": "Vanuatu Family Health Association" }
  ],
  "ctr75": [
    { "key": "ma75", "value": "Vietnam Family Planning Association", "text": "Vietnam Family Planning Association" }
  ],
  "ctr76": [
    { "key": "ma76", "value": "Family Planning Alliance Australia", "text": "Family Planning Alliance Australia" }
  ],
  "ctr77": [
    { "key": "ma77", "value": "Papua New Guinea Family Health Association", "text": "Papua New Guinea Family Health Association" }
  ],
  "ctr78": [
    { "key": "ma78", "value": "Promotion of Family Health Association of Lao PDR", "text": "Promotion of Family Health Association of Lao PDR" }
  ],
  "ctr79": [
    { "key": "ma79", "value": "Myanmar Maternal and Child Welfare Association", "text": "Myanmar Maternal and Child Welfare Association" }
  ],
  "ctr80": [
    { "key": "ma80", "value": "Japanese Organization for International Cooperation in Family Planning", "text": "Japanese Organization for International Cooperation in Family Planning" }
  ],
  "ctr81": [
    { "key": "ma81", "value": "Qêndra pêr Popullsinë dhe Zhvillimin (Center for Population and Development)", "text": "Qêndra pêr Popullsinë dhe Zhvillimin (Center for Population and Development)" }
  ],
  "ctr82": [
    { "key": "ma82", "value": "Österreichische Gesellschaft für Familienplanung", "text": "Österreichische Gesellschaft für Familienplanung" }
  ],
  "ctr83": [
    { "key": "ma83", "value": "Belgische Federatie voor Seksuele en Reproductieve Gezondheid en Rechten (BFSRGR) / Fédération Belge pour la Santé et les Droits Sexuels et Reproductifs (FBSDSR)", "text": "Belgische Federatie voor Seksuele en Reproductieve Gezondheid en Rechten (BFSRGR) / Fédération Belge pour la Santé et les Droits Sexuels et Reproductifs (FBSDSR)" }
  ],
  "ctr84": [
    { "key": "ma84", "value": "Association XY", "text": "Association XY" }
  ],
  "ctr85": [
    { "key": "ma85", "value": "Bulgarian Family Planning and Sexual Health Association", "text": "Bulgarian Family Planning and Sexual Health Association" }
  ],
  "ctr86": [
    { "key": "ma86", "value": "Cyprus Family Planning Association", "text": "Cyprus Family Planning Association" }
  ],
  "ctr87": [
    { "key": "ma87", "value": "Sex & Samfund - The Danish Family Planning Association", "text": "Sex & Samfund - The Danish Family Planning Association" }
  ],
  "ctr88": [
    { "key": "ma88", "value": "Eesti Seksuaaltervise Liit / Estonian Sexual Health Association", "text": "Eesti Seksuaaltervise Liit / Estonian Sexual Health Association" }
  ],
  "ctr89": [
    { "key": "ma89", "value": "Väestöliitto", "text": "Väestöliitto" }
  ],
  "ctr90": [
    { "key": "ma90", "value": "Mouvement Français pour le Planning Familial", "text": "Mouvement Français pour le Planning Familial" }
  ],
  "ctr91": [
    { "key": "ma91", "value": "Association HERA XXI", "text": "Association HERA XXI" }
  ],
  "ctr92": [
    { "key": "ma92", "value": "PRO FAMILIA Bundesverband", "text": "PRO FAMILIA Bundesverband" }
  ],
  "ctr93": [
    { "key": "ma93", "value": "Irish Family Planning Association", "text": "Irish Family Planning Association" }
  ],
  "ctr94": [
    { "key": "ma94", "value": "Israel Family Planning Association", "text": "Israel Family Planning Association" }
  ],
  "ctr95": [
    { "key": "ma95", "value": "Kazakhstan Association on Sexual and Reproductive Health", "text": "Kazakhstan Association on Sexual and Reproductive Health" }
  ],
  "ctr96": [
    { "key": "ma96", "value": "Reproductive Health Alliance of Kyrgyzstan", "text": "Reproductive Health Alliance of Kyrgyzstan" }
  ],
  "ctr97": [
    { "key": "ma97", "value": "Papardes Zieds", "text": "Papardes Zieds" }
  ],
  "ctr98": [
    { "key": "ma98", "value": "Rutgers", "text": "Rutgers" }
  ],
  "ctr99": [
    { "key": "ma99", "value": "Sex og Politikk", "text": "Sex og Politikk" }
  ],
  "ctr100": [
    { "key": "ma100", "value": "Associação Para o Planeamento da Família", "text": "Associação Para o Planeamento da Família" }
  ],
  "ctr101": [
    { "key": "ma101", "value": "Health Education and Research Association", "text": "Health Education and Research Association" }
  ],
  "ctr102": [
    { "key": "ma102", "value": "Serbian Association for Sexual and Reproductive Rights", "text": "Serbian Association for Sexual and Reproductive Rights" }
  ],
  "ctr103": [
    { "key": "ma103", "value": "Societatea de Educatie Contraceptiva si Sexuala", "text": "Societatea de Educatie Contraceptiva si Sexuala" }
  ],
  "ctr104": [
    { "key": "ma104", "value": "Slovenská spolocnost pre plánované rodicovstvo a výchovu k rodi.", "text": "Slovenská spolocnost pre plánované rodicovstvo a výchovu k rodi." }
  ],
  "ctr105": [
    { "key": "ma105", "value": "Federación de Planificación Familiar de España", "text": "Federación de Planificación Familiar de España" }
  ],
  "ctr106": [
    { "key": "ma106", "value": "Riksförbundet för Sexuell Upplysning", "text": "Riksförbundet för Sexuell Upplysning" }
  ],
  "ctr107": [
    { "key": "ma107", "value": "SANTÉ SEXUELLE Suisse", "text": "SANTÉ SEXUELLE Suisse" }
  ],
  "ctr108": [
    { "key": "ma108", "value": "Tajik Family Planning Alliance", "text": "Tajik Family Planning Alliance" }
  ],
  "ctr109": [
    { "key": "ma109", "value": "NGO Women Health and Family Planning", "text": "NGO Women Health and Family Planning" }
  ],
  "ctr110": [
    { "key": "ma110", "value": "Association for human rights and civic participation PaRiter", "text": "Association for human rights and civic participation PaRiter" }
  ],
  "ctr111": [
    { "key": "ma111", "value": "EMMA Közhasznú Egyesület", "text": "EMMA Közhasznú Egyesület" },
    { "key": "ma154", "value": "Háttér Society", "text": "Háttér Society" },
  ],
  "ctr112": [
    { "key": "ma112", "value": "Associazione Italiana Donne per lo Sviluppo", "text": "Associazione Italiana Donne per lo Sviluppo" }
  ],
  "ctr113": [
    { "key": "ma113", "value": "Women Rights Foundation in Malta", "text": "Women Rights Foundation in Malta" }
  ],
  "ctr114": [
    { "key": "ma114", "value": "Health for Youth Association", "text": "Health for Youth Association" }
  ],
  "ctr115": [
    { "key": "ma115", "value": "Fundacja Ogólnopolski Strajk Kobiet (Polish Women’s Strike Foundation)", "text": "Fundacja Ogólnopolski Strajk Kobiet (Polish Women’s Strike Foundation)" },
    { "key": "ma155", "value": "Foundation for Women and Family Planning (FEDERA)", "text": "Foundation for Women and Family Planning (FEDERA)" }
  ],
  "ctr116": [
    { "key": "ma116", "value": "Institute 8th March / Zavod Raziskovalni Inštitut 8. marec", "text": "Institute 8th March / Zavod Raziskovalni Inštitut 8. marec" }
  ],
  "ctr117": [
    { "key": "ma117", "value": "Yuksak Salohiyat", "text": "Yuksak Salohiyat" }
  ],
  "ctr118": [
    { "key": "ma118", "value": "Afghan Family Guidance Association", "text": "Afghan Family Guidance Association" }
  ],
  "ctr119": [
    { "key": "ma119", "value": "Population Services and Training Center (PSTC)", "text": "Population Services and Training Center (PSTC)" }
  ],
  "ctr120": [
    { "key": "ma120", "value": "Family Planning Association of India", "text": "Family Planning Association of India" }
  ],
  "ctr121": [
    { "key": "ma121", "value": "Society for Health Education", "text": "Society for Health Education" }
  ],
  "ctr122": [
    { "key": "ma122", "value": "Family Planning Association of Nepal", "text": "Family Planning Association of Nepal" }
  ],
  "ctr123": [
    { "key": "ma123", "value": "Family Planning Association of Sri Lanka", "text": "Family Planning Association of Sri Lanka" }
  ],
  "ctr124": [
    { "key": "ma124", "value": "Respect Educate Nurture Empower Women", "text": "Respect Educate Nurture Empower Women" }
  ],
  "ctr125": [
    { "key": "ma125", "value": "The Barbados Family Planning Association", "text": "The Barbados Family Planning Association" }
  ],
  "ctr126": [
    { "key": "ma126", "value": "Asociación Pro-Bienestar de la Familia Colombiana", "text": "Asociación Pro-Bienestar de la Familia Colombiana" }
  ],
  "ctr127": [
    { "key": "ma127", "value": "Jamaica Family Planning Association", "text": "Jamaica Family Planning Association" }
  ],
  "ctr128": [
    { "key": "ma128", "value": "Fundación Mexicana para la Planeación Familiar A.C.", "text": "Fundación Mexicana para la Planeación Familiar A.C." }
  ],
  "ctr129": [
    { "key": "ma129", "value": "Instituto Peruano de Paternidad Responsable", "text": "Instituto Peruano de Paternidad Responsable" }
  ],
  "ctr130": [
    { "key": "ma130", "value": "Stichting Lobi", "text": "Stichting Lobi" }
  ],
  "ctr131": [
    { "key": "ma131", "value": "Family Planning Association of Trinidad and Tobago", "text": "Family Planning Association of Trinidad and Tobago" }
  ],
  "ctr132": [
    { "key": "ma132", "value": "Planned Parenthood Federation of America", "text": "Planned Parenthood Federation of America" },
    { "key": "ma156", "value": "White Ribbon Alliance", "text": "White Ribbon Alliance" }

  ],
  "ctr133": [
    { "key": "ma133", "value": "Antigua Planned Parenthood Association", "text": "Antigua Planned Parenthood Association" }
  ],
  "ctr134": [
    { "key": "ma134", "value": "Foundation for the Promotion of Responsible Parenthood (Aruba)", "text": "Foundation for the Promotion of Responsible Parenthood (Aruba)" }
  ],
  "ctr135": [
    { "key": "ma135", "value": "Centro de Investigación Educación y Servicios", "text": "Centro de Investigación Educación y Servicios" }
  ],
  "ctr136": [
    { "key": "ma136", "value": "Asociación chilena de Protección de la Familia", "text": "Asociación chilena de Protección de la Familia" }
  ],
  "ctr137": [
    { "key": "ma137", "value": "Dominica Planned Parenthood Association", "text": "Dominica Planned Parenthood Association" }
  ],
  "ctr138": [
    { "key": "ma138", "value": "Association Guadeloupéenne pour le Planning Familial", "text": "Association Guadeloupéenne pour le Planning Familial" }
  ],
  "ctr139": [
    { "key": "ma139", "value": "CEMOPLAF", "text": "CEMOPLAF" }
  ],
  "ctr140": [
    { "key": "ma140", "value": "Merundoi Incorporated", "text": "Merundoi Incorporated" }
  ],
  "ctr141": [
    { "key": "ma141", "value": "Foundation for the Promotion of Responsible Parenthood (FPRP)", "text": "Foundation for the Promotion of Responsible Parenthood (FPRP)" }
  ],
  "ctr142": [
    { "key": "ma142", "value": "Grenada Planned Parenthood Association", "text": "Grenada Planned Parenthood Association" }
  ],
  "ctr143": [
    { "key": "ma143", "value": "Association Martiniquaise pour I'Information et l'Orientation Familiales", "text": "Association Martiniquaise pour I'Information et l'Orientation Familiales" }
  ],
  "ctr144": [
    { "key": "ma144", "value": "Saint Lucia Planned Parenthood Association", "text": "Saint Lucia Planned Parenthood Association" }
  ],
  "ctr145": [
    { "key": "ma145", "value": "St. Vincent Planned Parenthood Association", "text": "St. Vincent Planned Parenthood Association" }
  ],
  "ctr146": [
    { "key": "ma146", "value": "Asociación Pro-Bienestar de la Familia de Guatemala", "text": "Asociación Pro-Bienestar de la Familia de Guatemala" }
  ],
  "ctr147": [
    { "key": "ma147", "value": "Asociación Venezolana para una Educación Sexual Alternativa", "text": "Asociación Venezolana para una Educación Sexual Alternativa" }
  ],
  "ctr148": [
    { "key": "ma148", "value": "Fundación Huésped", "text": "Fundación Huésped" }
  ],
  "ctr149": [
    { "key": "ma149", "value": "Ciudadania Estudo Pesquisa Informaçao e Açao", "text": "Ciudadania Estudo Pesquisa Informaçao e Açao" }
  ],
  "ctr150": [
    { "key": "ma150", "value": "Association des Sages-Femmes d'Haïti", "text": "Association des Sages-Femmes d'Haïti" }
  ],
  "ctr151": [
    { "key": "ma151", "value": "Sociedad Cientifica Cubana Para el Desarrollo de la Familia", "text": "Sociedad Cientifica Cubana Para el Desarrollo de la Familia" }
  ],
  "ctr152": [
    { "key": "ma152", "value": "Fundación Llaves", "text": "Fundación Llaves" }
  ],
}
const countryList = {
  rg1: [
    { "key": "ctr1", "value": "Benin", "text": "Benin", "ma": "Association Béninoise pour la Promotion de la Famille", "ma2": " Ma 2" },
    { "key": "ctr2", "value": "Botswana", "text": "Botswana", "ma": "Botswana Family Welfare Association" },
    { "key": "ctr3", "value": "Burkina Faso", "text": "Burkina Faso", "ma": "Association Burkinabé pour le Bien-Etre Familial" },
    { "key": "ctr4", "value": "Burundi", "text": "Burundi", "ma": "Association Burundaise pour le Bien-Etre Familial" },
    { "key": "ctr5", "value": "Cameroon", "text": "Cameroon", "ma": "Cameroon National Association for Family Welfare" },
    { "key": "ctr6", "value": "Cape Verde", "text": "Cape Verde", "ma": "Associação Caboverdiana para a Proteção da Familia" },
    { "key": "ctr7", "value": "Central African Republic", "text": "Central African Republic", "ma": "Association Centrafricaine pour le Bien-Etre Familial" },
    { "key": "ctr8", "value": "Chad", "text": "Chad", "ma": "Association Tchadienne pour le Bien-Etre Familial" },
    { "key": "ctr9", "value": "Comoros", "text": "Comoros", "ma": "Association Comorienne pour le Bien-Etre de la Famille" },
    { "key": "ctr10", "value": "Republic of the Congo", "text": "Republic of the Congo", "ma": "Association Congolaise pour le Bien-Etre Familial" },
    { "key": "ctr11", "value": "Democratic Republic of the Congo", "text": "Democratic Republic of the Congo", "ma": "Association pour le Bien-Etre Familial/Naissances Désirables" },
    { "key": "ctr12", "value": "Cote d’Ivoire", "text": "Cote d’Ivoire", "ma": "Association Ivoirienne pour le Bien-Etre Familial" },
    { "key": "ctr13", "value": "Eswatini", "text": "Eswatini", "ma": "Family Life Association of Eswatini" },
    { "key": "ctr14", "value": "Ethiopia", "text": "Ethiopia", "ma": "Family Guidance Association of Ethiopia" },
    { "key": "ctr15", "value": "Ghana", "text": "Ghana", "ma": "Planned Parenthood Association of Ghana" },
    { "key": "ctr16", "value": "Guinea-Bissau", "text": "Guinea-Bissau", "ma": "Associação Guineense para o Bem Estar Familiar" },
    { "key": "ctr17", "value": "Guinea-Conakry", "text": "Guinea-Conakry", "ma": "Association Guinéenne pour le Bien-Etre Familial" },
    { "key": "ctr18", "value": "Lesotho", "text": "Lesotho", "ma": "Lesotho Planned Parenthood Association" },
    { "key": "ctr19", "value": "Madagascar", "text": "Madagascar", "ma": "Fianakaviana Sambatra" },
    { "key": "ctr20", "value": "Malawi", "text": "Malawi", "ma": "Family Planning Association of Malawi" },
    { "key": "ctr21", "value": "Mali", "text": "Mali", "ma": "Association Malienne pour la Protection et la Promotion de la Famille" },
    { "key": "ctr22", "value": "Mozambique", "text": "Mozambique", "ma": "Associação Moçambicana para Desenvolvimento da Família" },
    { "key": "ctr23", "value": "Namibia", "text": "Namibia", "ma": "Namibia Planned Parenthood Association" },
    { "key": "ctr24", "value": "Niger", "text": "Niger", "ma": "Association Nigérienne pour le Bien-Etre Familial" },
    { "key": "ctr25", "value": "Nigeria", "text": "Nigeria", "ma": "Planned Parenthood Federation of Nigeria" },
    { "key": "ctr26", "value": "Sierra Leone", "text": "Sierra Leone", "ma": "Planned Parenthood Association of Sierra Leone" },
    { "key": "ctr27", "value": "Tanzania", "text": "Tanzania", "ma": "Uzazi na Malezi Bora Tanzania" },
    { "key": "ctr28", "value": "Togo", "text": "Togo", "ma": "Association Togolaise pour le Bien-Etre Familial" },
    { "key": "ctr29", "value": "Uganda", "text": "Uganda", "ma": "Reproductive Health Uganda" },
    { "key": "ctr30", "value": "Zambia", "text": "Zambia", "ma": "Planned Parenthood Association of Zambia" },
    { "key": "ctr31", "value": "Mauritius", "text": "Mauritius", "ma": "Mauritius Family Planning & Welfare Association" },
    { "key": "ctr32", "value": "Sao Tome & Principe", "text": "Sao Tome & Principe", "ma": "Associação Santomense para Promoção Familiar" },
    { "key": "ctr33", "value": "Kenya", "text": "Kenya", "ma": "Reproductive Health Network Kenya" },
    { "key": "ctr34", "value": "Liberia", "text": "Liberia", "ma": "Community Healthcare Initiative" },
    { "key": "ctr35", "value": "Republic of South Africa", "text": "Republic of South Africa", "ma": "Soul City Institute, Not for Profit Company" },
    { "key": "ctr36", "value": "Rwanda", "text": "Rwanda", "ma": "Health Development Initiative" },
    { "key": "ctr37", "value": "Senegal", "text": "Senegal", "ma": "Action pour le Développement" },
    { "key": "ctr38", "value": "Seychelles", "text": "Seychelles", "ma": "Alliance of Solidarity for the Family" },
    { "key": "ctr39", "value": "South Sudan", "text": "South Sudan", "ma": "Reproductive Health Association of South Sudan" },
    { "key": "ctr40", "value": "Zimbabwe", "text": "Zimbabwe", "ma": "My Age Zimbabwe" }

  ],
  rg2: [
    { "key": "ctr41", "value": "Algeria", "text": "Algeria", "ma": "Association Algérienne pour la Planification Familiale" },
    { "key": "ctr42", "value": "Bahrain", "text": "Bahrain", "ma": "Bahrain Reproductive Health Association" },
    { "key": "ctr43", "value": "Egypt", "text": "Egypt", "ma": "Egyptian Family Planning Association" },
    { "key": "ctr44", "value": "Lebanon", "text": "Lebanon", "ma": "Lebanese Association for Family Health" },
    { "key": "ctr45", "value": "Mauritania", "text": "Mauritania", "ma": "Association Mauritanienne pour la Promotion de la Famille" },
    { "key": "ctr46", "value": "Morocco", "text": "Morocco", "ma": "Association Marocaine de Planification Familiale" },
    { "key": "ctr47", "value": "Pakistan", "text": "Pakistan", "ma": "Rahnuma-Family Planning Association of Pakistan" },
    { "key": "ctr48", "value": "Palestine", "text": "Palestine", "ma": "Palestinian Family Planning and Protection Association" },
    { "key": "ctr49", "value": "Somaliland", "text": "Somaliland", "ma": "Somaliland Family Health Association" },
    { "key": "ctr50", "value": "Sudan", "text": "Sudan", "ma": "Sudan Family Planning Association" },
    { "key": "ctr51", "value": "Syria", "text": "Syria", "ma": "Syrian Family Planning Association" },
    { "key": "ctr52", "value": "Tunisia", "text": "Tunisia", "ma": "Association Tunisienne de la Santé de la Reproduction" },
    { "key": "ctr53", "value": "Yemen", "text": "Yemen", "ma": "Yemeni Association for Reproductive Health" },
    { "key": "ctr54", "value": "Libya", "text": "Libya", "ma": "Safwa Charity Organization" },
    { "key": "ctr55", "value": "Jordan", "text": "Jordan", "ma": "Institute for Family Health" }
  ],
  rg3: [
    { "key": "ctr56", "value": "Cambodia", "text": "Cambodia", "ma": "Reproductive Health Association of Cambodia" },
    { "key": "ctr57", "value": "China", "text": "China", "ma": "China Family Planning Association" },
    { "key": "ctr58", "value": "Cook Islands", "text": "Cook Islands", "ma": "Cook Islands Family Welfare Association" },
    { "key": "ctr59", "value": "Fiji", "text": "Fiji", "ma": "Reproductive & Family Health Association of Fiji" },
    { "key": "ctr60", "value": "Hong Kong", "text": "Hong Kong", "ma": "Family Planning Association of Hong Kong" },
    { "key": "ctr61", "value": "Indonesia", "text": "Indonesia", "ma": "The Indonesian Planned Parenthood Association" },
    { "key": "ctr62", "value": "Kiribati", "text": "Kiribati", "ma": "Kiribati Family Health Association" },
    { "key": "ctr63", "value": "Democratic People's Republic of Korea", "text": "Democratic People's Republic of Korea", "ma": "Family Health Association of Korea" },
    { "key": "ctr64", "value": "Republic of Korea", "text": "Republic of Korea", "ma": "Korea Population, Health and Welfare Association" },
    { "key": "ctr65", "value": "Malaysia", "text": "Malaysia", "ma": "Federation of Reproductive Health Associations Malaysia" },
    { "key": "ctr66", "value": "Mongolia", "text": "Mongolia", "ma": "Mongolian Family Welfare Association" },
    { "key": "ctr67", "value": "New Zealand", "text": "New Zealand", "ma": "New Zealand Family Planning" },
    { "key": "ctr68", "value": "Philippines", "text": "Philippines", "ma": "The Family Planning Organization of the Philippines Inc." },
    { "key": "ctr69", "value": "Samoa", "text": "Samoa", "ma": "Samoa Family Health Association" },
    { "key": "ctr70", "value": "Solomon Islands", "text": "Solomon Islands", "ma": "Solomon Islands Planned Parenthood Association" },
    { "key": "ctr71", "value": "Thailand", "text": "Thailand", "ma": "Planned Parenthood Association of Thailand" },
    { "key": "ctr72", "value": "Tonga", "text": "Tonga", "ma": "Tonga Family Health Association" },
    { "key": "ctr73", "value": "Tuvalu", "text": "Tuvalu", "ma": "Tuvalu Family Health Association" },
    { "key": "ctr74", "value": "Vanuatu", "text": "Vanuatu", "ma": "Vanuatu Family Health Association" },
    { "key": "ctr75", "value": "Vietnam", "text": "Vietnam", "ma": "Vietnam Family Planning Association" },
    { "key": "ctr76", "value": "Australia", "text": "Australia", "ma": "Family Planning Alliance Australia" },
    { "key": "ctr77", "value": "Papua New Guinea", "text": "Papua New Guinea", "ma": "Papua New Guinea Family Health Association" },
    { "key": "ctr78", "value": "Laos", "text": "Laos", "ma": "Promotion of Family Health Association of Lao PDR" },
    { "key": "ctr79", "value": "Myanmar", "text": "Myanmar", "ma": "Myanmar Maternal and Child Welfare Association" },
    { "key": "ctr80", "value": "Japan", "text": "Japan", "ma": "Japanese Organization for International Cooperation in Family Planning" },

  ],
  rg4: [
    { "key": "ctr81", "value": "Albania", "text": "Albania", "ma": "Qêndra pêr Popullsinë dhe Zhvillimin (Center for Population and Development)" },
    { "key": "ctr82", "value": "Austria", "text": "Austria", "ma": "Österreichische Gesellschaft für Familienplanung" },
    { "key": "ctr83", "value": "Belgium", "text": "Belgium", "ma": "Belgische Federatie voor Seksuele en Reproductieve Gezondheid en Rechten (BFSRGR) / Fédération Belge pour la Santé et les Droits Sexuels et Reproductifs (FBSDSR)" },
    { "key": "ctr84", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina", "ma": "Association XY" },
    { "key": "ctr85", "value": "Bulgaria", "text": "Bulgaria", "ma": "Bulgarian Family Planning and Sexual Health Association" },
    { "key": "ctr86", "value": "Cyprus", "text": "Cyprus", "ma": "Cyprus Family Planning Association" },
    { "key": "ctr87", "value": "Denmark", "text": "Denmark", "ma": "Sex & Samfund - The Danish Family Planning Association" },
    { "key": "ctr88", "value": "Estonia", "text": "Estonia", "ma": "Eesti Seksuaaltervise Liit / Estonian Sexual Health Association" },
    { "key": "ctr89", "value": "Finland", "text": "Finland", "ma": "Väestöliitto" },
    { "key": "ctr90", "value": "France", "text": "France", "ma": "Mouvement Français pour le Planning Familial" },
    { "key": "ctr91", "value": "Georgia", "text": "Georgia", "ma": "Association HERA XXI" },
    { "key": "ctr92", "value": "Germany", "text": "Germany", "ma": "PRO FAMILIA Bundesverband" },
    { "key": "ctr93", "value": "Ireland", "text": "Ireland", "ma": "Irish Family Planning Association" },
    { "key": "ctr94", "value": "Israel", "text": "Israel", "ma": "Israel Family Planning Association" },
    { "key": "ctr95", "value": "Kazakhstan", "text": "Kazakhstan", "ma": "Kazakhstan Association on Sexual and Reproductive Health" },
    { "key": "ctr96", "value": "Kyrgyzstan", "text": "Kyrgyzstan", "ma": "Reproductive Health Alliance of Kyrgyzstan" },
    { "key": "ctr97", "value": "Latvia", "text": "Latvia", "ma": "Papardes Zieds" },
    { "key": "ctr98", "value": "Netherlands", "text": "Netherlands", "ma": "Rutgers" },
    { "key": "ctr99", "value": "Norway", "text": "Norway", "ma": "Sex og Politikk" },
    { "key": "ctr100", "value": "Portugal", "text": "Portugal", "ma": "Associação Para o Planeamento da Família" },
    { "key": "ctr101", "value": "Republic of Macedonia", "text": "Republic of Macedonia", "ma": "Health Education and Research Association" },
    { "key": "ctr102", "value": "Republic of Serbia", "text": "Republic of Serbia", "ma": "Serbian Association for Sexual and Reproductive Rights" },
    { "key": "ctr103", "value": "Romania", "text": "Romania", "ma": "Societatea de Educatie Contraceptiva si Sexuala" },
    { "key": "ctr104", "value": "Slovak Republic", "text": "Slovak Republic", "ma": "Slovenská spolocnost pre plánované rodicovstvo a výchovu k rodi." },
    { "key": "ctr105", "value": "Spain", "text": "Spain", "ma": "Federación de Planificación Familiar de España" },
    { "key": "ctr106", "value": "Sweden", "text": "Sweden", "ma": "Riksförbundet för Sexuell Upplysning" },
    { "key": "ctr107", "value": "Switzerland", "text": "Switzerland", "ma": "SANTÉ SEXUELLE Suisse" },
    { "key": "ctr108", "value": "Tajikistan", "text": "Tajikistan", "ma": "Tajik Family Planning Alliance" },
    { "key": "ctr109", "value": "Ukraine", "text": "Ukraine", "ma": "NGO Women Health and Family Planning" },
    { "key": "ctr110", "value": "Croatia", "text": "Croatia", "ma": "Association for human rights and civic participation PaRiter" },
    { "key": "ctr111", "value": "Hungary", "text": "Hungary", "ma": "EMMA Közhasznú Egyesület" },
    { "key": "ctr112", "value": "Italy", "text": "Italy", "ma": "Associazione Italiana Donne per lo Sviluppo" },
    { "key": "ctr113", "value": "Malta", "text": "Malta", "ma": "Women Rights Foundation in Malta" },
    { "key": "ctr114", "value": "Moldova", "text": "Moldova", "ma": "Health for Youth Association" },
    { "key": "ctr115", "value": "Poland", "text": "Poland", "ma": "Fundacja Ogólnopolski Strajk Kobiet (Polish Women’s Strike Foundation)" },
    { "key": "ctr116", "value": "Slovenia", "text": "Slovenia", "ma": "Institute 8th March / Zavod Raziskovalni Inštitut 8. marec" },
    { "key": "ctr117", "value": "Uzbekistan", "text": "Uzbekistan", "ma": "Yuksak Salohiyat" }
  ],
  rg5: [
    { "key": "ctr118", "value": "Afghanistan", "text": "Afghanistan", "ma": "Afghan Family Guidance Association" },
    { "key": "ctr119", "value": "Bangladesh", "text": "Bangladesh", "ma": "Population Services and Training Center (PSTC)" },
    { "key": "ctr120", "value": "India", "text": "India", "ma": "Family Planning Association of India" },
    { "key": "ctr121", "value": "Maldives", "text": "Maldives", "ma": "Society for Health Education" },
    { "key": "ctr122", "value": "Nepal", "text": "Nepal", "ma": "Family Planning Association of Nepal" },
    { "key": "ctr123", "value": "Sri Lanka", "text": "Sri Lanka", "ma": "Family Planning Association of Sri Lanka" },
    { "key": "ctr124", "value": "Bhutan", "text": "Bhutan", "ma": "Respect Educate Nurture Empower Women" },
  ],
  rg6: [
    { "key": "ctr125", "value": "Barbados", "text": "Barbados", "ma": "The Barbados Family Planning Association" },
    { "key": "ctr126", "value": "Colombia", "text": "Colombia", "ma": "Asociación Pro-Bienestar de la Familia Colombiana" },
    { "key": "ctr127", "value": "Jamaica", "text": "Jamaica", "ma": "Jamaica Family Planning Association" },
    { "key": "ctr128", "value": "Mexico", "text": "Mexico", "ma": "Fundación Mexicana para la Planeación Familiar A.C." },
    { "key": "ctr129", "value": "Peru", "text": "Peru", "ma": "Instituto Peruano de Paternidad Responsable" },
    { "key": "ctr130", "value": "Suriname", "text": "Suriname", "ma": "Stichting Lobi" },
    { "key": "ctr131", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago", "ma": "Family Planning Association of Trinidad and Tobago" },
    { "key": "ctr132", "value": "United States of America", "text": "United States of America", "ma": "Planned Parenthood Federation of America" },
    { "key": "ctr133", "value": "Antigua", "text": "Antigua", "ma": "Antigua Planned Parenthood Association" },
    { "key": "ctr134", "value": "Aruba", "text": "Aruba", "ma": "Foundation for the Promotion of Responsible Parenthood (Aruba)" },
    { "key": "ctr135", "value": "Bolivia", "text": "Bolivia", "ma": "Centro de Investigación Educación y Servicios" },
    // Chile ADDED on 26th March
    { "key": "ctr136", "value": "Chile", "text": "Chile", "ma": "Asociación chilena de Protección de la Familia" },
    { "key": "ctr137", "value": "Dominica", "text": "Dominica", "ma": "Dominica Planned Parenthood Association" },
    { "key": "ctr138", "value": "Guadeloupe", "text": "Guadeloupe", "ma": "Association Guadeloupéenne pour le Planning Familial" },
    // Ecuador ADDED on 25th March
    { "key": "ctr139", "value": "Ecuador", "text": "Ecuador", "ma": "CEMOPLAF" },
    // Guyana ADDED on 25th March
    { "key": "ctr140", "value": "Guyana", "text": "Guyana", "ma": "Merundoi Incorporated" },
    { "key": "ctr141", "value": "Curacao", "text": "Curacao", "ma": "Foundation for the Promotion of Responsible Parenthood (FPRP)" },
    { "key": "ctr142", "value": "Grenada", "text": "Grenada", "ma": "Grenada Planned Parenthood Association" },
    { "key": "ctr143", "value": "Martinique", "text": "Martinique", "ma": "Association Martiniquaise pour I'Information et l'Orientation Familiales" },
    { "key": "ctr144", "value": "St. Lucia", "text": "St. Lucia", "ma": "Saint Lucia Planned Parenthood Association" },
    { "key": "ctr145", "value": "St. Vincent", "text": "St. Vincent", "ma": "St. Vincent Planned Parenthood Association" },
    { "key": "ctr146", "value": "Guatemala", "text": "Guatemala", "ma": "Asociación Pro-Bienestar de la Familia de Guatemala" },
    { "key": "ctr147", "value": "Venezuela", "text": "Venezuela", "ma": "Asociación Venezolana para una Educación Sexual Alternativa" },
    { "key": "ctr148", "value": "Argentina", "text": "Argentina", "ma": "Fundación Huésped" },
    { "key": "ctr149", "value": "Brazil", "text": "Brazil", "ma": "Ciudadania Estudo Pesquisa Informaçao e Açao" },
    { "key": "ctr150", "value": "Haiti", "text": "Haiti", "ma": "Association des Sages-Femmes d'Haïti" },
    { "key": "ctr151", "value": "Cuba", "text": "Cuba", "ma": "Sociedad Cientifica Cubana Para el Desarrollo de la Familia" },
    { "key": "ctr152", "value": "Honduras", "text": "Honduras", "ma": "Fundación Llaves" },
  ]
}

const regionOption = [
  { key: "rg1", value: "Africa", text: "Africa" },
  { key: "rg6", value: "Americas and the Caribbean", text: "Americas and the Caribbean" },
  { key: "rg2", value: "Arab World", text: "Arab World" },
  { key: "rg3", value: "East and Southeast Asia and Oceania", text: "East and Southeast Asia and Oceania" },
  { key: "rg4", value: "European Network", text: "European Network" },
  { key: "rg5", value: "South Asia", text: "South Asia" }
];

const yearOptions = [
  { key: "1940", value: 1940, text: "1940" },
  { key: "1941", value: 1941, text: "1941" },
  { key: "1942", value: 1942, text: "1942" },
  { key: "1943", value: 1943, text: "1943" },
  { key: "1944", value: 1944, text: "1944" },
  { key: "1945", value: 1945, text: "1945" },
  { key: "1946", value: 1946, text: "1946" },
  { key: "1947", value: 1947, text: "1947" },
  { key: "1948", value: 1948, text: "1948" },
  { key: "1949", value: 1949, text: "1949" },
  { key: "1950", value: 1950, text: "1950" },
  { key: "1951", value: 1951, text: "1951" },
  { key: "1952", value: 1952, text: "1952" },
  { key: "1953", value: 1953, text: "1953" },
  { key: "1954", value: 1954, text: "1954" },
  { key: "1955", value: 1955, text: "1955" },
  { key: "1956", value: 1956, text: "1956" },
  { key: "1957", value: 1957, text: "1957" },
  { key: "1958", value: 1958, text: "1958" },
  { key: "1959", value: 1959, text: "1959" },
  { key: "1960", value: 1960, text: "1960" },
  { key: "1961", value: 1961, text: "1961" },
  { key: "1962", value: 1962, text: "1962" },
  { key: "1963", value: 1963, text: "1963" },
  { key: "1964", value: 1964, text: "1964" },
  { key: "1965", value: 1965, text: "1965" },
  { key: "1966", value: 1966, text: "1966" },
  { key: "1967", value: 1967, text: "1967" },
  { key: "1968", value: 1968, text: "1968" },
  { key: "1969", value: 1969, text: "1969" },
  { key: "1970", value: 1970, text: "1970" },
  { key: "1971", value: 1971, text: "1971" },
  { key: "1972", value: 1972, text: "1972" },
  { key: "1973", value: 1973, text: "1973" },
  { key: "1974", value: 1974, text: "1974" },
  { key: "1975", value: 1975, text: "1975" },
  { key: "1976", value: 1976, text: "1976" },
  { key: "1977", value: 1977, text: "1977" },
  { key: "1978", value: 1978, text: "1978" },
  { key: "1979", value: 1979, text: "1979" },
  { key: "1980", value: 1980, text: "1980" },
  { key: "1981", value: 1981, text: "1981" },
  { key: "1982", value: 1982, text: "1982" },
  { key: "1983", value: 1983, text: "1983" },
  { key: "1984", value: 1984, text: "1984" },
  { key: "1985", value: 1985, text: "1985" },
  { key: "1986", value: 1986, text: "1986" },
  { key: "1987", value: 1987, text: "1987" },
  { key: "1988", value: 1988, text: "1988" },
  { key: "1989", value: 1989, text: "1989" },
  { key: "1990", value: 1990, text: "1990" },
  { key: "1991", value: 1991, text: "1991" },
  { key: "1992", value: 1992, text: "1992" },
  { key: "1993", value: 1993, text: "1993" },
  { key: "1994", value: 1994, text: "1994" },
  { key: "1995", value: 1995, text: "1995" },
  { key: "1996", value: 1996, text: "1996" },
  { key: "1997", value: 1997, text: "1997" },
  { key: "1998", value: 1998, text: "1998" },
  { key: "1999", value: 1999, text: "1999" },
  { key: "2000", value: 2000, text: "2000" },
  { key: "2001", value: 2001, text: "2001" },
  { key: "2002", value: 2002, text: "2002" },
  { key: "2003", value: 2003, text: "2003" },
  { key: "2004", value: 2004, text: "2004" },
  { key: "2005", value: 2005, text: "2005" },
  { key: "2006", value: 2006, text: "2006" },
  { key: "2007", value: 2007, text: "2007" },
  { key: "2008", value: 2008, text: "2008" },
  { key: "2009", value: 2009, text: "2009" },
  { key: "2010", value: 2010, text: "2010" },
  { key: "2011", value: 2011, text: "2011" },
  { key: "2012", value: 2012, text: "2012" },
  { key: "2013", value: 2013, text: "2013" },
  { key: "2014", value: 2014, text: "2014" },
  { key: "2015", value: 2015, text: "2015" },
  { key: "2016", value: 2016, text: "2016" },
  { key: "2017", value: 2017, text: "2017" },
  { key: "2018", value: 2018, text: "2018" },
  { key: "2019", value: 2019, text: "2019" },
  { key: "2020", value: 2020, text: "2020" },
  { key: "2021", value: 2021, text: "2021" },
  { key: "2022", value: 2022, text: "2022" },
  { key: "2023", value: 2023, text: "2023" },
  { key: "2024", value: 2023, text: "2024" }

];
const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}
class Register extends Component {
  constructor(props) {
    super(props);
    const { minStrength = 3, thresholdLength = 7 } = props;

    this.minStrength = typeof minStrength === 'number'
      ? Math.max(Math.min(minStrength, 4), 0)
      : 3;

    this.thresholdLength = typeof thresholdLength === 'number'
      ? Math.max(thresholdLength, 7)
      : 7;
    this.state = {
      firstName: '',
      lastName: '',
      email: "",
      maFullName: '',
      password: "",
      confPassword: '',
      country: '',
      region: '',
      regionId: null,
      year: '',
      loading: false,
      user_category: 0,
      strength: 0,
      minStrength: 3,
      thresholdLength: 7,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    const { firstName, lastName, email, maFullName, password, confPassword, country, region, regionId, year, user_category } = this.state;
    const { t, i18n } = this.props;
    if (user_category == 2 && region === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        ) : (
          <div><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        )
      });
      return;
    }

    if (user_category == 3 && region === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        ) : (
          <div><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        )
      });
      return;
    }
    if (user_category == 3 && country === "") {
      this.setState({ loading: false });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        ) : (
          <div><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        )
      });
      return;
    }

    if (password !== confPassword) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}>
            <span>{t("Passwords do not match!")}</span>
          </div>
        ) : (
          <div>
            <span>{t("Passwords do not match!")}</span>
          </div>
        )
      });
      this.setState({ loading: false });
      return;
    }

    ////Password_length

    if (this.state.password.length <= this.state.thresholdLength) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message:
          localStorage.RAT_LANG == "ar" ? (
            <div style={{ paddingRight: "20px" }}> {this.props.t('password_validity')} </div>
          ) : (
            <div> {this.props.t('password_validity')} </div>
          )


      });
      this.setState({ loading: false });
      return;
    }
    /////

    if (firstName.trim().length < 2 || lastName.trim().length < 2) {
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }}><span>{t("You are using 1 character for the name! Minimum 2 characters required.")}</span></div>
        ) : (
          <div><span>{t("You are using 1 character for the name! Minimum 2 characters required.")}</span></div>
        )
      });
      this.setState({ loading: false });
      return;
    }
    if (!firstName == '' && !lastName == '' && !email == '' && !maFullName == '' && !password == '' && !confPassword == '') {
      this._createUser();
    } else {
      console.log('no');
      this.setState({
        loading: false,
      });
      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message: localStorage.RAT_LANG == 'ar' ? (
          <div style={{ paddingRight: "15px" }} ><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        ) : (
          <div><span>{t("Please make sure you have filled out all required fields.")}</span></div>
        )
      });
    }
    // this._createUser();
  };

  onUserCategoryChanged = event => {
    this.setState({
      user_category: event.target.value,
      country: '',
      region: '',
      year: '',
      maFullName: ''
    });
    switch (event.target.value) {
      case "1":
        this.setState({
          maFullName: "International Planned Parenthood Federation",
        });
        break;
      case "2":
        this.setState({
          maFullName: "NA",
        });
        break;
      default:
        break;
    }

  }

  handleChange = event => {
    // console.log(event)
    this.setState({ [event.target.id]: event.target.value });
  };
  passwordChange = (event) => {
    this.setState({
      password: event.target.value,
      strength: zxcvbn(event.target.value).score,
    })
  };

  _handleYearChange = (e, { value }) => this.setState({ year: value });
  _handleMaChange = (e, { value }) => this.setState({ maFullName: value });

  _handleRegionChange = async (e, { value }) => {
    countryOptions = [];
    const selectedOption = regionOption.filter(function (item) {
      return item.value == value;
    });
    this.setState({ region: value });
    const key = selectedOption[0].key;
    countryOptions = countryList[key];
    countryOptions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
    countryOptions.push({ "key": "ctr170", "value": "Other", "text": "Other", "ma": "Other" });
  };

  _handleCountryChange = (e, { value }) => {
    const allcountry = [...countryList.rg1, ...countryList.rg2, ...countryList.rg3, ...countryList.rg4, ...countryList.rg5, ...countryList.rg6];
    allcountry.push({ "key": "ctr170", "value": "Other", "text": "Other" });
    const selectedOption = allcountry.filter(function (item) {
      return item.value == value;
    });
    if (selectedOption[0] !== undefined) {
      this.setState({ maFullName: selectedOption[0].ma });
    } else {
      this.setState({ maFullName: "Other" });
    }

    this.setState({ country: value });

    ////
    maOptions = [];
    const selectedOptionMa = selectedOption.filter(function (item) {
      return item.value == value;
    });
    const newLocal = this;
    newLocal.setState({ country: value });
    const key = selectedOptionMa[0].key;
    maOptions = maList[key];
    selectedOption.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
    selectedOption.push({ "key": "ma170", "value": "Other", "text": "Other" });

    console.log(key)
    // ///
  };

  _createUser = async () => {

    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    const { t, i18n } = this.props;
    this.props.client.mutate({
      mutation: ADD_MA_USER,
      variables: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        fullName: this.state.maFullName,
        country: this.state.country === '' ? 'NA' : this.state.country,
        region: this.state.region === '' ? 'NA' : this.state.region,
        year: parseInt(this.state.year),
        languagePreference: localStorage.RAT_LANG,
      }
    }).then(result => {
      const ADDED_USER = result.data.addMAUser;
      const FULL_NAME = ADDED_USER.general.fullName;
      const REGION = ADDED_USER.general.region;
      const COUNTRY = ADDED_USER.general.country;
      const YEAR = ADDED_USER.general.year === null ? "NA" : ADDED_USER.general.year;
      const FIRST_NAME = ADDED_USER.user.firstName;
      const LAST_NAME = ADDED_USER.user.lastName;
      const USER_EMAIL = ADDED_USER.user.email;
      const LABEL_USER = (FULL_NAME === "International Planned Parenthood Federation") ? "Organization" : (FULL_NAME === "NA") ? "Regional Office Name" : "Full Name of the Member Association (MA)";
      const personalizations = {
        "personalizations": [
          {
            "to": [
              {
                "email": USER_EMAIL,
                "name": FULL_NAME
              }
            ],
            "bcc": [
              // {
              //   "email": "manjula@fpasrilanka.org",
              //   "name": "Manjula Chathuranga Liyanage"
              // },
              // {
              //   "email": "chameera.lakshitha212@gmail.com",
              //   "name": "Lakshitha"
              // },
              {
                "email": "rishi@fpasrilanka.org",
                "name": "Rishikeshan Thiyagaraja"
              },
              {
                "email": "narthana.se@gmail.com",
                "name": "Narthana Selvachothy"
              }
            ],
            "dynamic_template_data": {
              "name": FIRST_NAME
            },
            "subject": "SE Readiness Assessment Tool – Your request is under review"
          }
        ],
        // COMMENT KEY - C-01/06/2021
        // "template_id": "d-8899066665ea4e60bb78d07404ad9394"
        "template_id": localStorage.RAT_LANG === 'en' ? "d-8899066665ea4e60bb78d07404ad9394"
          : localStorage.RAT_LANG === 'ar' ? "d-005ca590b86a4d0aa41da13f18fc8b01"
            : localStorage.RAT_LANG === 'fr' ? "d-3fe0787b15784adb912712cc47c47043"
              : localStorage.RAT_LANG === 'sp' ? "d-ec0f327dd2b2458c9ec81c3385ee104d"
                : "d-8899066665ea4e60bb78d07404ad9394"
      };
      const new_user_personalizations = {
        "personalizations": [
          {
            "to": [
              {
                "email": "rishi@fpasrilanka.org",
                "name": "Rishikeshan Thiyagaraja"
              },
              // {
              //   "email": "narthana.se@gmail.com",
              //   "name": "Narthana Selvachothy"
              // }
            ],
            "cc": [
              {
                "email": "manjula@fpasrilanka.org",
                "name": "Manjula Chathuranga Liyanage"
              },
              // {
              //   "email": "chameera.lakshitha212@gmail.com",
              //   "name": "Lakshitha"
              // }
            ],
            "bcc": [
              {
                "email": "narthana.se@gmail.com",
                "name": "Narthana Selvachothy"
              }
            ],
            "subject": "SE Readiness Assessment Tool – A new user has signed up – Review required"
          }
        ],
        "content": [
          {
            "type": "text/html",
            "value": "<h2>Hello, Admin</h2><p>A new user has signed up for IPPF's Social Enterprise Readiness Assessment Tool.</p><p>User details as follows -</p><b>First Name: </b>" + FIRST_NAME + "<br><b>Last Name: </b>" + LAST_NAME + "<br><b>" + LABEL_USER + ": </b>" + FULL_NAME + "<br><b>Official Email: </b>" + USER_EMAIL + "<br><b>IPPF Region: </b>" + REGION + "<br><b>Country: </b>" + COUNTRY + "<br><b>Year of establishment: </b>" + YEAR + "<br><p><a href='https://ra.seippf.org/users'>Click here</a> here to approve/decline the request.</p><p style='color::#888888'><i>Note: This is a system generated notice. Please do not reply.</i></p>"
          }
        ]
      };
      try {
        const user_data = _generate_email('https://ra.seippf.org/mail/mail.php', new_user_personalizations);
        const data = _generate_email('https://ra.seippf.org/mail/mail.php', personalizations);
        console.log('sending emails');
        console.log(data);
        console.log(user_data);
        this.setState({
          loading: false,
        })
        this.props.setSuccessStatus({
          status: true,
          title: "",
          message:
            <span>{t("Thank you for registering to access the Readiness Assessment Tool. IPPF’s Social Enterprise Hub will review your request and revert soon. Do check your Email for notifications related to this account creation.")}</span>
        });
        this.props.history.push('/welcome');
      } catch (error) {
        console.error(error);
      }
    }).catch(error => {
      console.log(error);
      if (error) {
        this.setState({
          loading: false,
        });
        this.props.setFormStatus({
          status: true,
          title: "Oops!",
          message: localStorage.RAT_LANG == 'ar' ? (
            <div style={{ paddingRight: "15px" }}><span>{t("A User ID already exists for this email address.")}</span></div>
          ) : (
            <div><span>{t("A User ID already exists for this email address.")}</span></div>
          )
        });
      }
    });
  }

  render() {
    const { firstName, lastName, email, maFullName, password, confPassword, country, region, regionId, year, user_category, strength } = this.state;
    const { formErrorStatus, formSuccessState, validator, children, t, i18n, ...restProps } = this.props;
    const passwordLength = password.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;

    const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();
    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();
    return (
      <div
        uk-height-viewport="offset-top: true; offset-bottom: true"
        className="uk-flex uk-flex-middle"
        class="uk-margin"
        uk-scrollspy="target: > .reg-holder; cls:uk-animation-slide-bottom-small; delay: 200"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="uk-width-1-2@m uk-width-1-2@s uk-margin-auto reg-holder">
          <div className="uk-padding extra-top-padding">
            <Form success error onSubmit={this.handleSubmit}>
              <h2 className="uk-text-bold uk-text-center">{t("Create Your Account")}</h2>
              <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom uk-text-center">
                {t("Please complete all the fields below and click on")} <b>‘{t("Register")}’.</b>
              </p>
              <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom uk-text-center">
                {t("Keep a note of the password you enter")}.
              </p>
              <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label uk-margin-medium-top" : "uk-form-label uk-margin-medium-top"} >{t("User Category")}<i style={styles.icon} className="icon red">*</i></div>
              <div className="uk-inline">
                <label > <input required className="uk-radio" onChange={this.onUserCategoryChanged} type="radio" id="user_category" name="user_category" value="1" /> {t("Central Office")} </label>
                <label style={{ paddingRight: "15px" }}> <input className="uk-radio" onChange={this.onUserCategoryChanged} type="radio" id="user_category" name="user_category" value="2" /> {t("Regional Office")} </label>
                <label style={{ paddingRight: "15px" }}> <input className="uk-radio" onChange={this.onUserCategoryChanged} type="radio" id="user_category" name="user_category" value="3" /> {t("Member Association")} </label>
              </div>
              {(user_category === "2" || user_category === "3") ? (
                <div>
                  <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label" : "uk-form-label"}>{t("IPPF Region")}<i style={styles.icon} className="icon red">*</i></div>
                  <div className="uk-margin-bottom">
                    <Dropdown
                      onChange={this._handleRegionChange}
                      id="region"
                      name="region"
                      selection
                      value={this.state.region}
                      placeholder={t("Select your IPPF Region")}
                      options={regionOption}
                      style={{ width: "350px" }}
                    />
                  </div>
                </div>
              ) : ("")}
              {(user_category === "3") ? (
                <div>
                  <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Country")}<i style={styles.icon} className="icon red">*</i></div>
                  <div className="uk-margin-bottom">
                    <Dropdown
                      onChange={this._handleCountryChange}
                      name="country"
                      id="country"
                      selection
                      value={this.state.country}
                      placeholder={t("Select your country")}
                      options={countryOptions}
                      style={{ width: "350px" }}
                    />
                  </div>
                </div>
              ) : ("")}

              {(user_category === "3") ? (
                <div>
                  <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label" : "uk-form-label"}>{t("Year of establishment")}</div>
                  <div className="uk-margin-bottom">
                    <Dropdown
                      onChange={this._handleYearChange}
                      options={yearOptions}
                      placeholder={t("Select Year")}
                      selection
                      value={this.state.year}
                      style={{ width: "350px" }}
                    />
                  </div>
                </div>
              ) : ("")}
              <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("First Name")}<i style={styles.icon} className="icon red">*</i></div>
              <div>
                <input
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  className="uk-input uk-form-width-large"
                  placeholder=""
                  type="text"
                  onChange={this.handleChange}
                  required
                // minlength="2"
                />
              </div>

              <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Last Name")}<i style={styles.icon} className="icon red">*</i></div>
              <div>
                <input
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  className="uk-input uk-form-width-large"
                  placeholder=""
                  type="text"
                  onChange={this.handleChange}
                  required
                // minlength="2"
                />
              </div>
              {(user_category === "1") ? (
                <div>
                  <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Organization")}<i style={styles.icon} className="icon red">*</i></div>
                  <div>
                    <input
                      name="maFullName"
                      id="maFullName"
                      value={this.state.maFullName}
                      className="uk-input uk-form-width-large"
                      placeholder=""
                      type="text"
                      onChange={this.handleChange}
                      required
                      readonly="true"
                    />
                  </div>
                </div>
              ) : ("")}
              {(user_category === "3") ? (
                <div>
                  <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Full name of the Member Association (MA)")}<i style={styles.icon} className="icon red">*</i></div>
                    <Dropdown
                      className="uk-input"
                      options={maOptions}
                      value={this.state.maFullName}
                      onChange={this._handleMaChange}
                      id="maFullName"
                      name="maFullName"
                      selection
                    />

                    {/* <input
                      name="maFullName"
                      id="maFullName"
                      value={this.state.maFullName}
                      className="uk-input uk-form-width-large"
                      placeholder=""
                      type="text"
                      onChange={this.handleChange}
                      required
                    /> */}
                </div>
              ) : ("")}
              <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Official Email Address")}<i style={styles.icon} className="icon red">*</i></div>
              <div>
                <input
                  name="email"
                  id="email"
                  value={this.state.email}
                  className="uk-input uk-form-width-large"
                  placeholder=""
                  type="email"
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Password")}<i style={styles.icon} className="icon red">*</i></div>
              <Form.Field {...restProps} validator={this.validatePasswordStrong}  >
                <span style={{ fontSize: "14px" }} className="d-block form-hint">{t("To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.")}</span>
                {children}
                <div className={strengthClass}>
                  <div className="strength-meter-fill" data-strength={strength}></div>
                </div>
              </Form.Field>
              <div className="uk-margin-bottom">
                <input
                  name="password"
                  id="password"
                  value={this.state.password}
                  className="uk-input uk-form-width-large"
                  placeholder={t("Password")}
                  type="password"
                  onChange={this.passwordChange}
                  required
                />
              </div>
              <div className="position-absolute password-count mx-3">
                <span className={counterClass}>{passwordLength ? passwordLong ? `${this.thresholdLength}+` : passwordLength : ''}</span>
              </div>

              <div className={localStorage.RAT_LANG == 'ar' ? "uk-form-label " : "uk-form-label"}>{t("Confirm Password")}<i style={styles.icon} className="icon red">*</i></div>
              <div className="uk-margin-bottom">
                <input
                  label="Confirm Password"
                  name="confPassword"
                  id="confPassword"
                  value={this.state.confPassword}
                  className="uk-input uk-form-width-large"
                  placeholder={t("Confirm Password")}
                  type="password"
                  onChange={this.handleChange}
                  required
                />
              </div>


              <div></div>
              <input
                className="uk-button uk-button-success uk-width-1-1  uk-margin"
                type="submit"
                value={t("Register")}
                loading={this.state.loading}
                disabled={this.state.loading ? (true) : (false)}
              // disabled={!firstName || !lastName || !email || !maFullName || !password || !confPassword || !country || !region || !year }          
              />

              {(formErrorStatus.status) ? (
                <div className="danger_alert">{formErrorStatus.message}</div>
                // <Message
                //   error
                //   content={formErrorStatus.message}
                // />
              ) : ((formSuccessState.status) ? (
                <div className="success_alert">{formSuccessState.message}</div>
                // <Message
                //   success
                //   content={formSuccessState.message}
                // />
              ) : (''))}
            </Form>
            <p className={localStorage.RAT_LANG == 'ar' ? "" : ""}>
              {t("Already Registered?")} <button onClick={() => { window.location.href = '/' }} class="uk-button align uk-button-text verticalAlign"> {t("Log in Here")} </button>
            </p>
            {this.state.loading ? (<div class="spinner"></div>) : ("")}
          </div>

        </div>
      </div>
    );
  }
}
const styles = {
  icon: {
    width: "0px"
  }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Register))));
