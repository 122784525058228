import React, { Component } from "react";
import { Divider, Grid, Image, Segment } from 'semantic-ui-react'
import { withNamespaces } from "react-i18next";

class Contact extends Component {
    render() {
        const { t, i18n } = this.props
        return (
            <div className="contactStyle">
                {/* Begin Dark Header */}
                <div style={{ paddingTop: "130px", paddingBottom: "50px" }} class="uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light back" data-src="../assets/images/backgrounds/about.jpg" uk-img>
                    <div className="uk-width-1-1@m uk-text-center ">
                        <h1 className=" uk-animation-slide-top-small uk-text-white">
                            {t("Contact Details of IPPF Social Enterprise Hub")}
                        </h1>
                        <p className="uk-text-white uk-text-large">
                            {t("We look forward to hearing from You.")}<br />
                        </p>
                    </div>
                </div>
                <div className="uk-section-default uk-section ">
                    <div className="uk-margin-auto uk-container-small uk-width-1-2@m uk-text-center">
                        <p style={{ textAlign: "justify" }}>{t("Our team is happy to answer any questions, whether it is on the subject of Readiness Assessment, about the registration process, functionality/feedback of this tool, any issues you encountered, next steps post submission or even any other general queries related to your social enterprise.")}</p>
                        <p>{t("Send us an email or reach us on the below contact details.")}</p>
                        <p>{t("You can also get in touch via our ‘Chat function’ on the screen.")}</p>
                        <p><h5> {t("Please give us between 1 – 2 working days to revert on your inquiry.")}</h5></p>
                        <p className="uk-text-center ContactTittle"><strong> {t("Contact Details of IPPF Social Enterprise Hub")} </strong></p>
                        <div className="uk-child-width-1-2@s uk-grid-match uk-grid">
                            <div >
                                <div className="uk-card uk-card-hover uk-card-body uk-light" style={{ backgroundColor: "#fff", fontSize: "16px", padding: "20px 20px" }}>
                                    <h3 className="uk-card-title" style={{ color: "#000", minHeight:"45px" }}>{t("Project Manager")}</h3>
                                    <p><span className="far fa-user" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.RAT_LANG=='ar'? "contact_text_Arabic": "contact_text"} >Rishikeshan Thiyagaraja (Rishi)</span></p>
                                    <p><span className="far fa-envelope" style={{ color: "#000", paddingRight: "5px" }}></span><span ><a href="mailto:rishi@fpasrilanka.org" style={{ color: "#151F6D" }} className={localStorage.RAT_LANG=='ar'? "contact_text_Arabic": "contact_text"}>rishi@fpasrilanka.org</a></span></p>
                                    <p><span className="fab fa-skype" style={{ color: "#000", paddingRight: "5px" }}></span><span style={{ color: "#151F6D" }} className={localStorage.RAT_LANG=='ar'? "contact_text_Arabic": "contact_text"}>rishikeshant</span></p>
                                    <p>
                                        <span className="fas fa-mobile-alt" style={{ color: "#000", paddingRight: "5px" }}></span>
                                        {localStorage.RAT_LANG=='ar'?
                                        ( <span className= "contact_text_Arabic"> 94773783865+</span>
                                            ):(
                                            <span className="contact_text"> +94773783865</span>
                                        ) }
                                        </p>
                                    <p><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span>
                                    {localStorage.RAT_LANG=='ar'?(
                                         <span className="contact_text_Arabic">94112555455+ (تحويلة 217)</span>
                                    ):(
                                        <span className="contact_text">+94112555455 (Ext 217)</span>
                                    )
                                            }
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="uk-card uk-card-hover uk-card-body uk-light" style={{ backgroundColor: "#fff", fontSize: "16px", padding: "20px 20px" }}>
                                    <h3 className="uk-card-title" style={{ color: "#000", paddingRight: "5px", minHeight:"45px"  }}>{t("Capacity Building Officer")}</h3>
                                    <p><span className="far fa-user" style={{ color: "#000", paddingRight: "5px" }}></span><span className={localStorage.RAT_LANG=='ar'? "contact_text_Arabic": "contact_text"}>Manjula Liyanage (Manjula)</span></p>
                                    <p><span className="far fa-envelope" style={{ color: "#000", paddingRight: "5px" }}></span><span><a href="mailto:manjula@fpasrilanka.org" style={{ color: "#151F6D" }} className={localStorage.RAT_LANG=='ar'? "contact_text_Arabic": "contact_text"}>manjula@fpasrilanka.org</a></span></p>
                                    <p><span className="fab fa-skype" style={{ color: "#000", paddingRight: "5px" }}></span><span style={{ color: "#151F6D" }} className={localStorage.RAT_LANG=='ar'? "contact_text_Arabic": "contact_text"}>manjula.chathuranga</span></p>
                                    <p><span className="fas fa-mobile-alt" style={{ color: "#000", paddingRight: "5px" }}></span>
                                    {localStorage.RAT_LANG=='ar'? (
                                         <span className="contact_text_Arabic">94714072631+</span>
                                    ):(
                                        <span className="contact_text">+94714072631</span>
                                    )}
                                   </p>
                                    <p><span className="fa fa-phone" style={{ color: "#000", paddingRight: "5px" }}></span>
                                    {localStorage.RAT_LANG=='ar'? (
                                        <span className="contact_text_Arabic">94112555455+ (تحويلة 217)</span>
                                    ):(
                                        <span className="contact_text">+94112555455 (Ext 217)</span>
                                    )}
                                    
                                    </p>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="uk-container uk-width-1-3@m ">
                        <div className="uk-margin-auto uk-container-small">
                            <div className="uk-column-1-2 uk-column-divider ">
                            </div>

                        </div>
                    </div>

                </div>



                {/* End Dark Header */}


            </div>
        );
    }
}
const styles = {
    darkBack: {
        backgroundColor: 'rgba(0, 0, 0, 0.52)',
        backgroundBlendMode: 'color-burn',
    },
    poweredBy: {
        bottom: '8px',
        right: '60px',

    }
}

export default withNamespaces()(Contact);
