import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { GET_USER_SCORE } from '../../../queries/SubmissionQueries';
import { GET_MY_PROFILE } from "../../../queries/UserQueries";
import { _generate_email } from '../../../middleware';
import { withNamespaces } from "react-i18next";

class Register extends Component {
  constructor(props) {
    super(props);
    var state = window.history.state || {};
    var reloadCount = state.reloadCount || 0;
    if (performance.navigation.type === 1) { // Reload
      state.reloadCount = ++reloadCount;
      window.history.replaceState(state, null, document.URL);
    } else if (reloadCount) {
      reloadCount = 0;
      delete state.reloadCount;
      window.history.replaceState(state, null, document.URL);
    }
    console.log(reloadCount);
    this.state = {
      loading: false,
      userId: localStorage.USER_ID,
      result_status: "Not Ready",
      result_color: "red",
    };

    // this._checkGeneralInfo().then(result => {
    //   console.log(result);
    //   const firstName = result.user.firstName;
    //   const lastName = result.user.lastName;
    //   const email = result.user.email;
    //   const maFullName = result.general.fullName
    //   const submittedBy = firstName.concat(" ", lastName)
    //   // console.log(submittedBy)
    //   const personalizations = {
    //     "personalizations": [
    //       {
    //         "to": [
    //           {
    //             "email": email,
    //             "name": submittedBy
    //           }
    //         ],
    //         "bcc": [
    //           {
    //             "email": "rishi@fpasrilanka.org",
    //             "name": "Rishikeshan Thiyagaraja"
    //           },
    //           {
    //             "email": "narthana.se@gmail.com",
    //             "name": "Narthana Selvachothy"
    //           }
    //         ],
    //         "dynamic_template_data": {
    //           "name": firstName
    //         },
    //         "subject": "SE Readiness Assessment Tool – Your Assessment was submitted to the SE Hub"
    //       }
    //     ],

    //     // COMMENT KEY - C-01/06/2021
    //     // "template_id": "d-cc6d406e08934fcf9db5432e3bd4419c"
    //     "template_id": localStorage.RAT_LANG === 'en' ? "d-cc6d406e08934fcf9db5432e3bd4419c"
    //       : localStorage.RAT_LANG === 'ar' ? "d-70ed88b6ec28453ca4a14b3b5a188762"
    //         : localStorage.RAT_LANG === 'fr' ? "d-19fec9f373d94583b29f37ac6d735cc8"
    //           : localStorage.RAT_LANG === 'sp' ? "d-dbaa717f4835427da6aa274f10699eda"
    //             : "d-cc6d406e08934fcf9db5432e3bd4419c"
    //   };
    //   const new_submission_personalizations = {
    //     "personalizations": [
    //       {
    //         "to": [
    //           {
    //             "email": "rishi@fpasrilanka.org",
    //             "name": "Rishikeshan Thiyagaraja"
    //           },
    //         ],
    //         "bcc": [
    //           {
    //             "email": "narthana.se@gmail.com",
    //             "name": "Narthana Selvachothy"
    //           }
    //         ],

    //         "subject": "SE Readiness Assessment Tool – An Assessment has been submitted for your review"
    //       }
    //     ],
    //     "content": [
    //       {
    //         "type": "text/html",
    //         "value": "<h2>Hello, Admin</h2><p>A new assessment has been submitted on IPPF's Social Enterprise Readiness Assessment Tool for your review.</p><p><b>Submitted by: </b>" + submittedBy + "</p><p><b>Organization: </b>" + maFullName + "</p><br><p><a href='https://ra.seippf.org/submissions'>Click here</a> here to review the assessment.</p><p style='color::#888888'><i>Note: This is a system generated notice. Please do not reply.</i></p>"
    //       }
    //     ]
    //   };
    //   // reloadCount VARIABLE IS COMMENTED ON 15 DECEMBER 2022 AS THE EMAIL BELOW IS NOT GENERATED TO ADMIN
    //   // if (reloadCount === 0) {
    //   try {
    //     const user_data = _generate_email('https://ra.seippf.org/mail/mail.php', new_submission_personalizations);
    //     const data = _generate_email('https://ra.seippf.org/mail/mail.php', personalizations);
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   // }
    // });
  }
// COMMENTED BCZ EMAIL GOES REPEATEDLY WHEN THE THANKYOU PAGE IS REFRESHED
  // _checkGeneralInfo = async () => {
  //   const result = await this.props.client.query({
  //     query: GET_MY_PROFILE,
  //     fetchPolicy: 'network-only'
  //   });
  //   return result.data.getMyProfile;
  // };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.get_user_score().then(result => {

      // const high_impact = 30;
      // const tot_others = 30;


      const high_impact = parseFloat(result['0.4']['weightedValue']);
      const tot_others = parseFloat(result['0.3']['weightedValue']) + parseFloat(result['0.2']['weightedValue']) + parseFloat(result['0.1']['weightedValue']);

      // console.log(tot_others);
      // console.log(high_impact);
      const tot_marks = tot_others + high_impact;
      // console.log(tot_marks);
      // var result_status = "Not Ready";

      if (high_impact === 40 && tot_others >= 20) {
        this.setState({ result_status: "Ready", result_color: "RGB(146,208,80)" });
        // result_status="Ready";
      } else if (high_impact > 0 && tot_marks >= 60) {
        this.setState({ result_status: "Partially Ready.Additional development required in certain areas.", result_color: "RGB(255,192,0)" });
        // result_status="Partially Ready.Additional development required in certain areas.";
      }
      // console.log(result_status);
    });
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
      window.location.reload();
    });
  }

  get_user_score = async () => {
    const result = await this.props.client.query({
      query: GET_USER_SCORE,
      variables: { userId: this.state.userId },
      // fetchPolicy: 'cache-first'
      fetchPolicy: 'network-only'
    });
    return result.data.getUserScore;
  };

  render() {
    const { result_status, result_color } = this.state;
    const { t, i18n } = this.props;
    return (
      <div
        uk-height-viewport="offset-top: true; offset-bottom: true"
        className="uk-flex uk-flex-middle uk-margin"
        uk-scrollspy="target: > .welc-holder; cls:uk-animation-slide-bottom-small; delay: 200"
      >
        <div className="uk-width-1-2@m uk-width-1-2@s uk-margin-auto welc-holder">
          <div className="">
            <Form >
              {/* <h4 style={{color:result_color}}>{result_status}</h4> */}
              <h4 className="uk-text-bold uk-margin-remove-top">{t("Thank you for the interest shown and taking the time to complete the social enterprise readiness assessment questionnaire.")}</h4>
              <h4 className="uk-text-bold uk-margin-remove-top">{t("This information is important and will help us to assess your enterprise readiness.")}</h4>
              <h4 className="uk-text-bold uk-margin-remove-top">{t("We will get in touch if any queries on your responses or/and on the way forward once the responses are analysed at our end.")}</h4>

              <p className="uk-text-bold uk-margin-remove-top uk-margin-small-bottom" style={{ color: "#00A9E0" }}>
                - {t("IPPF Social Enterprise Hub")} -
              </p>

            </Form>
            {this.state.loading ? (<div class="spinner"></div>) : ("")}
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(withNamespaces()(Register));
