import React, { Component } from "react";
import Instructions from "./Instructions";
import MyAccount from "./MyAccount";
import Profile from './Profile';
import AssesmentHome from "./assesment/AssesmentHome";
import Register from '../common/Register';
import Welcome from '../common/Welcome';
import About from '../generic/About';
import Instruction from './Instruction';
import Contact from './Contact';
import ForgetPassword from './forget_password'
import PrivacyAndPolicy from './PrivacyAndPolicy';
import TermAndConditions from './TermAndConditions';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Image, Button, Modal, Dropdown } from 'semantic-ui-react';
import { isLoggedIn } from "../../services/CacheOperations";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  FIRST_NAME,
  LAST_NAME,
  AUTH_TOKEN,
  USER_NAME,
  LANGUAGE_OPTIONS
} from "../../constants/index";
import { hist } from './../../App';

import ReadinessAssessment from './ReadinessAssessment';
import IPPFReadinessAssTool from './ippfReadinessAssTool'
import Glossary from './Glossary'

//Ex Image
import userImage from '../../assets/images/avatures/avature.jpg';
import LogoEncyte from '../../assets/images/logo.png'
// import navigation_img from '../../assets/images/navigation_flow.jpg';
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";
import { EDIT_USER } from "../../queries/UserQueries";
import i18n from '../../i18n';


const languageOptions = [
  { text: "English", value: "en", key: "en" },
  { text: "العربية", value: "ar", key: "ar" },
  { text: "Français", value: "fr", key: "fr" },
  { text: "Español", value: "sp", key: "sp" },
];

localStorage.RAT_LANG = localStorage.RAT_LANG || "en";
const files = require.context('../../assets/locales', true);

class Home extends Component {
  constructor(props) {
    super(props);
    // const navigation_img = images(`./${this.props.i18n.language}/navigation_flow.jpg`);
    this.state = {
      navigation_img: ""
    }
    console.log(localStorage.USER_TYPE);
    if (this.props.location.pathname === "/users" && (localStorage.USER_TYPE === "MAUSER" || localStorage.USER_TYPE === undefined || localStorage.USER_TYPE === "")) {
      window.location = '/';
    }
    this.state = {
      open_navigation: false,
      size_navigation: "small",
      isLogin: this.props.location.pathname === "/" ? false : true,
      lang_value: localStorage.RAT_LANG || "en"
    }
    console.log(this.props.location.pathname);
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = localStorage.RAT_LANG === 'fr' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f7j2nrb4'
        : localStorage.RAT_LANG === 'ar' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84md8oh'
          : localStorage.RAT_LANG === 'sp' ? 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/1f84k7lac'
            : 'https://embed.tawk.to/5e2e97488e78b86ed8ab369e/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }
  _onLinkAbout = () => {
    console.log("about");
    document.body.scrollTop = 400;
    document.documentElement.scrollTop = 400;
  };

  _logout = () => {
    localStorage.AUTH_TOKEN = "";
    localStorage.FIRST_NAME = "";
    localStorage.LAST_NAME = "";
    localStorage.USER_TYPE = "";
    localStorage.USER_NAME = "";
    localStorage.removeItem('IS_FIRST_OPEN')
    window.location.href = "/";
  };
  showNavigation = () => {
    console.log(this.props.i18n.language)
    this.setState({
      open_navigation: true,
      navigation_img: files(`./${this.props.i18n.language === "en-US" ? "en" : this.props.i18n.language}/navigation_flow.jpg`)
    })
  }

  close_navigation = () => this.setState({ open_navigation: false })

  changeLanguage = async (e, { value }, data) => {
    localStorage.RAT_LANG = value;
    // || localStorage.USER_ID != "" || localStorage.USER_ID != null
    if (localStorage.USER_ID != undefined) {
      // || localStorage.USER_ID != null
      this.update_user_language(value).then((result) => {
        i18n.changeLanguage(value);
        this.setState({
          lang_value: value,
        });
        // this.setState(prevState => ({ lang_value: value }));
        window.location.reload();
      });
    }
    else {
      i18n.changeLanguage(value);
      this.setState({
        lang_value: value,
      });
      // this.setState(prevState => ({ lang_value: value }));
      window.location.reload();
    }
  }

  update_user_language = async (lang) => {
    const result = await this.props.client.mutate({
      mutation: EDIT_USER,
      variables: { userId: localStorage.USER_ID, languagePreference: lang }
    });
    return;
  };

  // open = () => this.setState({ open: true })
  render() {
    const { open_navigation, size_navigation, isLogin, navigation_img } = this.state
    const { t, i18n } = this.props;
    console.log(localStorage.RAT_LANG)
    return (
      <div>
        {/* Begin Main */}
        <div id="spinneroverlay">
          <div className="spinner"></div>
        </div>
        {/* End Main */}

        {/* Begin NavBar */}
        <div className=" tm-header uk-visible@m tm-header-transparent">
          <div
            media="768"
            animation="uk-animation-slide-top"
            cls-active="uk-navbar-sticky uk-nav-dark"
            sel-target=".uk-navbar-container"
            top='.tm-header ~ [class*="uk-section"], .tm-header ~ * > [class*="uk-section"]'
            cls-inactive="uk-navbar-transparent   uk-dark"
            className=" uk-sticky uk-navbar-container"
          >
            <div className="uk-position-relative">
              {/* <div className=" uk-container  uk-position-relative"> */}

              {localStorage.RAT_LANG == 'ar' ? (
                <nav className="paddingHeader uk-navbar uk-navbar-transparent">


                  <div className="lineHeight uk-navbar-right" >


                    {/* //////Below lines add */}

                    {isLoggedIn() ? (

                      <div class="uk-navbar-left" >
                        {/* <br/> */}
                        {/* <br/>
                       <br/> */}
                        {/* class="uk-navbar-right" */}

                        <label className="contact_Dropdown_ar">  <i className="fas fa-user icon-large uk-border-circle" ></i> <strong> {isLoggedIn().firstName} </strong> </label>

                        <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" className="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right">
                          <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                            {/* Hamse Mohamoud */}
                          </p>
                          <ul className="uk-nav uk-dropdown-nav">
                            <li>
                              <a href="/profile">
                                <i className="fas fa-cog uk-margin-small-left"></i>
                                {t("My Account")}</a>
                            </li>
                            <li className="uk-nav-divider"></li>
                            <li>
                              <a onClick={this._logout}>
                                <i className="fas fa-sign-out-alt uk-margin-small-left fa-flip-horizontal"></i>
                                {t("Log out")}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : ("")}

                    <div role="listbox" aria-expanded="false" className="ui top left pointing dropdown" tabIndex="0">

                      <div className="menu transition">
                        <div
                          style={{ "pointer-events": "all" }}
                          role="option"
                          aria-checked="false"
                          aria-selected="true"
                          className="selected item"
                        >
                          <i aria-hidden="true" className="user icon"></i>
                          <span className="text">{t("Account")}</span>
                        </div>
                        <div
                          style={{ "pointer-events": "all" }}
                          role="option"
                          aria-checked="false"
                          aria-selected="false"
                          className="item"
                        >
                          <i aria-hidden="true" className="settings icon"></i>
                          <span className="text">{t("Settings")}</span>
                        </div>
                        <div
                          style={{ "pointer-events": "all" }}
                          role="option"
                          aria-checked="false"
                          aria-selected="false"
                          className="item"
                        >
                          <i aria-hidden="true" className="sign out icon"></i>
                          <span className="text">{t("Sign Out")}</span>
                        </div>
                      </div>

                    </div>

                    {/* ///// Above line */}

                    <ul className="uk-navbar-nav toolbar-nav">

                      {/* //Uncomment to enable language feature */}
                      <li className="uk-text-capitalize" style={{paddingRight:"40px"}}>
                        <div style={{ position: "relative", paddingTop: "33.1px", paddingRight: "15px", paddingLeft: "60px" }}>
                          <Dropdown
                            className="languageSwitcherLogin_Ar"
                            options={languageOptions}
                            value={this.state.lang_value}
                            onChange={this.changeLanguage}
                          />
                        </div>
                      </li>

                      {isLoggedIn() ? (
                        <li >
                          {/* style={{marginRight:"50px"}} */}
                          <a className="Space" href="/assessment">{t("Home")}</a>
                        </li>
                      ) : (isLogin ? (
                        <li>
                          <a className="Space" href="/">{t("Login")}</a>
                        </li>
                      ) : ("")


                      )}

                      <li className="dropdown" >
                        <a className={localStorage.RAT_LANG == 'fr' ? "dropbtn_fr" : "dropbtn Space"} >{t("Readiness Assessment")}</a>
                        <div className="dropdown-content_ar" >
                          {isLoggedIn() ? (
                            <div style={{ marginLeft: "50px" }} >
                              <a href="/readinessAssessment" target="_blank"> {t("What is SE Readiness Assessment?")}</a>
                              <a href="/faq" target="_blank">{t("FAQs")}</a>
                              <a href="/glossary" target="_blank">{t("Glossary")}</a>
                              < a onClick={this.showNavigation}>{t("Navigation Guide")}</a>
                            </div>
                          ) : (
                            <div >
                              <a href="/readinessAssessment" target="_blank"> {t("What is SE Readiness Assessment?")}</a>
                              <a href="/faq" target="_blank">{t("FAQs")}</a>
                              <a href="/glossary" target="_blank">{t("Glossary")}</a>
                              < a onClick={this.showNavigation}>{t("Navigation Guide")}</a>
                            </div>
                          )}
                          {/* < a onClick={this.showNavigation}>{t("Navigation Guide")}</a> */}
                        </div>
                      </li>



                      {isLoggedIn() ? (
                        <li className="dropdown"  >
                          <a className={localStorage.RAT_LANG == 'fr' ? "dropbtn_fr" : "dropbtn Space"} >{t("Assessments")}
                          </a>
                          <div className="dropdown-content_ar">
                            <a href="/assessment?new=true">{t("Start an Assessment")}</a>
                            <a href="/assessment" >{t("Your previous Assessments")}</a>
                          </div>
                        </li>

                      ) : ("")
                      }
                      <li className="uk-text-uppercase">
                        <a  className="Space" href="/#aboutus_section" >{t("About Us")}</a>
                      </li>
                      <li className="uk-text-uppercase">
                        <a className="Space" href="/contact" target='_blank'>{t("Contact Us")}</a>
                      </li>


                    </ul>

                    {isLoggedIn() ? ("") : (

                      <a
                        className={localStorage.RAT_LANG == 'fr' ? "el-content uk-button uk-button-success uk-circle register_button_fr uk-navbar-right registerButton_alignment" : "el-content uk-button uk-button-success uk-circle uk-navbar-right registerButton_alignment"}
                        href="/register"
                      >
                        {" "}
                        {t("Register")}{" "}
                      </a>


                    )}

                    {/* ///// removed lines user icon */}

                  </div>

                  {/* ///Arabic */}
                  <div className="uk-navbar-left">
                    <a href="#" className="uk-logo black-logo">
                      <Image style={{ width: "75%", marginTop: "14.5px" }} className="uk-display-inline-block ippf-logo adm-logo"
                        src={require('../../assets/images/1_SE_IPPF_logo_1.png')}
                        fluid />
                      <Image style={{ width: "75%", float: "right", paddingBottom: "2px" }} className="uk-display-inline-block ippf-logo adm-logo"
                        src={require('../../assets/images/2_SE_IPPF_logo_old_edited_color.png')}
                        fluid />
                    </a>
                  </div>
                </nav>

              ) : (
                <nav className="paddingHeader uk-navbar uk-navbar-transparent">
                  <div className="uk-navbar-left">
                    <a href="#" className="uk-logo black-logo" >
                    {/* style={{display:"flex"}} */}
                      <Image style={{ width: "75%", paddingTop: "14px" }} className="uk-display-inline-block ippf-logo adm-logo"
                        src={require('../../assets/images/1_SE_IPPF_logo_1.png')}
                        fluid />
                      <Image style={{ width: "75%", float: "right" }} className="uk-display-inline-block ippf-logo adm-logo"
                        src={require('../../assets/images/2_SE_IPPF_logo_old_edited_color.png')}
                        fluid />
                      {/* <Image className="ippf-logo" src={require('../../assets/images/ippf-logo.png')} fluid /> */}
                    </a>
                  </div>
                  <div className="lineHeight uk-navbar-right" >
                    <ul className="uk-navbar-nav toolbar-nav">

                      {/* //Uncomment to enable language feature */}
                      <li className="uk-text-capitalize">
                        <div style={{ position: "relative", paddingTop: "31px" }}>
                          <Dropdown
                            className="languageSwitcherLogin"
                            options={languageOptions}
                            value={this.state.lang_value}
                            onChange={this.changeLanguage}
                          />

                        </div>
                      </li>

                      {/* <li>            
                      <a className="remove-affter"><Dropdown options={LANGUAGE_OPTIONS} value={this.props.i18n.language=== "en-US" ? "en":this.props.i18n.language} onChange={this.changeLanguage} /></a>
                    </li> */}
                      {isLoggedIn() ? (
                        <li>
                          <a href="/assessment">{t("Home")}</a>
                        </li>
                      ) : (isLogin ? (
                        <li>
                          <a href="/">{t("Login")}</a>
                        </li>
                      ) : ("")


                      )}

                      <li className="dropdown">
                        <a className={localStorage.RAT_LANG == 'fr' ? "dropbtn_fr" : "dropbtn"} >{t("Readiness Assessment")}</a>
                        <div className="dropdown-content">
                          {isLoggedIn() ? (
                            <div>
                              <a href="/readinessAssessment" target="_blank"> {t("What is SE Readiness Assessment?")}</a>
                              <a href="/faq" target="_blank">{t("FAQs")}</a>
                              <a href="/glossary" target="_blank">{t("Glossary")}</a>
                            </div>
                          ) : (
                            <div>
                              <a href="/readinessAssessment"> {t("What is SE Readiness Assessment?")}</a>
                              <a href="/faq">{t("FAQs")}</a>
                              <a href="/glossary">{t("Glossary")}</a>
                            </div>
                          )}
                          < a onClick={this.showNavigation}>{t("Navigation Guide")}</a>
                        </div>
                      </li>



                      {isLoggedIn() ? (
                        <li className="dropdown">
                          <a className={localStorage.RAT_LANG == 'fr' ? "dropbtn_fr" : "dropbtn"} >{t("Assessments")}
                          </a>
                          <div className="dropdown-content">
                            <a href="/assessment?new=true">{t("Start an Assessment")}</a>
                            <a href="/assessment" >{t("Your previous Assessments")}</a>
                          </div>
                        </li>

                      ) : ("")
                      }
                      <li className="uk-text-uppercase">
                        <a href="/#aboutus_section">{t("About Us")}</a>
                      </li>
                      <li className="uk-text-uppercase">
                        <a href="/contact" target='_blank'>{t("Contact Us")}</a>
                      </li>


                    </ul>

                    {isLoggedIn() ? ("") : (

                      <a
                        className={localStorage.RAT_LANG == 'fr' ? "el-content uk-button uk-button-success uk-circle register_button_fr uk-navbar-right registerButton_alignment" : "el-content uk-button uk-button-success uk-circle registerButton_alignment"}
                        href="/register"
                      >
                        {" "}
                        {t("Register")}{" "}
                      </a>


                    )}

                    {isLoggedIn() ? (
                      <div class="uk-navbar-right" >
                        {/* <br/>
                       <br/> */}
                        {/* <br/> */}
                        {/* class="uk-navbar-right" */}

                        <label className="contact_Dropdown"> <strong> {isLoggedIn().firstName} </strong> <i className="fas fa-user icon-large uk-border-circle" ></i> </label>

                        <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" className="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right">
                          <p className="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                            {/* Hamse Mohamoud */}
                          </p>
                          <ul className="uk-nav uk-dropdown-nav">
                            <li>
                              <a href="/profile">
                                <i className="fas fa-cog uk-margin-small-right"></i>
                                {t("My Account")}</a>
                            </li>
                            <li className="uk-nav-divider"></li>
                            <li>
                              <a onClick={this._logout}>
                                <i className="fas fa-sign-out-alt uk-margin-small-right"></i>
                                {t("Log out")}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : ("")}

                    <div role="listbox" aria-expanded="false" className="ui top left pointing dropdown" tabIndex="0">

                      <div className="menu transition">
                        <div
                          style={{ "pointer-events": "all" }}
                          role="option"
                          aria-checked="false"
                          aria-selected="true"
                          className="selected item"
                        >
                          <i aria-hidden="true" className="user icon"></i>
                          <span className="text">{t("Account")}</span>
                        </div>
                        <div
                          style={{ "pointer-events": "all" }}
                          role="option"
                          aria-checked="false"
                          aria-selected="false"
                          className="item"
                        >
                          <i aria-hidden="true" className="settings icon"></i>
                          <span className="text">{t("Settings")}</span>
                        </div>
                        <div
                          style={{ "pointer-events": "all" }}
                          role="option"
                          aria-checked="false"
                          aria-selected="false"
                          className="item"
                        >
                          <i aria-hidden="true" className="sign out icon"></i>
                          <span className="text">{t("Sign Out")}</span>
                        </div>
                      </div>

                    </div>

                  </div>
                </nav>

              )}






            </div>
          </div>
        </div>
        {/* End NavBar */}

        {/* Begin Router */}
        <Router>
          <Route exact path="/" component={Instructions} />
          <Route exact path="/forgetPassword" component={ForgetPassword} />
          <Route exact path="/instructions" component={Instructions} />
          <Route path="/assessment" component={AssesmentHome} />
          <Route path="/account" component={MyAccount} />
          <Route path="/profile" component={Profile} />
          <Route path="/submissions" component={Profile} />
          <Route exact path="/register" component={Register} />
          <Route path="/about" component={About} />
          {/* <Route path="/instruction" component={Instruction} /> */}
          <Route path="/contact" component={Contact} />
          {/* <Route path="/policy" component={PrivacyAndPolicy} /> */}
          {/* <Route path="/terms" component={TermAndConditions} /> */}
          <Route path="/welcome" component={Welcome} />
          <Route path="/readinessAssessment" component={ReadinessAssessment} />
          <Route path="/faq" component={IPPFReadinessAssTool} />
          <Route path="/glossary" component={Glossary} />
        </Router>

        {/* End Router */}

        {/* Begin Footer */}
        <div className="foot uk-section-small uk-margin-medium-top">
          <hr className="uk-margin-remove" />
          <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative">

            {localStorage.RAT_LANG == 'ar' ? (
              <div className="uk-grid uk-margin-small" uk-grid="true">
                <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-left">
                  <ul className="uk-list tm-footer-list">
                    <li >
                      <a style={{ textDecoration: "none" }} href="/#aboutus_section" uk-scroll data-uk-smooth-scroll="{duration: 500}">
                        {t("About Us")}
                      </a>
                    </li>
                    {/* "http://ra.seippf.org/userguide/" */}
                    <li> <a name="manual" href={localStorage.RAT_LANG == 'ar' ? "https://ra.seippf.org/userguide/ar" : localStorage.RAT_LANG == 'fr' ? "https://ra.seippf.org/userguide/fr" : localStorage.RAT_LANG == 'sp' ? "https://ra.seippf.org/userguide/es" : "https://ra.seippf.org/userguide/en"} target="_blank">{t("User Manual")}</a></li>
                    <li>
                      <a href="/contact" target='_blank'>
                        {t("Contact Us")}
                      </a>
                    </li>
                    <li>
                      {/* href="https://www.ippf.org/privacy" */}
                      <a href={localStorage.RAT_LANG == 'sp' ? "https://drive.google.com/file/d/17MF3-PtiGTQH03wriceSxx6jJc5mb-mt/view?usp=sharing" : localStorage.RAT_LANG == 'fr' ? "https://drive.google.com/file/d/1BHAd9w7PAU4ZCXFHAh2mqEB7JxjdI_A9/view?usp=sharing" : localStorage.RAT_LANG == 'ar' ? "https://drive.google.com/file/d/1bVElETZIbuqv0IsQ-ULbltCh7xaLblqQ/view" : "https://drive.google.com/file/d/1t56KrWgscRbhCW7Q4PZPn8cdqiKLuZDi/view?usp=sharing"} target="_blank">
                        {t("Privacy Policy")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto " uk-grid uk-height-match="target: SELECTOR">
                  <p style={{ float: "left" }} className="uk-link-heading uk-text-lead uk-text-bold">
                    <Image style={{ width: "75%", float: "left", marginTop: "14.5px" }} className="uk-display-inline-block ippf-logo adm-logo" src={require('../../assets/images/1_SE_IPPF_logo_1.png')} fluid />
                    <Image style={{ width: "75%", float: "right", paddingBottom: "2px" }} className="uk-display-inline-block ippf-logo adm-logo" src={require('../../assets/images/2_SE_IPPF_logo_old_edited_color.png')} fluid />
                  </p>
                </div>

              </div>
            ) : (
              <div className="uk-grid uk-margin-small" uk-grid="true">
                <div className="uk-width-2-3@m uk-width-1-2@s uk-first-column uk-margin-auto " uk-grid uk-height-match="target: SELECTOR">
                  <p className="uk-link-heading uk-text-lead uk-text-bold">
                    <Image style={{ width: "75%" }} className="uk-display-inline-block ippf-logo adm-logo" src={require('../../assets/images/1_SE_IPPF_logo_1.png')} fluid />
                    <Image style={{ width: "75%", }} className="uk-display-inline-block ippf-logo adm-logo" src={require('../../assets/images/2_SE_IPPF_logo_old_edited_color.png')} fluid />
                  </p>
                </div>
                <div className="uk-width-expand@m uk-width-1-1@s uk-flex  uk-flex-right">
                  <ul className="uk-list tm-footer-list">
                    <li >
                      <a style={{ textDecoration: "none" }} href="/#aboutus_section" target='_blank' uk-scroll data-uk-smooth-scroll="{duration: 500}">
                        {t("About Us")}
                      </a>
                    </li>
                    {/* "http://ra.seippf.org/userguide/" */}
                    <li> <a name="manual" href={localStorage.RAT_LANG == 'ar' ? "https://ra.seippf.org/userguide/ar" : localStorage.RAT_LANG == 'fr' ? "https://ra.seippf.org/userguide/fr" : localStorage.RAT_LANG == 'sp' ? "https://ra.seippf.org/userguide/es" : "https://ra.seippf.org/userguide/en"} target="_blank">{t("User Manual")}</a></li>
                    <li>
                      <a href="/contact" target='_blank'>
                        {t("Contact Us")}
                      </a>
                    </li>
                    <li>
                      {/* href="https://www.ippf.org/privacy" */}
                      <a href={localStorage.RAT_LANG == 'sp' ? "https://drive.google.com/file/d/17MF3-PtiGTQH03wriceSxx6jJc5mb-mt/view?usp=sharing" : localStorage.RAT_LANG == 'fr' ? "https://drive.google.com/file/d/1BHAd9w7PAU4ZCXFHAh2mqEB7JxjdI_A9/view?usp=sharing" : "https://drive.google.com/file/d/1t56KrWgscRbhCW7Q4PZPn8cdqiKLuZDi/view?usp=sharing"} target="_blank">
                        {t("Privacy Policy")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}


          </div>
        </div>
        <div className="footerCopyright uk-section-small" style={{ marginTop: "30px" }}>
          <div className="uk-container uk-align-center uk-margin-remove-bottom uk-position-relative" >

            {localStorage.RAT_LANG == 'ar' ? (
              <div className="uk-grid uk-margin-small" uk-grid="true">
                <div className="image uk-width-expand@m uk-width-1-2@s">
                  <p style={{ textAlign: "right" }}> {t("Powered By")} <a href="https://encyte.io" target="_blank"> <img src={LogoEncyte} /> </a></p>
                </div>

                <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                  <p>{t("Version 1 . 1 . 0")}</p>
                </div>

                {localStorage.RAT_LANG == "sp" ?
                  (
                    <div style={{ fontSize: "13px" }} className="copyRight uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                      <p> {t("Copyright (C) 2020")} - <span className="uk-text-bold">  {t("IPPF Social Enterprise Hub")}</span>. {t("All rights reserved")}. </p>
                    </div>
                  ) : (
                    <div className="copyRight uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                      <p> {t("Copyright (C) 2020")} - <span className="uk-text-bold">  {t("IPPF Social Enterprise Hub")}</span>. {t("All rights reserved")}. </p>
                    </div>
                  )
                }



              </div>

            ) : (
              <div className="uk-grid uk-margin-small" uk-grid="true">
                {localStorage.RAT_LANG == "sp" ?
                  (
                    <div style={{ fontSize: "13px" }} className="copyRight uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                      <p> {t("Copyright (C) 2020")} - <span className="uk-text-bold">  {t("IPPF Social Enterprise Hub")}</span>. {t("All rights reserved")}. </p>
                    </div>
                  ) : (
                    <div className="copyRight uk-width-1-2@m uk-width-1-2@s uk-first-column uk-margin-auto">
                      <p> {t("Copyright (C) 2020")} - <span className="uk-text-bold">  {t("IPPF Social Enterprise Hub")}</span>. {t("All rights reserved")}. </p>
                    </div>
                  )
                }

                <div className="copyRight uk-width-expand@m uk-width-1-2@s uk-text-center">
                  <p>{t("Version 1 . 1 . 0")}</p>
                </div>
                <div className="image uk-width-expand@m uk-width-1-2@s">
                  <p style={{ textAlign: "right" }}> {t("Powered By")} <a href="https://encyte.io" target="_blank"> <img src={LogoEncyte} /> </a></p>
                </div>
              </div>

            )}

          </div>
        </div>
        <Modal size={size_navigation} open={open_navigation}>
          {/* <Modal.Header>A quick navigation guide to taking up the Readiness Assessment </Modal.Header> */}
          <Modal.Header style={{ textAlign: "right", fontSize: "1em", borderBottom: "0px", padding: "17px" }}><i aria-hidden="true" class="close icon" style={{ cursor: "pointer" }} onClick={this.close_navigation}></i></Modal.Header>
          {/* <Modal.Actions><i aria-hidden="true" class="close icon" style={{cursor:"pointer"}} onClick={this.close_navigation}></i></Modal.Actions> */}
          <Modal.Content image style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Image className="uk-display-inline-block" uk-cover style={{ width: "100%" }} src={navigation_img} />
          </Modal.Content>
          <Modal.Actions style={{ borderTop: "0px" }}><i aria-hidden="true" class="close icon" style={{ cursor: "pointer" }} onClick={this.close_navigation}></i></Modal.Actions>
          {/* <Modal.Actions>
            <Button positive onClick={this.close_navigation}> {t("Ok")} </Button>
          </Modal.Actions> */}
        </Modal>
        {/* End Footer */}
      </div >
    );
  }
}

const styles = {
  darkBack: {
    backgroundColor: "rgba(0, 0, 0, 0.52)",
    backgroundBlendMode: "color-burn"
  },
  poweredBy: {
    bottom: "8px",
    right: "60px"
  }
};


// export default withRouter(withApollo(Home));
export default withRouter(withApollo(withNamespaces()(Home)));