import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from 'react-redux';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import { setFormStatus, setSuccessStatus } from "../../actions/user-actions"
import { USER_LOGIN } from "../../queries/UserQueries";
import {
  FIRST_NAME,
  LAST_NAME,
  AUTH_TOKEN,
  USER_NAME
} from "../../constants/index";
import { from } from "zen-observable";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}

class Login extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const {t, i18n } = this.props;
    const logOff = query.get('logOff');
    this.state = {
      email: "",
      password: "",
      loading: false,
      isLogOff: logOff ? JSON.parse(logOff) : false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.props.setSuccessStatus({ status: this.state.isLogOff, title: '', message: <div style={{ lineHeight: "19.999px" }}>{t("You have been logged out due to inactivity.")} <br /> {t("Please login again.")}</div> });
  }

  forget_password = () => {
    this.props.history.push('/forgetPassword')
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    this._loginUser();
  };

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  _loginUser = () => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });
    const { email, password } = this.state;
    const {t, i18n } = this.props;
    if (!email == '' || !password == '') {
      this.loginUser().then(result => {
        if (result.data.login.user.isActive) {
          localStorage.AUTH_TOKEN = result.data.login.token;
          localStorage.FIRST_NAME = result.data.login.user.firstName;
          localStorage.LAST_NAME = result.data.login.user.lastName;
          localStorage.USER_TYPE = result.data.login.user.userType;
          localStorage.USER_NAME = result.data.login.user.email;
          localStorage.USER_ID = result.data.login.user.id;
          //Redirect to Home if success
          this.setState({ loading: false });
          if (result.data.login.user.userType === 'ADMIN') {
            //redirect to admin page
            window.location.href = '/';
          } else {
            // redirect to user page
            window.location.href = '/assessment';
          }
        } else {
          this.props.setFormStatus({ status: true, title: 'Oops!', message: <span>{t("Your request has not been authorized yet!")}</span> });
          this.setState({ email: "", password: "", loading: false });
          return;
        }
      }).catch(error => {
        if (error) {
          console.log(error);          
          this.props.setFormStatus({ status: true, title: 'Oops!', message: <div style={{ lineHeight: "19.999px", paddingRight:"12px" }} className={localStorage.RAT_LANG=='fr'? "incorrect_cred_styles" : localStorage.RAT_LANG=='sp'? "incorrect_cred_styles":""} >{t("Your Email or Password appears to be wrong!")} <br /> {t("Please check your account details and try again.")}</div> });
        }
        this.setState({ loading: false });

      });

    } else {
      this.props.setFormStatus({ status: true, title: 'Oops!', message: <span>{t("Required All Fields!")}</span> });
      this.setState({ email: "", password: "", loading: false });
      return;
    }
  };

  loginUser = async () => {
    const { email, password } = this.state;
    const result = await this.props.client.mutate(
      {
        mutation: USER_LOGIN,
        variables: { email, password },
      })
    return result;
  }

  render() {
    // const { formErrorStatus } = this.props;
    // const { t, i18n } = this.props;
    const { formErrorStatus, formSuccessState, t, i18n } = this.props;
    return (
      <div
      // uk-height-viewport="offset-top: true; offset-bottom: true"
      // className="uk-flex uk-flex-middle"
      >
        <div className="login_container uk-margin-medium-top " style={{ paddingLeft: 0, }}>
          {/* <br /> */}
          {/* <div className="uk-width-1-3@m uk-width-1-2@s uk-margin-auto"> */}
          <div>
            {/* <div className="uk-padding"> */}
            <Form error onSubmit={this.handleSubmit}>
              <h2 className={localStorage.RAT_LANG=='fr'? "uk-text-bold uk-text-center loginTitle_fr": "uk-text-bold uk-text-center loginTitle" } >{t("Log in")}</h2>
              {/* <div className="uk-form-label Login_text">Official Email Address<i style={ styles.icon } className="icon red">*</i></div> */}
              <div className="uk-margin">

                <input
                  name="email"
                  id="email"
                  value={this.state.email}
                  className="uk-input uk-form-width-large"
                  placeholder={t("Official Email Address")}
                  type="email"
                  onChange={this.handleChange}
                  required
                />
              </div>
              {/* <div className="uk-form-label Login_text">Password<i style={ styles.icon } className="icon red">*</i></div> */}
              <div className="uk-margin">
                <input
                  name="password"
                  id="password"
                  value={this.state.password}
                  className="uk-input uk-form-width-large"
                  placeholder={t("Password")}
                  type="password"
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="uk-margin uk-text-center">
                <input
                  className="uk-button ui positive button uk-margin uk-width-1-2"
                  type="submit"
                  value={t("Sign in")}
                  loading={this.state.loading}
                // disabled={!this.state.email || !this.state.password}
                />
              </div>
              {formErrorStatus.status == true ? (
                <div className="danger_alert">{formErrorStatus.message}</div>
                // <Message
                //   error
                //   content={formErrorStatus.message}
                // />
              ) : ('')}
              {formSuccessState.status ? (
                <div className={localStorage.RAT_LANG=='ar'? "warning_alert_ar" : "warning_alert"} >{formSuccessState.message}</div>
                // <Message
                //   error
                //   content={formErrorStatus.message}
                // />
              ) : ('')}
            </Form>
            {this.state.loading ? (<div class="spinner"></div>) : ("")}
            <p className={localStorage.RAT_LANG=='fr'? "uk-text-center Login_text dont_hv_ac_style_fr" : "uk-text-center Login_text" }  style={{ marginTop: "0px", marginBottom: "0px" }}>
            {t("Don't Have an Account Yet?")} <button onClick={() => { window.location.href = '/register' }} class="uk-button uk-button-text Login_text verticalAlign"> {t("Register Here")} </button>
            </p>
            <p className="uk-text-center" style={{ marginTop: "0px", marginBottom: "0px" }}>
              <button class="uk-button uk-button-text Login_text" onClick={() => this.forget_password()}> {t("Forgot Password")} </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  icon: {
    width: "0px"
  }
};
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Login)))); 
