import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from '../../../../actions/user-actions';
import { withApollo } from 'react-apollo';
import { Form, Select, Dropdown, Button, Message, Modal, Image } from "semantic-ui-react";
import { ADD_SUBMISSION, GET_GENERAL_INFO } from '../../../../queries/SubmissionQueries';
import { GET_MY_PROFILE } from "../../../../queries/UserQueries";
import navigation_img from '../../../../assets/images/navigation_flow.jpg';
//import firebase from '../../../firebase/firebase';
import { checkReference } from '../../../../firebase/FileServices';
import {
    USER_NAME
} from "../../../../constants/index";
import { async } from "@firebase/util";
import { withNamespaces } from "react-i18next";

var countryOptions = [];
const countryList = {
    rg1: [
        { "key": "ctr1", "value": "Benin", "text": "Benin" },
        { "key": "ctr2", "value": "Botswana", "text": "Botswana" },
        { "key": "ctr3", "value": "Burkina Faso", "text": "Burkina Faso" },
        { "key": "ctr4", "value": "Burundi", "text": "Burundi" },
        { "key": "ctr5", "value": "Cameroon", "text": "Cameroon" },
        { "key": "ctr6", "value": "Cape Verde", "text": "Cape Verde" },
        { "key": "ctr7", "value": "Central African Republic", "text": "Central African Republic" },
        { "key": "ctr8", "value": "Chad", "text": "Chad" },
        { "key": "ctr9", "value": "Comoros", "text": "Comoros" },
        { "key": "ctr10", "value": "Republic of the Congo", "text": "Republic of the Congo" },
        { "key": "ctr11", "value": "Democratic Republic of the Congo", "text": "Democratic Republic of the Congo" },
        { "key": "ctr12", "value": "Cote d’Ivoire", "text": "Cote d’Ivoire" },
        { "key": "ctr13", "value": "Eswatini", "text": "Eswatini" },
        { "key": "ctr14", "value": "Ethiopia", "text": "Ethiopia" },
        { "key": "ctr15", "value": "Ghana", "text": "Ghana" },
        { "key": "ctr16", "value": "Guinea-Bissau", "text": "Guinea-Bissau" },
        { "key": "ctr17", "value": "Guinea-Conakry", "text": "Guinea-Conakry" },
        { "key": "ctr18", "value": "Lesotho", "text": "Lesotho" },
        
        { "key": "ctr19", "value": "Madagascar", "text": "Madagascar" },
        { "key": "ctr20", "value": "Malawi", "text": "Malawi" },
        { "key": "ctr21", "value": "Mali", "text": "Mali" },
        { "key": "ctr22", "value": "Mozambique", "text": "Mozambique" },
        { "key": "ctr23", "value": "Namibia", "text": "Namibia" },
        { "key": "ctr24", "value": "Niger", "text": "Niger" },
        { "key": "ctr25", "value": "Nigeria", "text": "Nigeria" },
        { "key": "ctr26", "value": "Sierra Leone", "text": "Sierra Leone" },
        { "key": "ctr27", "value": "Tanzania", "text": "Tanzania" },
        { "key": "ctr28", "value": "Togo", "text": "Togo" },
        { "key": "ctr29", "value": "Uganda", "text": "Uganda" },
        { "key": "ctr30", "value": "Zambia", "text": "Zambia" },
        { "key": "ctr31", "value": "Mauritius", "text": "Mauritius" },
        { "key": "ctr32", "value": "Sao Tome & Principe", "text": "Sao Tome & Principe" },
        { "key": "ctr33", "value": "Kenya", "text": "Kenya" },
        { "key": "ctr34", "value": "Liberia", "text": "Liberia" },
        { "key": "ctr35", "value": "Republic of South Africa", "text": "Republic of South Africa" },
        { "key": "ctr36", "value": "Rwanda", "text": "Rwanda" },
        { "key": "ctr37", "value": "Senegal", "text": "Senegal" },
        { "key": "ctr38", "value": "Seychelles", "text": "Seychelles" },
        { "key": "ctr39", "value": "South Sudan", "text": "South Sudan" },
        { "key": "ctr40", "value": "Zimbabwe", "text": "Zimbabwe" },


    ],
    rg2: [
        { "key": "ctr41", "value": "Algeria", "text": "Algeria" },
        { "key": "ctr42", "value": "Bahrain", "text": "Bahrain" },
        { "key": "ctr43", "value": "Egypt", "text": "Egypt" },
        { "key": "ctr44", "value": "Lebanon", "text": "Lebanon" },
        { "key": "ctr45", "value": "Mauritania", "text": "Mauritania" },
        { "key": "ctr46", "value": "Morocco", "text": "Morocco" },
        { "key": "ctr47", "value": "Pakistan", "text": "Pakistan" },
        { "key": "ctr48", "value": "Palestine", "text": "Palestine" },
        { "key": "ctr49", "value": "Somaliland", "text": "Somaliland" },
        { "key": "ctr50", "value": "Sudan", "text": "Sudan" },
        { "key": "ctr51", "value": "Syria", "text": "Syria" },
        { "key": "ctr52", "value": "Tunisia", "text": "Tunisia" },
        { "key": "ctr53", "value": "Yemen", "text": "Yemen" },
        { "key": "ctr54", "value": "Libya", "text": "Libya" },
        { "key": "ctr55", "value": "Jordan", "text": "Jordan" },


    ],
    rg3: [
        { "key": "ctr56", "value": "Cambodia", "text": "Cambodia" },
        { "key": "ctr57", "value": "China", "text": "China" },
        { "key": "ctr58", "value": "Cook Islands", "text": "Cook Islands" },
        { "key": "ctr59", "value": "Fiji", "text": "Fiji" },
        { "key": "ctr60", "value": "Hong Kong", "text": "Hong Kong" },
        { "key": "ctr61", "value": "Indonesia", "text": "Indonesia" },
        { "key": "ctr62", "value": "Kiribati", "text": "Kiribati" },
        { "key": "ctr63", "value": "Democratic People's Republic of Korea", "text": "Democratic People's Republic of Korea" },
        { "key": "ctr64", "value": "Republic of Korea", "text": "Republic of Korea" },
        { "key": "ctr65", "value": "Malaysia", "text": "Malaysia" },
        { "key": "ctr66", "value": "Mongolia", "text": "Mongolia" },
        { "key": "ctr67", "value": "New Zealand", "text": "New Zealand" },
        { "key": "ctr68", "value": "Philippines", "text": "Philippines" },
        { "key": "ctr69", "value": "Samoa", "text": "Samoa" },
        { "key": "ctr70", "value": "Solomon Islands", "text": "Solomon Islands" },
        { "key": "ctr71", "value": "Thailand", "text": "Thailand" },
        { "key": "ctr72", "value": "Tonga", "text": "Tonga" },
        { "key": "ctr73", "value": "Tuvalu", "text": "Tuvalu" },
        { "key": "ctr74", "value": "Vanuatu", "text": "Vanuatu" },
        { "key": "ctr75", "value": "Vietnam", "text": "Vietnam" },
        { "key": "ctr76", "value": "Australia", "text": "Australia" },
        { "key": "ctr77", "value": "Papua New Guinea", "text": "Papua New Guinea" },
        { "key": "ctr78", "value": "Laos", "text": "Laos" },
        { "key": "ctr79", "value": "Myanmar", "text": "Myanmar" },
        { "key": "ctr80", "value": "Japan", "text": "Japan" },

    ],
    rg4: [
        { "key": "ctr81", "value": "Albania", "text": "Albania" },
        { "key": "ctr82", "value": "Austria", "text": "Austria" },
        { "key": "ctr83", "value": "Belgium", "text": "Belgium" },
        { "key": "ctr84", "value": "Bosnia and Herzegovina", "text": "Bosnia and Herzegovina" },
        { "key": "ctr85", "value": "Bulgaria", "text": "Bulgaria" },
        { "key": "ctr86", "value": "Cyprus", "text": "Cyprus" },
        { "key": "ctr87", "value": "Denmark", "text": "Denmark" },
        { "key": "ctr88", "value": "Estonia", "text": "Estonia" },
        { "key": "ctr89", "value": "Finland", "text": "Finland" },
        { "key": "ctr90", "value": "France", "text": "France" },
        { "key": "ctr91", "value": "Georgia", "text": "Georgia" },
        { "key": "ctr92", "value": "Germany", "text": "Germany" },
        { "key": "ctr93", "value": "Ireland", "text": "Ireland" },
        { "key": "ctr94", "value": "Israel", "text": "Israel" },
        { "key": "ctr95", "value": "Kazakhstan", "text": "Kazakhstan" },
        { "key": "ctr96", "value": "Kyrgyzstan", "text": "Kyrgyzstan" },
        { "key": "ctr97", "value": "Latvia", "text": "Latvia" },
        { "key": "ctr98", "value": "Netherlands", "text": "Netherlands" },
        { "key": "ctr99", "value": "Norway", "text": "Norway" },
        { "key": "ctr100", "value": "Portugal", "text": "Portugal" },
        { "key": "ctr101", "value": "Republic of Macedonia", "text": "Republic of Macedonia" },
        { "key": "ctr102", "value": "Republic of Serbia", "text": "Republic of Serbia" },
        { "key": "ctr103", "value": "Romania", "text": "Romania" },
        { "key": "ctr104", "value": "Slovak Republic", "text": "Slovak Republic" },
        { "key": "ctr105", "value": "Spain", "text": "Spain" },
        { "key": "ctr106", "value": "Sweden", "text": "Sweden" },
        { "key": "ctr107", "value": "Switzerland", "text": "Switzerland" },
        { "key": "ctr108", "value": "Tajikistan", "text": "Tajikistan" },
        { "key": "ctr109", "value": "Ukraine", "text": "Ukraine" },
        { "key": "ctr110", "value": "Croatia", "text": "Croatia" },
        { "key": "ctr111", "value": "Hungary", "text": "Hungary" },
        { "key": "ctr112", "value": "Italy", "text": "Italy" },
        { "key": "ctr113", "value": "Malta", "text": "Malta" },
        { "key": "ctr114", "value": "Moldova", "text": "Moldova" },
        { "key": "ctr115", "value": "Poland", "text": "Poland" },
        { "key": "ctr116", "value": "Slovenia", "text": "Slovenia" },
        { "key": "ctr117", "value": "Uzbekistan", "text": "Uzbekistan" },

    ],
    rg5: [
        { "key": "ctr118", "value": "Afghanistan", "text": "Afghanistan" },
        { "key": "ctr119", "value": "Bangladesh", "text": "Bangladesh" },
        { "key": "ctr120", "value": "India", "text": "India" },
        { "key": "ctr121", "value": "Maldives", "text": "Maldives" },
        { "key": "ctr122", "value": "Nepal", "text": "Nepal" },
        // { "key": "ctr123", "value": "Pakistan", "text": "Pakistan" },
        { "key": "ctr123", "value": "Sri Lanka", "text": "Sri Lanka" },
        { "key": "ctr124", "value": "Bhutan", "text": "Bhutan" },
    ],
    rg6: [
        { "key": "ctr125", "value": "Barbados", "text": "Barbados" },
        { "key": "ctr126", "value": "Colombia", "text": "Colombia" },
        { "key": "ctr127", "value": "Jamaica", "text": "Jamaica" },
        { "key": "ctr128", "value": "Mexico", "text": "Mexico" },
        { "key": "ctr129", "value": "Peru", "text": "Peru" },
        { "key": "ctr130", "value": "Suriname", "text": "Suriname" },
        { "key": "ctr131", "value": "Trinidad and Tobago", "text": "Trinidad and Tobago" },
        { "key": "ctr132", "value": "United States of America", "text": "United States of America" },
        { "key": "ctr133", "value": "Antigua", "text": "Antigua" },
        { "key": "ctr134", "value": "Aruba", "text": "Aruba" },
        { "key": "ctr135", "value": "Bolivia", "text": "Bolivia" },
        { "key": "ctr136", "value": "Chile", "text": "Chile" },
        { "key": "ctr137", "value": "Dominica", "text": "Dominica" },
        { "key": "ctr138", "value": "Guadeloupe", "text": "Guadeloupe" },
        { "key": "ctr139", "value": "Ecuador", "text": "Ecuador" },
        { "key": "ctr140", "value": "Guyana", "text": "Guyana" },
        { "key": "ctr141", "value": "Curacao", "text": "Curacao" },
        { "key": "ctr142", "value": "Grenada", "text": "Grenada" },
        { "key": "ctr143", "value": "Martinique", "text": "Martinique" },
        { "key": "ctr144", "value": "St. Lucia", "text": "St. Lucia" },
        { "key": "ctr145", "value": "St. Vincent", "text": "St. Vincent" },
        { "key": "ctr146", "value": "Guatemala", "text": "Guatemala" },
        { "key": "ctr147", "value": "Venezuela", "text": "Venezuela" },
        { "key": "ctr148", "value": "Argentina", "text": "Argentina" },
        { "key": "ctr149", "value": "Brazil", "text": "Brazil" },
        { "key": "ctr150", "value": "Haiti", "text": "Haiti" },
        { "key": "ctr151", "value": "Cuba", "text": "Cuba" },
        { "key": "ctr152", "value": "Honduras", "text": "Honduras" },
    
    ]
}

const regionOption = [
    { key: "rg1", value: "Africa", text: "Africa" },
    { key: "rg6", value: "Americas and the Caribbean", text: "Americas and the Caribbean" },
    { key: "rg2", value: "Arab World", text: "Arab World" },
    { key: "rg3", value: "East and Southeast Asia and Oceania", text: "East and Southeast Asia and Oceania" },
    { key: "rg4", value: "European Network", text: "European Network" },
    { key: "rg5", value: "South Asia", text: "South Asia" }
];
const yearOptions = [
    { key: "1940", value: 1940, text: "1940" },
    { key: "1941", value: 1941, text: "1941" },
    { key: "1942", value: 1942, text: "1942" },
    { key: "1943", value: 1943, text: "1943" },
    { key: "1944", value: 1944, text: "1944" },
    { key: "1945", value: 1945, text: "1945" },
    { key: "1946", value: 1946, text: "1946" },
    { key: "1947", value: 1947, text: "1947" },
    { key: "1948", value: 1948, text: "1948" },
    { key: "1949", value: 1949, text: "1949" },
    { key: "1950", value: 1950, text: "1950" },
    { key: "1951", value: 1951, text: "1951" },
    { key: "1952", value: 1952, text: "1952" },
    { key: "1953", value: 1953, text: "1953" },
    { key: "1954", value: 1954, text: "1954" },
    { key: "1955", value: 1955, text: "1955" },
    { key: "1956", value: 1956, text: "1956" },
    { key: "1957", value: 1957, text: "1957" },
    { key: "1958", value: 1958, text: "1958" },
    { key: "1959", value: 1959, text: "1959" },
    { key: "1960", value: 1960, text: "1960" },
    { key: "1961", value: 1961, text: "1961" },
    { key: "1962", value: 1962, text: "1962" },
    { key: "1963", value: 1963, text: "1963" },
    { key: "1964", value: 1964, text: "1964" },
    { key: "1965", value: 1965, text: "1965" },
    { key: "1966", value: 1966, text: "1966" },
    { key: "1967", value: 1967, text: "1967" },
    { key: "1968", value: 1968, text: "1968" },
    { key: "1969", value: 1969, text: "1969" },
    { key: "1970", value: 1970, text: "1970" },
    { key: "1971", value: 1971, text: "1971" },
    { key: "1972", value: 1972, text: "1972" },
    { key: "1973", value: 1973, text: "1973" },
    { key: "1974", value: 1974, text: "1974" },
    { key: "1975", value: 1975, text: "1975" },
    { key: "1976", value: 1976, text: "1976" },
    { key: "1977", value: 1977, text: "1977" },
    { key: "1978", value: 1978, text: "1978" },
    { key: "1979", value: 1979, text: "1979" },
    { key: "1980", value: 1980, text: "1980" },
    { key: "1981", value: 1981, text: "1981" },
    { key: "1982", value: 1982, text: "1982" },
    { key: "1983", value: 1983, text: "1983" },
    { key: "1984", value: 1984, text: "1984" },
    { key: "1985", value: 1985, text: "1985" },
    { key: "1986", value: 1986, text: "1986" },
    { key: "1987", value: 1987, text: "1987" },
    { key: "1988", value: 1988, text: "1988" },
    { key: "1989", value: 1989, text: "1989" },
    { key: "1990", value: 1990, text: "1990" },
    { key: "1991", value: 1991, text: "1991" },
    { key: "1992", value: 1992, text: "1992" },
    { key: "1993", value: 1993, text: "1993" },
    { key: "1994", value: 1994, text: "1994" },
    { key: "1995", value: 1995, text: "1995" },
    { key: "1996", value: 1996, text: "1996" },
    { key: "1997", value: 1997, text: "1997" },
    { key: "1998", value: 1998, text: "1998" },
    { key: "1999", value: 1999, text: "1999" },
    { key: "2000", value: 2000, text: "2000" },
    { key: "2001", value: 2001, text: "2001" },
    { key: "2002", value: 2002, text: "2002" },
    { key: "2003", value: 2003, text: "2003" },
    { key: "2004", value: 2004, text: "2004" },
    { key: "2005", value: 2005, text: "2005" },
    { key: "2006", value: 2006, text: "2006" },
    { key: "2007", value: 2007, text: "2007" },
    { key: "2008", value: 2008, text: "2008" },
    { key: "2009", value: 2009, text: "2009" },
    { key: "2010", value: 2010, text: "2010" },
    { key: "2011", value: 2011, text: "2011" },
    { key: "2012", value: 2012, text: "2012" },
    { key: "2013", value: 2013, text: "2013" },
    { key: "2014", value: 2014, text: "2014" },
    { key: "2015", value: 2015, text: "2015" },
    { key: "2016", value: 2016, text: "2016" },
    { key: "2017", value: 2017, text: "2017" },
    { key: "2018", value: 2018, text: "2018" },
    { key: "2019", value: 2019, text: "2019" },
    { key: "2020", value: 2020, text: "2020" },
    { key: "2021", value: 2021, text: "2021" },
    { key: "2022", value: 2022, text: "2022" },
    { key: "2023", value: 2023, text: "2023" },
    { key: "2024", value: 2024, text: "2024" },

];
const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}
class NewSection1 extends Component {
    constructor(props) {
        super(props);
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.state = {
            open: true,
            firstName: "",
            lastName: "",
            fullName: "",
            country: "",
            region: "",
            year: '',
            contactPersonFullName: "",
            contactPersonEmail: "",
            contactPersonSkype: "",
            loading: true,
            sectionEmpty: true,
            size: "small",
            imageStatus: "loading",
            assessmentName: ""
        };
        this._checkGeneralInfo();

        this.checkRef();
    }

    checkRef = async () => {
        const result = await checkReference(localStorage.USER_NAME);
        console.log('Checking Reference for user ' + result);
    }

    handleImageLoaded() {
        console.log('Loaded');
        this.open();
        this.setState({ imageStatus: "loaded" });
    }

    handleImageErrored() {
        this.setState({ imageStatus: "failed to load" });
    }

    _checkGeneralInfo = () => {
        this.props.client.query({
            query: GET_GENERAL_INFO,
            fetchPolicy: 'network-only'
        }).then(result => {
            console.log(result.data);
            if (result.data.getGeneralInfo === null || result.data.getGeneralInfo.length === 0) {
                console.log('there is nothing here');
            } else {
                // Apply values 
                if (result.data.getGeneralInfo.region !== "NA") {
                    const selectedOption = regionOption.filter(function (item) {
                        return item.value == result.data.getGeneralInfo.region;
                    });
                    console.log(selectedOption)
                    const key = selectedOption[0].key;
                    countryOptions = countryList[key];
                }

                countryOptions.sort((a, b) => (a['text'] || "").toString().localeCompare((b['text'] || "").toString()));
                this.props.client.query({
                    query: GET_MY_PROFILE,
                }).then(result => {
                    console.log(result);
                    this.setState({
                        // loading: false,
                        firstName: result.data.getMyProfile.user.firstName,
                        lastName: result.data.getMyProfile.user.lastName,

                    })
                }).catch(error => {
                    this.setState({
                        // loading: false,
                    })
                });

                this.setState({
                    fullName: result.data.getGeneralInfo.fullName,
                    country: result.data.getGeneralInfo.country === "NA" ? "" : result.data.getGeneralInfo.country,
                    region: result.data.getGeneralInfo.region === "NA" ? "" : result.data.getGeneralInfo.region,
                    year: result.data.getGeneralInfo.year,
                    contactPersonFullName: result.data.getGeneralInfo.contactPersonFullName,
                    contactPersonEmail: result.data.getGeneralInfo.contactPersonEmail,
                    contactPersonSkype: result.data.getGeneralInfo.contactPersonSkype,
                    sectionEmpty: false,
                    assessmentName: result.data.getGeneralInfo.assessmentName
                })
            }
            this.setState({ loading: false, });
        }).catch(error => {
            console.log(error)
            console.log('there was an error calling general info');
            this.setState({ loading: false, });
        })
    }


    _submitGeneralInfo = (flag, path = "") => {
        const { t, i18n } = this.props;
        this.setState({ loading: true });
        this.props.client.mutate({
            mutation: ADD_SUBMISSION,
            variables: {
                fullName: this.state.fullName,
                country: this.state.country === '' ? 'NA' : this.state.country,
                region: this.state.region === '' ? 'NA' : this.state.region,
                year: parseInt(this.state.year),
                contactPersonFullName: this.state.contactPersonFullName,
                contactPersonEmail: this.state.contactPersonEmail,
                contactPersonSkype: this.state.contactPersonSkype,
                assessmentName: this.state.assessmentName
            }
        }).then(result => {
            console.log(result)
            localStorage.SUBMISSION_ID = result
            this._checkGeneralInfo();
            this.setState({ loading: false });
            this.props.setSuccessStatus({
                status: true,
                title: "",
                message: t("Saved successfully!")

            });
            if (flag === true) {
                this.props.history.push(`/assessment/new/section2`);
            } else {
                this.props.history.push({ pathname: "/assessment/new/".concat(path) });
            }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    t("There was an error while trying to update data")

            });
        });
    }

    _next = (flag, path = "") => {
        const { t, i18n } = this.props;
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const { fullName, country, region, year, contactPersonFullName, contactPersonEmail, contactPersonSkype, assessmentName } = this.state;
        const emailField = document.getElementById('contactPersonEmail');
        const isValidEmail = emailField.checkValidity();

        const fullNameField = document.getElementById('contactPersonFullName');
        const isValidFullName = fullNameField.checkValidity();
        if (isValidFullName && isValidEmail) {
            if (!fullName == '' && !contactPersonFullName == '' && !contactPersonEmail == '' && !assessmentName == '') {
                this._submitGeneralInfo(flag, path);
            } else {
                this.setState({
                    loading: false,
                });
                this.props.setFormStatus({
                    status: true,
                    title: "Oops!",
                    message:
                        localStorage.RAT_LANG == 'ar' ? (
                            <span style={{ fontWeight: "bold", paddingRight: "12px" }}>{t("Please complete all mandatory fields.")}</span>
                        ) : (
                            <span style={{ fontWeight: "bold" }}>{t("Please complete all mandatory fields.")}</span>
                        )

                });
            }
        } else {
            this.setState({
                loading: false,
            });
            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    localStorage.RAT_LANG == 'ar' ? (
                        <span style={{ fontWeight: "bold", paddingRight: "12px" }}>{t("Please complete all mandatory fields.")}</span>
                    ) : (
                        <span style={{ fontWeight: "bold" }}>{t("Please complete all mandatory fields.")}</span>
                    )
            });
        }
    };

    _nextWithoutUpdate = () => {
        this.props.history.push(`/assessment/new/section2`);
    }

    showContent(response) {
        console.log(response);
    }

    _handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    _handleYearChange = (e, { value }) => this.setState({ year: value });

    _handleRegionChange = (e, { value }) => {
        countryOptions = [];
        const selectedOption = regionOption.filter(function (item) {
            return item.value == value;
        });
        this.setState({ region: value });
        const key = selectedOption[0].key;
        countryOptions = countryList[key];
    }

    _handleCountryChange = (e, { value }) => this.setState({ country: value });

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    // const orderPlans = plans => (
    //   _.orderBy(plans, item => (
    //     // get each item, extract the number from `name` and
    //     // parse it to int, so that the order function works
    //     // correctly. otherwise, we would have something like
    //     // 1 month, 10 months, 11 months, 2 months
    //     parseInt(
    //       item.name.replace(/\D+/gi, ''))
    //     )
    //   )
    // );

    close = () => this.setState({ open: false })

    open = () => this.setState({ open: true })

    _goBack = () => {
        // this.props.history.goBack();
        window.location.href = "/assessment";
    };

    _pagi = (path) => {
        // const { submissionId, submissionType } = this.state;
        // this.props.setFormStatus({ status: false, title: '', message: '' });
        // this.props.setSuccessStatus({ status: false, title: '', message: '' });
        // const { fullName, contactPersonFullName, contactPersonEmail, assessmentName } = this.state;
        // const emailField = document.getElementById('contactPersonEmail');
        // const isValidEmail = emailField.checkValidity();

        // const fullNameField = document.getElementById('contactPersonFullName');
        // const isValidFullName = fullNameField.checkValidity();
        // if (isValidFullName && isValidEmail) {
        //     if (!fullName == '' && !contactPersonFullName == '' && !contactPersonEmail == '' && !assessmentName == '') {
        //         this._submitGeneralInfo(true);
        //         // this.props.history.push({ pathname: "/assessment/new/".concat(path) });
        //     } else {
        //         this.props.setFormStatus({
        //             status: true,
        //             title: "Oops!",
        //             message: "Complete all mandatory fields."
        //         });
        //     }
        // }else{
        //     this.props.setFormStatus({
        //         status: true,
        //         title: "Oops!",
        //         message: "Complete all mandatory fields."
        //     }); 
        // }
        this._next(-1, path)
    }

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        const { open, size } = this.state

        return (
            <div>
                {/* Begin Dark Header */}
                <div className="uk-section-default uk-section">
                    <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                        {/* <h3 className=" uk-text-light">
              {" "}
              General Details{" "}
            </h3> */}
                    </div>
                    {/* <hr /> */}
                    <Form success error>
                        <div
                            className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                            uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                        >
                            <div className="paper uk-link-reset " style={{ backgroundColor: "#686c6d" }}>
                                <div className="uk-grid uk-flex-middle">
                                    <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                        <i className="far fa-comment-alt icon-xxlarge"></i>
                                    </div>
                                    <div className="uk-width-5-6">
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <h3 className="uk-margin-remove" style={{ color: "#ffffff", fontSize: "1.4rem" }}>
                                                {" "}
                                                {t("General Details")}{" "}
                                            </h3>
                                        ) : (
                                            <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                                                {" "}
                                                {t("General Details")}{" "}
                                            </h4>
                                        )
                                        }


                                    </div>
                                </div>
                            </div>
                            <div className="uk-link-reset paper uk-margin-remove-bottom">
                                {/* General Details */}
                                <div
                                    id="register"
                                    className="animation: uk-animation-slide-left-medium"
                                >
                                    <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                                        {" "}
                                    </p>

                                    <div className="uk-form-label">{t("User Category")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="firstName"
                                            id="firstName"
                                            value={(this.state.fullName === "International Planned Parenthood Federation") ? (
                                                "Central Office"
                                            ) : (this.state.fullName === "NA") ? ("Regional Office") : ("Member Association")}
                                            className="uk-input uk-form-width-large"
                                            placeholder=""
                                            type="text"
                                            onChange={this._handleChange}
                                            // required
                                            readOnly
                                            disabled
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("First Name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="firstName"
                                            id="firstName"
                                            value={this.state.firstName}
                                            className="uk-input uk-form-width-large"
                                            placeholder=""
                                            type="text"
                                            onChange={this._handleChange}
                                            // required
                                            readOnly
                                            disabled
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Last Name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="lastName"
                                            id="lastName"
                                            value={this.state.lastName}
                                            className="uk-input uk-form-width-large"
                                            placeholder=""
                                            type="text"
                                            onChange={this._handleChange}
                                            // required
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                    <div className="uk-form-label">
                                        {(this.state.fullName === "International Planned Parenthood Federation") ? (
                                            t("Organization")
                                        ) : (this.state.fullName === "NA") ? t("Regional Office Name") : t("Full name of the Member Association (MA)")}
                                        <i style={styles.icon} className="icon red">*</i>
                                    </div>
                                    <div>
                                        <input
                                            onChange={this._handleChange}
                                            name="fullName"
                                            id="fullName"
                                            value={this.state.fullName}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Full name")}
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>
                                    <div className="uk-form-label">{t("IPPF Region")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="region"
                                            id="region"
                                            value={this.state.region === "" ? "NA" : this.state.region}
                                            className="uk-input uk-form-width-large"
                                            // placeholder="Full name"
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>
                                    <div className="uk-form-label">{t("Country")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <input
                                            name="country"
                                            id="country"
                                            value={this.state.country === "" ? "NA" : this.state.country}
                                            className="uk-input uk-form-width-large"
                                            // placeholder="Full name"
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>


                                    <div className="uk-form-label">{t("Year of establishment")}</div>
                                    <div>
                                        <input
                                            name="country"
                                            id="country"
                                            value={this.state.year === 0 || this.state.year === "" ? "NA" : this.state.year}
                                            className="uk-input uk-form-width-large"
                                            // placeholder="Full name"
                                            type="text"
                                            // required
                                            readOnly
                                            disabled="disabled"
                                        />
                                    </div>
                                    <hr />
                                    <div className="uk-form-label"><b>{t("For this Assessment - provide an assessment name and contact person details")}</b></div>

                                    <div className="uk-margin"></div>

                                    <div className="uk-form-label">{t("Assessment name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="assessmentName"
                                            id="assessmentName"
                                            value={this.state.assessmentName}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Assessment name")}
                                            type="text"
                                            required
                                            minLength="2"
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Full name")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="contactPersonFullName"
                                            id="contactPersonFullName"
                                            value={this.state.contactPersonFullName}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Full name")}
                                            type="text"
                                            required
                                            minLength="2"
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Official Email Address")}<i style={styles.icon} className="icon red">*</i></div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="contactPersonEmail"
                                            id="contactPersonEmail"
                                            value={this.state.contactPersonEmail}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Official Email Address")}
                                            type="email"
                                            required
                                        />
                                    </div>

                                    <div className="uk-form-label">{t("Skype ID")}</div>
                                    <div>
                                        <span className="uk-form-icon">
                                            <i className="far fa-User icon-medium"></i>
                                        </span>
                                        <input
                                            onChange={this._handleChange}
                                            name="contactPersonSkype"
                                            id="contactPersonSkype"
                                            value={this.state.contactPersonSkype}
                                            className="uk-input uk-form-width-large"
                                            placeholder={t("Skype ID")}
                                            type="text"
                                        // required
                                        />
                                    </div>

                                    <div className="uk-margin"></div>
                                    {(formErrorStatus.status) ? (
                                        localStorage.RAT_LANG == 'ar' ? (
                                            <div className="danger_alert" style={{ paddingRight: "12px" }}>{formErrorStatus.message}</div>

                                        ) : (
                                            <div className="danger_alert">{formErrorStatus.message}</div>

                                        )
                                    ) : ((formSuccessState.status) ? (
                                        localStorage.RAT_LANG == 'ar' ? (
                                            <div className="success_alert" style={{ paddingRight: "12px" }}>{formSuccessState.message}</div>

                                        ) : (
                                            <div className="success_alert">{formSuccessState.message}</div>

                                        )
                                    ) : (''))}
                                </div>
                                {/* General Details */}
                            </div>

                            <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-6 uk-margin-small quick-nav" uk-grid>
                                <div>
                                    <div className="uk-card">
                                        <li className="uk-active"><a className={localStorage.RAT_LANG == "fr" ? "fr_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1" : localStorage.RAT_LANG == 'ar' ? "Ar_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1" : localStorage.RAT_LANG == "sp" ? "uk-button uk-button-default uk-button-small uk-width-1-1 sp_MA_contact" : "uk-button uk-button-default uk-button-small uk-width-1-1"} onClick={(e) => this._pagi("section1")} href="javascript:void(0)">{t("MA Contact")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card">
                                        <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="uk-grid uk-text-center button_div">
                                <div className="uk-width-1-3@m">
                                    <Button
                                        basic
                                        className="outline-button blueButton"
                                        onClick={this._goBack}>
                                        {t("Go Back")}
                                    </Button>
                                </div>

                                <div className="uk-width-expand@m">
                                </div>

                                <div className="uk-width-1-3@m">
                                    {!this.state.sectionEmpty ? (
                                        <div>
                                            <Button
                                                type="submit"
                                                onClick={(e) => this._next(true)}
                                                // onClick={this._nextWithoutUpdate}
                                                className="next-button purpleButton"
                                                loading={this.state.loading}
                                                basic
                                            >
                                                {t("Save and Next")}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={(e) => this._next(true)}
                                                basic
                                                className="outline-button"
                                                loading={this.state.loading}
                                            >
                                                {t("Save & Continue")}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>

                {/* <Button onClick={this.show()}>Instructions</Button> */}

                {/* <Modal size={size} open={open} >
                    <Modal.Content image >
                        <Image className="uk-display-inline-block" uk-cover style={{ width: "100%" }} src={navigation_img} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={this.close}> Ok </Button>
                    </Modal.Actions>
                </Modal> */}


                {/* End Dark Header */}
            </div>
        );
    }
}

const styles = {
    stickyStyle: {
        zIndex: "980"
    },
    icon: {
        width: "0px"
    },
    navigation_flow: {
        backgroundImage: `url(${navigation_img})`,
        // backgroundImage: URL("../../../assets/images/navigation_flow.jpg")
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(NewSection1)))); 
