import React, { Component } from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import { withNamespaces } from "react-i18next";

class Glossary extends Component {
    render() {
        const { t, i18n } = this.props;
        return (
            <div className="uk-section-default uk-section">
                <div className="uk-margin-auto uk-container-small">
                </div>
                <hr />
                <Container className="containerStyle containerMoveBottom" >
                    <Segment className="HeaderBorder">
                        <Header as={localStorage.RAT_LANG == 'ar' ?'h3': 'h4'}> {t("Glossary")}</Header>
                    </Segment>
                    <div className="readinesysAss" >
                        <p>{t("Listed below in alphabetical order is a selection of words and phrases defined in the context of this assessment.")}</p>

                        <p> <strong> {t("Business Plan")}  </strong></p>

                        <p>{t("A formal document containing business goals, the methods on how these goals can be attained, and the time frame within which these goals need to be achieved. It also describes the nature of the business, background information on the organization, marketing plans, the organization's financial projections, and the strategies it intends to implement to achieve the stated targets. In its entirety, this document serves as a road map that provides direction to the business and can be used to obtain financing.")}</p>

                        <p> <strong> {t("Capacity building/development")}  </strong></p>

                        <p>{t("The process by which individuals and organizations obtain, improve, and retain the social enterprise related skills, knowledge, tools, equipment, and other resources needed to do their jobs competently. It allows individuals and organizations to perform at a greater aptitude.")}</p>

                        <p> <strong> {t("Constitution")}  </strong></p>

                        <p>{t("An aggregate of fundamental principles (or established precedents) in an organization which govern its operation. The principles or bylaws establish the specific rules of guidance by which the organization is to function.")} </p>

                        <p> <strong> {t("Credit facility")}  </strong></p>

                        <p>{t("A type of loan made in a business or organizational finance context. It allows a business to borrow money over an extended period of time rather than reapplying for a loan each time it needs money. In effect, a credit facility lets an organization to take out a secured (or at times unsecured) loan for generating capital over an extended period of time.")}</p>

                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("Revolving loan facility, overdraft, letter of credit and most retail credit accounts.")}</i></p>

                        <p> <strong> {t("Creditworthiness")}  </strong></p>

                        <p>{t("The extent to which a person or organization is considered suitable to receive financial credit, often based on their reliability in paying money back in the past.")}</p>

                        <p> <strong> {t("Data Management System (DMS)")}  </strong></p>

                        <p>{t("A software package designed to define, store, retrieve and manage data in a database. A DMS examines the data itself, the data format, field names, record structure and file structure. It also defines rules to validate and process this data.")}</p>

                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("DHIS2")}</i></p>


                        <p> <strong> {t("Domestic law")}  </strong></p>

                        <p>{t("The system of rules and legal policies which a particular country or community recognizes as regulating the actions of its members and which it may enforce by the imposition of penalties. These rules are in place to uphold peace, govern events, aid transactions and establish a degree of control.")}</p>

                        <p> <strong> {t("ERP system")}  </strong></p>
                      
                        <p> {t("ERP stands for Enterprise Resource Planning. This is ideally an automated IT application which supports the main functions of an organization to be carried out in a system-driven approach. This package of software integrates functions such as HR, Finance, Sales, Marketing, Procurement, etc.")}</p>
                        <p>{t("For the purpose of this assessment, apart from any professional software, you can also include an internally custom developed database.")}</p>
                       
                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("SAGE, SAP FICO, SAP HRM, Oracle, and QuickBooks")}</i></p>

                        <p> <strong> {t("Financial year")}  </strong></p>

                        <p>{t("The one year period for which financial statements of an organization is prepared is referred to as the financial year. A financial year varies between countries/organizations for budgetary purposes. This means that while the financial year has 12 months, it necessarily does not start on 1st of January and end on 31st December. It could in certain instances be 1st April to 31st March the next year.")}</p>
                            
                        <p> <strong> {t("Funding Sources")}  </strong></p>

                        <p>{t("Funding is the act of providing money to finance the social enterprise. In this instance, the funding sources refers to credit facilities from financial institutions, donations, grants, internal reserves/savings, subsidies or special projects.")}</p>
                            
                        <p> <strong> {t("Governing Board")}  </strong></p>
                        
                        <p>{t("The supreme governing body of an NGO. Its decisions determine the organization's direction and policies, and eventually its impact on the people it serves. A Governing Board at IPPF’s Member Associations may also be known as National Board, National Council or Governing Council.")}</p>
                        
                        <p> <strong> {t("Human Resources/HR system")}  </strong></p>
                            
                        <p>{t("An automated or manual systematic procedure of handling Human Resource related functions in an organization such as storing employee data, managing payrolls, recruitment processes, benefits administration, and keeping track of attendance records.")}</p>
                        
                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("Human Resource Information Systems (HRIS)")}</i></p>
                        
                        <p> <strong> {t("Income generating activities")}  </strong></p>

                        <p>{t("An activity where money is paid to an organization for services rendered or products provided. They are driven with a monetary value.")}</p>
                        
                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("Sale of contraceptives, sale of sanitary napkins, providing medical consultation and counselling services")}</i></p>
                        {/* style={{marginLeft:"100px"}} */}
                        <p> <strong> {t("(Local) Self-generated income")}  </strong></p>
                        {/* style={{marginLeft:"100px"}} */}
                        <p>{t("Income raised or generated by IPPF’s Member Associations. As an indication, this would cover income sources such as commodity sales, fees for services, training, education, professional services, rentals, grants and membership fees.")}</p>

                        <p> <strong> {t("Indicative metric/conversion for land measurement")}  </strong></p>

                        <p><span className={localStorage.RAT_LANG=='ar'? "glossary_padding_ar": localStorage.RAT_LANG=='sp'? "glossary_padding_sp":"glossary_padding_others" } >{t("10,000 sq. meters")} </span>{t("= 1 Hectare")}</p>

                        <p><span className={localStorage.RAT_LANG=='ar'? "glossary_padding_ar_two": localStorage.RAT_LANG=='fr'? "glossary_padding_two_fr" : localStorage.RAT_LANG=='sp'? "glossary_padding_two_sp" :"glossary_padding_others_two" } >{t("43,560 sp. Feet")} </span>{t("= 1 Acre")}</p>

                        <p><span className={localStorage.RAT_LANG=='ar'? "glossary_padding_ar_three": localStorage.RAT_LANG=='fr'? "glossary_padding_three_fr": localStorage.RAT_LANG=='sp'? "glossary_padding_three_sp" : "glossary_padding_others_three" } >{t("1 Hectare")} </span>{t("= 2.471 Acres")}</p>
                        
                        <p> <strong> {t("Market Research")}  </strong></p>

                        <p>{t("The process of gathering, analysing and interpreting information about a market for a product or service to be offered for sale in that market. It includes the analysis of  the past, present and potential customers for this product or service; research into the characteristics, spending habits, location and needs of the business's target market, the industry as a whole, and the particular competitors in the market.")}</p>
                        
                        <p> <strong> {t("Member Association (MA)")}  </strong></p>
                            
                        <p>{t("Includes IPPF’s fully accredited Member Associations, Associate Members and Collaborative Partners.")}</p>
                            
                        <p> <strong> {t("NGO")}  </strong></p>
                            
                        <p>{t("A Non-Profit Organization or NGO is an organization that operates independently of any government for a public benefit purpose.")} </p>
                        
                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("IPPF, IPPF Member Associations, Red Cross, World Wildlife Fund")}</i></p>
                            
                        <p> <strong> {t("PESTLE analysis")}  </strong></p>
                            
                        <p>{t("PESTLE analysis (Political, Economic, Social, Technological, Environmental and Legal factors) describes a framework of macro-environmental factors which affect an organization. It is part of an external analysis when conducting a strategic analysis or doing market research, and gives an overview of the different macro-environmental factors to be taken into consideration. It is a strategic tool for understanding market growth or decline, business position, potential and direction for operations.")}</p>
                        
                        <p> <strong> {t("Procurement policy")}  </strong></p>
                        
                        <p>{t("Provides guidelines for purchasing in an organization. A procurement policy helps to ensure that an organization can buy efficiently and obtain value for money from its suppliers by clarifying roles, responsibilities, processes, etc.")}</p>
                        
                        <p> <strong> {t("Service statistics")}  </strong></p>

                        <p>{t("A mechanism that the MAs follow to report the number of SRHR services, first time users of contraception, distribution of SRH services, etc. for each calendar year. These are utilised to assess trends in service provision relative to previous years in order to address needs, strengths and areas of improvement for each of the MAs and across IPPF’s programmatic focus areas.")}</p>
                            
                        <p> <strong> {t("Social Enterprises – IPPF")}  </strong></p>
                            
                        <p>{t("IPPF recognizes social enterprise as the use of entrepreneurial methods to generate a surplus income, which is used to finance the social development, service delivery and humanitarian activities that enable the organization to fulfil its social mission.")}</p>
                        
                        <p style={{ color: "orange" }}><i>{t("Examples of social enterprises at IPPF")}: {t("Sale of contraceptives, Pharmacies, Laboratories, Restaurants, Printing press, Learner institutes, Boarding House, etc.")}</i></p>

                        <p> <strong> {t("Specialist staff")}  </strong></p>

                        <p>{t("A person who has a specialized skill or knowledge about a particular subject. In this instance the reference is to one or more persons who has experience and knowledge in business management, marketing, sales or entrepreneurial activities.")}</p>
                        
                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("Business manager, Marketing manager, Sales officer")}</i></p>

                        <p> <strong> {t("Staff or employee turnover")}  </strong></p>

                        <p>{t("Refers to the number or percentage of workers who leave an organization. The staff turnover rate is calculated (as a %) by dividing the number of employees who left the company by the average number of employees in a certain period in time. This number is then multiplied by 100 to get a percentage.")}</p>
                        
                        <p> <strong> {t("Start-up")}  </strong></p>

                        <p>{t("An initial stage of a business venture to develop a unique product or service and bring it to the market.")} </p>
                        
                        {/* <p style={{ color: "orange" }}><i>Examples of social enterprises at IPPF: Sale of contraceptives, Pharmacies, Laboratories, Restaurants, Printing press, Learning institutes, Boarding House, etc.</i></p> */}
                        
                        <p> <strong> {t("SWOT analysis")}  </strong></p>

                        <p>{t("Strengths, Weaknesses, Opportunities and Threats analysis is used to analyze the internal context (strengths and weaknesses) and the external environment (opportunities and threats) as relevant to an organization. It is then repeated at a future time, both to analyse how well it has capitalised on the strengths, dealt with the weaknesses, exploited the opportunities and minimised the threats and what the strengths, weaknesses, opportunities and threats are at that time.")}</p>
                        
                        <p> <strong> {t("Value")}   </strong></p>
                        
                        <p>{t("In this instance, refers to the current market value of the asset in question.")} </p>

                        <p style={{ color: "orange" }}><i>{t("Eg")}: {t("A value of a vehicle would depreciate (decrease) with time, while the value of land would most likely appreciate (increase).")}</i></p>

                        <p> <strong> {t("Venture Capital")}  </strong></p>
                        
                        <p>{t("A type of financing that is provided by firms or funds to small, early-stage, emerging businesses that are deemed to have growth potential.")}</p>
                        
                        <p> <strong> {t("Working Capital")}  </strong></p>
                        
                        <p>{t("The funds available to meet the current and short-term obligations of the organization. Put simply, it is the money at hand to run the social enterprise/business for the next 6 - 12 months.")}</p>
                            
                   </div>
                </Container>
            </div>
        )
    }
}

export default withNamespaces()(Glossary); 
