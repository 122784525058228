import React, { Component } from 'react';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import userImage from '../../../assets/images/avatures/avature.jpg';
import { logoutUser } from '../../../services/CacheOperations';
import { GET_MA_PROFILE_LIST } from "../../../queries/UserQueries";
import { GET_SUBMISSION_LIST, GET_SUBMISSIONS } from "../../../queries/SubmissionQueries";
import { Dropdown } from 'semantic-ui-react';
import { LANGUAGE_OPTIONS } from "../../../constants/index";
import { withNamespaces, Trans } from "react-i18next";
import i18n from '../../../i18n';
class DashboardMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingMA: true,
            loadingSubmissions: true,
            userStatus: "",
            users: [],
            submissions: [],
            activeMA: 0,
            pendingMA: 0,
            completedSubmissions: 0
        };
        this._getUserList();
        this._getSubmissionList();
    }

    logout() {
        logoutUser();
        window.location.href = '/';
    }

    _getUserList = async () => {
        await this.props.client.query({
            query: GET_MA_PROFILE_LIST
        }).then(result => {
            const MAList = result.data.getMAUserList;
            let res = MAList.filter(it => it.user.isActive === true);
            this.setState({
                loadingMA: false,
                activeMA: res.length,
                pendingMA: MAList.length - res.length
            });
        }).catch(error => {
            console.log(error);
            this.setState({ loadingMA: false });
        });
    };

    _getSubmissionList = async () => {
        await this.props.client.query({
            query: GET_SUBMISSIONS
        }).then(result => {
            const submissionList = result.data.getSubmissions;
            // const completedSubmissions = Object.entries(submissionList).map(([key, value]) => value.length === 5);
            this.setState({
                loadingSubmissions: false,
                completedSubmissions: submissionList.length
                // completedSubmissions: completedSubmissions.filter(x => x).length
            });
        }).catch(error => {
            console.log(error);
            this.setState({ loadingSubmissions: false });
        });
    };
    // changeLanguage = async (e, { value }, data) => {
    //     localStorage.RAT_LANG = value;
    //     this.setState(prevState => ({ lang_value: value }));
    //     i18n.changeLanguage(value);
    //     // window.location.reload();
    // }

    render() {
        const { t, i18n } = this.props;
        const { loadingMA, loadingSubmissions, activeMA, pendingMA, completedSubmissions } = this.state;
        console.log(localStorage.RAT_LANG)
        return (
            <div class="admin-content">
                {/* Begin Navigation */}
                <nav class="uk-navbar">
                    <div class="uk-navbar-left">
                        <ul class="uk-navbar-nav">
                            <li class="uk-active">
                                <a href="/" style={{ fontSize: "22px" }}>{t("Dashboard")}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="uk-navbar-right">
                        {/* <ul className="uk-navbar-nav toolbar-nav">
                            <li>
                                <a className="remove-affter"><Dropdown options={LANGUAGE_OPTIONS} value={this.props.i18n.language === "en-US" ? "en" : this.props.i18n.language} onChange={this.changeLanguage} /></a>
                            </li>
                        </ul> */}
                        <a href="#">
                            <i className="fas fa-user icon-large uk-border-circle" ></i>
                        </a>
                        <div uk-dropdown="pos: top-right ;mode : click ;animation: uk-animation-slide-bottom-small" class="uk-dropdown uk-padding-small angle-top-right uk-dropdown-bottom-right">
                            <p class="uk-margin-remove-bottom uk-margin-small-top uk-text-bold">
                                {/* Hamse Mohamoud */}
                            </p>
                            <ul class="uk-nav uk-dropdown-nav">
                                <li>
                                    <a onClick={() => this.logout()}>
                                        <i class="fas fa-sign-out-alt uk-margin-small-right"></i>
                                        {t("Log out")}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div class="admin-content-inner" style={{ paddingTop: 0 }}>

                    <div class="uk-grid uk-child-width-1-3@m uk-child-width-1-3 uk-grid-match " uk-grid>
                        <div>
                            <div class="uk-background-cover uk-light dashboard-card" style={{ backgroundColor: "#105583c9", borderRadius: "5px" }}>
                                <i class="fas fa-users icon-xxlarge uk-visible@m circle-icon"></i>
                                <h3 style={{color:"#73d7ff"}}> {loadingMA ? t("Loading") : (
                                    activeMA + " " + t("Members")
                                )}  </h3>
                                <p style={{ fontSize: "18px", color: "#fff" }}> {t("Active")}</p>
                                <a className="uk-button uk-button-default" style={{color:"#ffe0bf"}} href="/users?status=true"> {t("View All")} </a>
                            </div>
                        </div>
                        <div>
                            <div class="uk-background-cover uk-light dashboard-card" style={{ backgroundColor: "#e2ad1cbd", borderRadius: "5px" }}>
                                <i class="fas fa-users icon-xxlarge uk-visible@m circle-icon"></i>
                                <h3 style={{color:"#b55d00"}}> {loadingMA ? t("Loading") : (
                                    pendingMA + " " + t("Members")
                                )}  </h3>
                                <p style={{ fontSize: "18px", color: "#fff" }}> {t("Pending")}</p>
                                <a className="uk-button uk-button-default" style={{color:"#125450"}} href="/users?status=false"> {t("View All")} </a>
                            </div>
                        </div>
                        <div>
                            <div class="uk-background-cover uk-light dashboard-card" style={{ backgroundColor: "#3daed4bd", borderRadius: "5px" }}>
                                <i class="far fa-paper-plane icon-xxlarge uk-visible@m circle-icon"></i>
                                <h3 style={{color:"#266c9e"}}> {loadingSubmissions ? t("Loading") : (
                                    completedSubmissions + " " + t("Submissions")
                                )} </h3>
                                <p style={{ fontSize: "18px", color: "#fff" }}>  {t("All records")} </p>
                                <a className="uk-button uk-button-default" style={{color:"#173f5f"}} class="uk-button uk-button-default" href="/submissions"> {t("View All")} </a>
                            </div>
                        </div>

                    </div>
                </div>
                {/* End Navigation */}
            </div>
        )
    }
}
export default withRouter(withApollo(withNamespaces()(DashboardMain)));