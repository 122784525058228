import React, { Component } from "react";
import SectionLoader from "../../../../generic/assesment/SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
    Form,
    Button,
    TextArea,
    Input,
    Label,
    Loader,
    Message,
    Icon,
    Popup,
    Grid,
    Header,
    Dropdown,
} from "semantic-ui-react";
import { GET_SECTION_IV } from "../../../../../queries/SectionQueries";
import {
    GET_SECTION_1,
    SUBMIT_SECTION_1,
    UPDATE_SECTION_1,
    GET_EDIT_SECTION_SUBMISSION,
    UPDATE_USER_SECTION_1
} from "../../../../../queries/SubmissionQueries";

import { USER_NAME } from "../../../../../constants/index";

import { uploadFile, deleteFile } from "../../../../../firebase/FileServices";
import { Promise } from "q";
import { setFormStatus, setSuccessStatus } from '../../../../../actions/user-actions';
import { connect } from 'react-redux';
import sectionFourQuestions from '../../../../questions/section4_questions.json';
import moment from "moment";
import { GENERIC_PROFILE } from "../../../../../queries/UserQueries";
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}
const trainingOptions = [
    {
        key: "Internal",
        text: "Internal",
        value: "Internal"
    },
    {
        key: "External",
        text: "External",
        value: "External"
    },
    {
        key: "Both the above",
        text: "Both the above",
        value: "Both the above"
    }
]

const systemTypeOptions = [
    { key: "Internally developed", value: "Internally developed", text: "Internally developed" },
    { key: "Readymade software​", value: "Readymade software", text: "Readymade software" }
]
class ReviewSection5 extends Component {
    constructor(props) {
        super(props);

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const proObj = this.props.location.pathname.split('/');
        this.state = {
            submissionId: this.props.location.state.submissionId,
            submissionType: this.props.location.state.submissionType,
            assessmentName: this.props.location.state.assessmentName,
            userId: proObj[2],
            loading: true,
            spinner: false,
            saveNContinue: false,
            sectionEmpty: true,
            section: {
                id: "",
                sectionNumber: "",
                title: "",
                subTitle: "",
                description: "",
                sequence: "",
                createdAt: "",
                updatedAt: ""
            },
            // question1: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization own any buildings?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, list out, including the value.",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     secondaryArray: [{ buildingName: "", value: "" }],
            //     weightFactor: "0.1",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question2: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization own any vehicles?",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, list out the types, including the value.",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     secondaryArray: [{ vehicleType: "", value: "" }],
            //     weightFactor: "0.3",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question3: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Does your organization own any land? ",
            //     primaryAnswer: false,
            //     secondaryQuestion: "If Yes, list out, including the value.",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     secondaryArray: [{ landLocation: "", landSize: "", value: "" }],
            //     weightFactor: "0.1",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question4: {
            //     showFollowupQuestion: true,
            //     primaryQuestion: "What other assets, facilities and equipment does your organization own?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     q4secondaryAnswer: "",
            //     uploads: [],
            //     fileUrl: null,
            //     fileRef: null,
            //     tempFile: "",
            //     weightFactor: "-0.1",
            //     isUploading: false,
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question5: {
            //     showFollowupQuestion: false,
            //     primaryQuestion:
            //         "Can your organization mortgage any of the properties mentioned above to obtain a credit facility?",
            //     primaryAnswer: false,
            //     secondaryQuestion:
            //         "If Yes, which properties can be mortgaged.",
            //     secondaryAnswer: "",
            //     weightFactor: "0.3",
            //     uploads: [],
            //     validated: true,
            //     feedBack: "",
            //     score: "",
            //     isScore: false
            // },
            // question6: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "Where does your organization conduct its training activities?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     secondaryJson: {},
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question7: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What data management system(s) does your organization use to collate service statistics?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question8: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "How does your organization use the data collated from the data management system?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            // question9: {
            //     showFollowupQuestion: true,
            //     primaryQuestion:
            //         "What enterprise resource planning (ERP) systems does your organization use for Finance, HR, other supporting functions, etc.?",
            //     primaryAnswer: "",
            //     secondaryQuestion: "",
            //     secondaryAnswer: "",
            //     primaryArray: [{ systemOrSoftwareName: "", department: "" }],
            //     primaryJson: {},
            //     weightFactor: "-0.1",
            //     uploads: [],
            //     validated: false,
            //     feedBack: "",
            //     score: "",
            //     isScore: true
            // },
            ...sectionFourQuestions,
            sectionFeedBack: "",
            user: {
                firstName: "...",
                lastName: "...",
                email: "...",
                id: "...",
                createdAt: "...",
            },
            general: {
                fullName: "...",
                country: "...",
                region: "...",
                year: "...",
            },
        };
        console.log(sectionFourQuestions)
        props.setFormStatus({ status: false, title: "", message: "" });
        props.setSuccessStatus({ status: false, title: "", message: "" });
        this._getSection3();
        this._getSection3Submissions();

        this._question1Onchange = this._question1Onchange.bind(this);
        this._question2Onchange = this._question2Onchange.bind(this);
        this._question3Onchange = this._question3Onchange.bind(this);
        this._getGenericProfile();
    }
    _getGenericProfile = () => {
        const { userId } = this.state;
        this.props.client.query({
            query: GENERIC_PROFILE,
            variables: { userId: userId }
        }).then(result => {
            console.log(result)
            this.setState({
                user: { ...result.data.getUserFullProfile.user },
                general: { ...result.data.getUserFullProfile.general },
                loading: false,
            })
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 2000);
    }
    _goBack = () => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/review/section4'), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    };

    _next = () => {
        this.setState({
            saveNContinue: true
        });
        this._submitSection(true);
    };

    _updateSection = () => {
        this.setState({
            spinner: true
        });
        this._submitSection(false);
    };

    _submitSection = (flag) => {

        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const submission = {
            question1: this.state.question1,
            question2: this.state.question2,
            question3: this.state.question3,
            question4: this.state.question4,
            question5: this.state.question5,
            question6: this.state.question6,
            question7: this.state.question7,
            question8: this.state.question8,
            question9: this.state.question9,
            sectionFeedBack: this.state.sectionFeedBack
        };
        this._saveSection(submission, flag);
    };

    _saveSection = (submission, flag) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.client.mutate({
            mutation: UPDATE_USER_SECTION_1,
            variables: {
                submission: submission,
                sectionId: "ck1gmw55c1ko10b88d4v0x3yi",
                submissionId: submissionId,
                submissionType: submissionType,
                userId: userId,
                actionType: flag
            }
        }).then(result => {
            this._getSection3Submissions();
            this.setState({
                spinner: false,
                saveNContinue: false,
            });
            this.props.setSuccessStatus({
                status: true,
                title: "",
                message: "Saved successfully!"
            });
            if (flag) {
                this.props.history.push({ pathname: '/users/'.concat(userId, '/review/section6'), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
            }

        }).catch(error => {
            this.setState({
                spinner: false,
                saveNContinue: false,
            });

            this.props.setFormStatus({
                status: true,
                title: "Oops!",
                message:
                    "There was an error while trying to update data"

            });
        });
    };

    _getSection3 = () => {
        this.props.client
            .query({
                query: GET_SECTION_IV
            })
            .then(result => {
                this.setState({
                    section: { ...result.data.getSection1 }
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    _getSection3Submissions = () => {
        const { submissionId, submissionType } = this.state;
        this.props.client
            .query({
                query: GET_EDIT_SECTION_SUBMISSION,
                variables: { sectionId: "ck1gmw55c1ko10b88d4v0x3yi", submissionId: submissionId },
                fetchPolicy: 'network-only'
            })
            .then(result => {
                console.log(result);
                if (result.data.getEditSectionSubmission === null || result.data.getEditSectionSubmission.length === 0) {
                    console.log('there is nothing here');

                } else {
                    // Apply values 
                    this.setState({
                        question1: result.data.getEditSectionSubmission.submission.question1
                            ? { ...result.data.getEditSectionSubmission.submission.question1, weightFactor: this.state.question1.weightFactor }
                            : this.state.question1,
                        question2: result.data.getEditSectionSubmission.submission.question2
                            ? { ...result.data.getEditSectionSubmission.submission.question2, weightFactor: this.state.question2.weightFactor }
                            : this.state.question2,
                        question3: result.data.getEditSectionSubmission.submission.question3
                            ? { ...result.data.getEditSectionSubmission.submission.question3, weightFactor: this.state.question3.weightFactor }
                            : this.state.question3,
                        question4: result.data.getEditSectionSubmission.submission.question4
                            ? { ...result.data.getEditSectionSubmission.submission.question4, weightFactor: this.state.question4.weightFactor }
                            : this.state.question4,
                        question5: result.data.getEditSectionSubmission.submission.question5
                            ? { ...result.data.getEditSectionSubmission.submission.question5, weightFactor: this.state.question5.weightFactor }
                            : this.state.question5,
                        question6: result.data.getEditSectionSubmission.submission.question6
                            ? { ...result.data.getEditSectionSubmission.submission.question6, weightFactor: this.state.question6.weightFactor }
                            : this.state.question6,
                        question7: result.data.getEditSectionSubmission.submission.question7
                            ? { ...result.data.getEditSectionSubmission.submission.question7, weightFactor: this.state.question7.weightFactor }
                            : this.state.question7,
                        question8: result.data.getEditSectionSubmission.submission.question8
                            ? { ...result.data.getEditSectionSubmission.submission.question8, weightFactor: this.state.question8.weightFactor }
                            : this.state.question8,
                        question9: result.data.getEditSectionSubmission.submission.question9
                            ? { ...result.data.getEditSectionSubmission.submission.question9, weightFactor: this.state.question9.weightFactor }
                            : this.state.question9,
                        sectionFeedBack: result.data.getEditSectionSubmission.submission.sectionFeedBack ? result.data.getEditSectionSubmission.submission.sectionFeedBack : this.state.sectionFeedBack,
                        sectionEmpty: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    // Question1 Stuff
    _question1Followup = state => {
        let fields = this.state.question1.secondaryArray;
        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        state
            ? this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: emptyStrings.length === 0 ? true : false
                }
            })
            : this.setState({
                question1: {
                    ...this.state.question1,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question1Onchange = event => {
        this.setState({
            question1: {
                ...this.state.question1,
                secondaryJson: {
                    ...this.state.question1.secondaryJson,
                    [event.target.id]: event.target.value
                }
            }
        });
    };

    // Question 2 stuff
    _question2Followup = state => {
        let fields = this.state.question2.secondaryArray;
        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
        state
            ? this.setState({
                question2: {
                    ...this.state.question2,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: emptyStrings.length === 0 ? true : false
                }
            })
            : this.setState({
                question2: {
                    ...this.state.question2,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question2Onchange = event => {
        this.setState({
            question2: {
                ...this.state.question2,
                secondaryJson: {
                    ...this.state.question2.secondaryJson,
                    [event.target.id]: event.target.value
                }
            }
        });
    };

    // Question 3 stuff
    _question3Followup = state => {
        let fields = this.state.question3.secondaryArray;
        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);
        state
            ? this.setState({
                question3: {
                    ...this.state.question3,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: emptyStrings.length === 0 ? true : false
                }
            })
            : this.setState({
                question3: {
                    ...this.state.question3,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question3Onchange = event => {
        this.setState({
            question3: {
                ...this.state.question3,
                secondaryJson: {
                    ...this.state.question3.secondaryJson,
                    [event.target.id]: event.target.value
                }
            }
        });
    };

    //Question 4 stuff
    _question4Followup = state => {
        state
            ? this.setState({
                question4: {
                    ...this.state.question4,
                    showFollowupQuestion: true,
                    primaryAnswer: true
                }
            })
            : this.setState({
                question4: {
                    ...this.state.question4,
                    showFollowupQuestion: false,
                    primaryAnswer: false
                }
            });
    };

    _question4Onchange = event => {
        this.setState({
            question4: {
                ...this.state.question4,
                [event.target.id]: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 5 stuff
    _question5Followup = state => {
        state
            ? this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: true,
                    primaryAnswer: true,
                    validated: this.state.question5.secondaryAnswer.trim() === "" ? false : true
                }
            })
            : this.setState({
                question5: {
                    ...this.state.question5,
                    showFollowupQuestion: false,
                    primaryAnswer: false,
                    validated: true
                }
            });
    };

    _question5Onchange = event => {
        this.setState({
            question5: {
                ...this.state.question5,
                [event.target.id]: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    _question6Onchange = event => {
        this.setState({
            question6: {
                ...this.state.question6,
                [event.target.id]: event.target.value
            }
        });
    };

    //Question 7 Stuff
    _question7Onchange = event => {
        this.setState({
            question7: {
                ...this.state.question7,
                [event.target.id]: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 8 stuff
    _question8Onchange = event => {
        this.setState({
            question8: {
                ...this.state.question8,
                [event.target.id]: event.target.value,
                validated: event.target.value.trim() === "" ? false : true
            }
        });
    };

    // Question 9 stuff
    _question9Followup = state => {
        state
            ? this.setState({
                question9: {
                    ...this.state.question9,
                    showFollowupQuestion: false,
                    primaryAnswer: true
                }
            })
            : this.setState({
                question9: {
                    ...this.state.question9,
                    showFollowupQuestion: false,
                    primaryAnswer: false
                }
            });
    };

    // _question9Onchange = event => {
    //   this.setState({
    //     question9: {
    //       ...this.state.question9,
    //       [event.target.id]: event.target.value
    //     }
    //   });
    // };

    _question9Onchange = event => {
        this.setState({
            question9: {
                ...this.state.question9,
                primaryJson: {
                    ...this.state.question9.primaryJson,
                    [event.target.id]: event.target.value
                }
            }
        });
    };

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    _handleMultiChange31(i, e) {
        if (e.target.value < 0) {
            e.preventDefault();
        } else {
            const { name, value } = e.target;
            let fields = this.state.question1.secondaryArray;
            fields[i][name] = value;

            const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
            const singleArr = [].concat(...toArray);
            const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

            this.setState({
                question1: {
                    ...this.state.question1,
                    validated: emptyStrings.length === 0 ? true : false
                }
            });

            this.setState({ fields });
        }

    }

    _handleMultiChange32(i, e) {
        if (e.target.value < 0) {
            e.preventDefault();
        } else {
            const { name, value } = e.target;
            let fields = this.state.question2.secondaryArray;
            fields[i][name] = value;

            const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
            const singleArr = [].concat(...toArray);
            const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

            this.setState({
                question2: {
                    ...this.state.question2,
                    validated: emptyStrings.length === 0 ? true : false
                }
            });
            this.setState({ fields });
        }
    }

    _handleMultiChange33(i, e) {
        if (e.target.value < 0) {
            e.preventDefault();
        } else {
            const { name, value } = e.target;
            let fields = this.state.question3.secondaryArray;
            fields[i][name] = value;

            const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
            const singleArr = [].concat(...toArray);
            const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

            this.setState({
                question3: {
                    ...this.state.question3,
                    validated: emptyStrings.length === 0 ? true : false
                }
            });
            this.setState({ fields });
        }
    }

    _handleMultiChange39(i, e) {
        const { name, value } = e.target;
        let fields = this.state.question9.primaryArray;
        fields[i][name] = value;
        const toArray = fields.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question9: {
                ...this.state.question9,
                validated: emptyStrings.length === 0 ? true : false
            }
        });
        this.setState({ fields });
    }

    _addMoreClick31() {
        let fields = this.state.question1.secondaryArray;
        fields.push({ buildingName: "", value: "" });
        this.setState({
            question1: {
                ...this.state.question1,
                validated: false
            }
        });
        this.setState(fields)
    }

    _addMoreClick32() {
        let fields = this.state.question2.secondaryArray;
        fields.push({ vehicleType: "", value: "" });
        this.setState({
            question2: {
                ...this.state.question2,
                validated: false
            }
        });
        this.setState(fields)
    }

    _addMoreClick33() {
        let fields = this.state.question3.secondaryArray;
        fields.push({ landLocation: "", landSize: "", value: "" });
        this.setState({
            question3: {
                ...this.state.question3,
                validated: false
            }
        });
        this.setState(fields)
    }

    _addMoreClick39() {
        let fields = this.state.question9.primaryArray;
        fields.push({ systemOrSoftwareName: "", department: "" });
        this.setState({
            question9: {
                ...this.state.question9,
                validated: false
            }
        });
        this.setState(fields)
    }

    _remoevMoreClick31(i) {
        let secondaryArray = this.state.question1.secondaryArray;
        secondaryArray.splice(i, 1);

        const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question1: {
                ...this.state.question1,
                validated: emptyStrings.length === 0 ? true : false
            }
        });

        this.setState({ secondaryArray });
    }

    _remoevMoreClick32(i) {
        let secondaryArray = this.state.question2.secondaryArray;
        secondaryArray.splice(i, 1);

        const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question2: {
                ...this.state.question2,
                validated: emptyStrings.length === 0 ? true : false
            }
        });

        this.setState({ secondaryArray });
    }

    _remoevMoreClick33(i) {
        let secondaryArray = this.state.question3.secondaryArray;
        secondaryArray.splice(i, 1);

        const toArray = secondaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question3: {
                ...this.state.question3,
                validated: emptyStrings.length === 0 ? true : false
            }
        });

        this.setState({ secondaryArray });
    }

    _remoevMoreClick39(i) {
        let primaryArray = this.state.question9.primaryArray;
        primaryArray.splice(i, 1);

        const toArray = primaryArray.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y.trim())], []);
        const singleArr = [].concat(...toArray);
        const emptyStrings = singleArr.filter(str => str.trim().length <= 0);

        this.setState({
            question9: {
                ...this.state.question9,
                validated: emptyStrings.length === 0 ? true : false
            }
        });

        this.setState({ primaryArray });
    }

    _createMulipleQ31() {
        const { t, i18n } = this.props
        if (this.state.question1.secondaryArray != undefined) {
            return this.state.question1.secondaryArray.map((el, i) => (
                <div key={i}>
                    <br />

                    <div className="uk-grid">
                        <div className="uk-width-1-2">
                            <label>{t("Building Name")}</label> <br />
                            {/* <input
                                id="buildingName"
                                type="text"
                                name="buildingName"
                                placeholder={t("Building Name")}
                                value={el.buildingName || ''}
                                onChange={this._handleMultiChange31.bind(this, i)}
                                disabled
                            /> */}
                            <TextArea
                                onChange={this._handleMultiChange31.bind(this, i)}
                                name="buildingName"
                                id="buildingName"
                                value={el.buildingName || ''}
                                placeholder={t("Building Name")}
                                type="text"
                                readOnly
                                rows={1}
                            />

                        </div>

                        <div className="uk-width-1-2">
                            <label>{t("Value")}</label> <br />
                            <Input
                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                type="number"
                                placeholder={t("Value")}
                                id="value"
                                name="value"
                                style={{ width: "60%" }}
                                value={el.value || ''}
                                onChange={this._handleMultiChange31.bind(this, i)}
                                disabled
                                onKeyDown={e => {
                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <Label basic>$</Label>
                                <input />
                                <Label>.00</Label>
                            </Input>
                        </div>

                    </div>
                    <br />

                    {this.state.question1.secondaryArray.length <= 1 ? (
                        null
                    ) : (
                        null
                        // <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                        //     <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick31.bind(this, i)}></i>                                
                        // </div>
                    )}

                    < hr className="multiple_Field_Seperator" />

                </div >

            ))
        } else {
            let fields = this.state.question1;
            fields["secondaryArray"] = [{ buildingName: "", value: "" }];
            this.setState(fields)
        }
    }

    _createMulipleQ32() {
        const { t, i18n } = this.props
        if (this.state.question2.secondaryArray != undefined) {
            return this.state.question2.secondaryArray.map((el, i) => (
                <div key={i}>
                    <br />

                    <div className="uk-grid">
                        <div className="uk-width-1-2">
                            <label>{t("Vehicle Type")}</label>
                            {/* <input
                                value={el.vehicleType || ''}
                                onChange={this._handleMultiChange32.bind(this, i)}
                                id="vehicleType"
                                name="vehicleType"
                                placeholder={t("Vehicle Type")}
                                disabled
                            /> */}
                            <TextArea
                                onChange={this._handleMultiChange32.bind(this, i)}
                                name="vehicleType"
                                id="vehicleType"
                                value={el.vehicleType || ''}
                                placeholder={t("Vehicle Type")}
                                type="text"
                                readOnly
                                rows={1}
                            />
                        </div>

                        <div className="uk-width-1-2">
                            <label>{t("Value")}</label> <br />
                            <Input
                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                type="number"
                                placeholder={t("Value")}
                                id="value"
                                name="value"
                                style={{ width: "60%" }}
                                value={el.value || ''}
                                onChange={this._handleMultiChange32.bind(this, i)}
                                disabled
                                onKeyDown={e => {
                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <Label basic>$</Label>
                                <input />
                                <Label>.00</Label>
                            </Input>
                        </div>

                    </div>
                    <br />

                    {this.state.question2.secondaryArray.length <= 1 ? (
                        null
                    ) : (
                        null
                        // <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                        //     <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick32.bind(this, i)}></i>                               
                        // </div>
                    )}
                    < hr className="multiple_Field_Seperator" />
                </div >

            ))
        } else {
            let fields = this.state.question2;
            fields["secondaryArray"] = [{ vehicleType: "", value: "" }];
            this.setState(fields)
        }
    }

    _createMulipleQ33() {
        const { t, i18n } = this.props
        if (this.state.question3.secondaryArray != undefined) {
            return this.state.question3.secondaryArray.map((el, i) => (
                <div key={i}>
                    <br />

                    <div className="uk-grid">
                        <div className="uk-width-1-3">
                            <label>{t("Land location (city/town)")}</label>
                            {/* <input
                                value={el.landLocation || ''}
                                onChange={this._handleMultiChange33.bind(this, i)}
                                id="landLocation"
                                name="landLocation"
                                placeholder={t("Land Location")}
                                disabled
                            /> */}
                            <TextArea
                                onChange={this._handleMultiChange33.bind(this, i)}
                                className={localStorage.RAT_LANG == 'en' ? "land_loca_other" : localStorage.RAT_LANG == 'sp' ? "land_loca_other" : localStorage.RAT_LANG == 'ar' ? "land_loca_other" : ""}
                                // style={{marginTop: "21px"}}
                                name="landLocation"
                                id="landLocation"
                                value={el.landLocation || ''}
                                placeholder={t("Land Location")}
                                type="text"
                                readOnly
                                rows={1}
                            />
                        </div>

                        <div className="uk-width-1-3">
                            <label>{t("Land size (in Square meters)")}</label>
                            <input
                                value={el.landSize || ''}
                                onChange={this._handleMultiChange33.bind(this, i)}
                                // style={{marginTop: "21px", fontSize:"14px"}}
                                className={localStorage.RAT_LANG == 'sp' ? "input_sp_placeholder" : "input__other_placeholder"}
                                id="landSize"
                                name="landSize"
                                placeholder={t("1 sq meter = 0.0001 hectare")}
                                type="number"
                                disabled
                                onKeyDown={e => {
                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>

                        <div className="uk-width-1-3">
                            <label>{t("Value")}</label> <br />
                            <Input
                                size='mini'
                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                type="number"
                                placeholder={t("Value")}
                                id="value"
                                name="value"
                                // style={{ width: "50%", marginTop: "25px" }}
                                className={localStorage.RAT_LANG == 'sp' ? "input_field_sp" : "input_field_other"}
                                value={el.value || ''}
                                onChange={this._handleMultiChange33.bind(this, i)}
                                disabled
                                onKeyDown={e => {
                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <Label basic>$</Label>
                                <input />
                                <Label>.00</Label>
                            </Input>
                        </div>

                    </div>
                    <br />

                    {this.state.question3.secondaryArray.length <= 1 ? (
                        null
                    ) : (
                        null
                        // <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                        //     <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick33.bind(this, i)}></i>                                
                        // </div>
                    )}
                    < hr className="multiple_Field_Seperator" />
                </div >

            ))
        } else {
            let fields = this.state.question3;
            fields["secondaryArray"] = [{ landLocation: "", landSize: "", value: "" }];
            this.setState(fields)
        }
    }

    _createMulipleQ39() {
        const { t, i18n } = this.props
        if (this.state.question9.primaryArray != undefined) {
            return this.state.question9.primaryArray.map((el, i) => (
                <div key={i}>
                    <br />

                    <div className="uk-grid">
                        <div className="uk-width-1-2">
                            <label>{t("System/Software name (SAGE, SAP HR, etc.)")}</label>
                            {/* <input
                                value={el.systemOrSoftwareName || ''}
                                onChange={this._handleMultiChange39.bind(this, i)}
                                id="systemOrSoftwareName"
                                name="systemOrSoftwareName"
                                placeholder={t("System/Software name (SAGE, SAP HR, etc.)")}
                                disabled
                            /> */}
                            <TextArea
                                onChange={this._handleMultiChange39.bind(this, i)}
                                name="systemOrSoftwareName"
                                id="systemOrSoftwareName"
                                value={el.systemOrSoftwareName || ''}
                                placeholder={t("System/Software name (SAGE, SAP HR, etc.)")}
                                type="text"
                                readOnly
                            />

                        </div>

                        <div className="uk-width-1-2">
                            <label>{t("Department (Finance, HR, etc.)")}</label>
                            {/* <input
                                value={el.department || ''}
                                onChange={this._handleMultiChange39.bind(this, i)}
                                id="department"
                                name="department"
                                placeholder={t("Department (Finance, HR, etc.)")}
                                disabled
                            /> */}
                            <TextArea
                                onChange={this._handleMultiChange39.bind(this, i)}
                                name="department"
                                id="department"
                                value={el.department || ''}
                                placeholder={t("Department (Finance, HR, etc.)")}
                                type="text"
                                readOnly
                            />
                        </div>

                    </div>
                    <br />

                    {this.state.question9.primaryArray.length <= 1 ? (
                        null
                    ) : (
                        null
                        // <div className="ms-Grid-col ms-sm6 ms-lg2 uk-text-right">
                        //     <i class="fas fa-trash-alt multiple_Field_Remove" aria-hidden="true" onClick={this._remoevMoreClick39.bind(this, i)}></i>                                
                        // </div>
                    )}
                    < hr className="multiple_Field_Seperator" />
                </div >

            ))
        } else {
            let fields = this.state.question9;
            fields["primaryArray"] = [{ systemOrSoftwareName: "", department: "" }];
            this.setState(fields)

        }
    }
    _question1Onchange = event => {
        this.setState({
            question1: {
                ...this.state.question1,
                [event.target.id]: event.target.value
            }
        });
    };

    _question1jsonOnchange = (event, data) => {
        this.setState({
            question6: {
                ...this.state.question6,
                [data.id]: data.value,
                validated: data.value === "" ? false : true
            }
        });
    };

    _pagi = (path) => {
        const { submissionId, submissionType, userId, assessmentName } = this.state;
        this.props.history.push({ pathname: '/users/'.concat(userId, '/review/', path), state: { submissionId: submissionId, submissionType: submissionType, assessmentName: assessmentName } });
    }

    _feedBackHandler = (event, name) => {
        this.setState({
            [name]: {
                ...this.state[name],
                feedBack: event.target.value

            }
        });
    }

    _scoreHandler = (event, name) => {
        if (event.target.value > 100 || event.target.value < 0) {
            // if (event.target.value > 10 || event.target.value < 0 || event.target.value.length > 2 || event.target.value === "00") {
            event.preventDefault();
        } else {
            this.setState({
                [name]: {
                    ...this.state[name],
                    score: event.target.value

                }
            });
        }
    }

    _sectionFeedBackHandler = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;

        return (
            <div class="remove-disabled">
                <div>
                    <div class="uk-background-muted uk-margin-top uk-padding" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <div class="uk-grid" uk-grid>
                            <div class="uk-width-expand@m">
                                <form class=" uk-flex-inline uk-flex-middle uk-text-small">
                                    <h4 class="uk-margin-remove-bottom uk-margin-right">
                                        {" "}
                                        {this.state.general.fullName}
                                    </h4>
                                </form>
                                <h6 class="uk-margin-remove-top uk-text-left">
                                    {" "}
                                    {this.state.general.country}, {this.state.general.region}
                                </h6>
                            </div>
                            <div class="uk-width-auto@m uk-text-small">
                                <p class="uk-text-right uk-margin-remove-bottom"> {t("Submitted by")}: <strong> {this.state.user.firstName} {this.state.user.lastName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom"> {t("Assessment name")}: <strong>{this.state.assessmentName} </strong></p>
                                <p class="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">{t("Account created on")}: {moment(this.state.user.createdAt).format('DD-MMMM-YYYY')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "20px" }}>
                        <div class="uk-width-auto@m uk-text-small">

                        </div>
                    </div> */}
                </div>
                {/* <div class="uk-background-muted" style={{ paddingRight: "40px", paddingBottom: "15px" }}>
                    <div class="uk-width-auto@m uk-text-small">
                        <p class="uk-text-right uk-margin-remove-top" style={{ color: result_color }}><b>{result_status}: {score}</b></p>

                    </div>
                </div> */}
                {this.state.loading ? (
                    <SectionLoader />
                ) : (
                    <div>
                        {/* Begin Dark Header */}
                        <div className="uk-section-default uk-section" style={{ paddingTop: "10px" }}>
                            <div className="uk-container-small uk-margin-auto" style={{ marginTop: "10px" }}>
                                <h3 className=" uk-text-light">
                                    {" "}
                                    {t("Section")} {this.state.section.sectionNumber}{" "}
                                    {/* {this.state.section.title}{" "} */}
                                </h3>
                            </div>
                            {/* <hr /> */}

                            <div
                                className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                                uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                            >
                                <div className="paper uk-link-reset " style={{ backgroundColor: "#151F6D" }}>
                                    <div className="uk-grid uk-flex-middle">
                                        <div className="uk-width-1-6 uk-flex-first uk-text-center">
                                            <i className="far fa-comment-alt icon-xxlarge"></i>
                                        </div>
                                        <div className="uk-width-5-6">
                                            {localStorage.RAT_LANG == 'ar' ? (
                                                <h3 className="uk-margin-remove" style={{ color: "#ffffff", fontSize: "1.4rem" }}>
                                                    {" "}
                                                    {t(this.state.section.title)}{" "}
                                                </h3>
                                            ) : (
                                                <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                                                    {" "}
                                                    {t(this.state.section.title)}{" "}
                                                </h4>
                                            )
                                            }

                                            {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                                        </div>
                                    </div>
                                </div>
                                <Form success error className="uk-margin">
                                    {/* Begin Question 1 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>27)</b>{" "}
                                            {t("Does your organization own any buildings?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <Button.Group size="large" className="buttons_arabic_style" >
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                        ) : (
                                            <Button.Group size="large" >
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? true : false} onClick={() => this._question1Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question1.showFollowupQuestion ? false : true} onClick={() => this._question1Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                        )}

                                        {this.state.question1.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, list out, including the value.")}
                                                </div>
                                                <div >

                                                    {this._createMulipleQ31()}
                                                    <div className="uk-text-right"><button disabled onClick={this._addMoreClick31.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">{t("Add One More")}</button></div>

                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question1")}
                                                name="question1feedBack"
                                                id="question1feedBack"
                                                value={this.state.question1.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 1 */}


                                    {/* Begin Question2 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>28)</b>{" "}
                                            {t("Does your organization own any vehicles?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <Button.Group size="large" className="buttons_arabic_style" >

                                                <Button disabled positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                        ) : (
                                            <Button.Group size="large">
                                                <Button disabled positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                        )}

                                        {this.state.question2.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, list out the types, including the value.")}
                                                </div>
                                                <div>
                                                    {this._createMulipleQ32()}
                                                    <div className="uk-text-right"><button disabled onClick={this._addMoreClick32.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">{t("Add One More")}</button></div>
                                                </div>

                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question2")}
                                                name="question2feedBack"
                                                id="question2feedBack"
                                                value={this.state.question2.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 2 */}

                                    {/* Begin Question 3 */}

                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>29)</b>{" "}
                                            {t("Does your organization own any land?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <Button.Group size="large" className="buttons_arabic_style" >
                                                <Button disabled positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                        ) : (
                                            <Button.Group size="large" >
                                                <Button disabled positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                        )}

                                        {this.state.question3.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, list out, including the value.")}
                                                </div>
                                                <div>
                                                    {this._createMulipleQ33()}
                                                    <div className="uk-text-right"><button disabled onClick={this._addMoreClick33.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">{t("Add One More")}</button></div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question3")}
                                                name="question3feedBack"
                                                id="question3feedBack"
                                                value={this.state.question3.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    {/* End Question 3 */}

                                    {/* Begin Question 4 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4 style={{ fontSize: "1.20rem" }}><b>30)</b> {t("What other assets, facilities and equipment does your organization own?")} </h4>
                                        <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and provide details.")}</p>

                                        <TextArea
                                            onChange={this._question4Onchange}
                                            name="primaryAnswer"
                                            id="primaryAnswer"
                                            value={this.state.question4.primaryAnswer}
                                            className="text-area-height uk-input uk-form-width-large"
                                            placeholder={t("Describe here")}
                                            type="text"
                                            disabled
                                        />
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question4score"
                                                    name="question4score"
                                                    value={this.state.question4.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question4")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question4score"
                                                name="question4score"
                                                value={this.isEmpty(this.state.question4.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question4.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question4")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question4")}
                                                name="question4feedBack"
                                                id="question4feedBack"
                                                value={this.state.question4.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>

                                    </div>
                                    {/* End Question 4 */}

                                    {/* Begin Question 5 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>31)</b>{" "}
                                            {t("Can your organization mortgage any of the properties mentioned above to obtain a credit facility?")}{" "}
                                        </h4>
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <Button.Group size="large" className="buttons_arabic_style">
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                            </Button.Group>
                                        ) : (
                                            <Button.Group size="large" >
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? true : false} onClick={() => this._question5Followup(true)}>
                                                    {t("YES")}
                                                </Button>
                                                {/* <Button.Or /> */}
                                                <div class="or" data-text={localStorage.RAT_LANG == 'sp' ? "o" : localStorage.RAT_LANG == 'fr' ? "ou" : localStorage.RAT_LANG == 'ar' ? "أو" : "or"}></div>
                                                <Button disabled positive={this.state.question5.showFollowupQuestion ? false : true} onClick={() => this._question5Followup(false)}>
                                                    {t("NO")}
                                                </Button>
                                            </Button.Group>
                                        )}

                                        {this.state.question5.showFollowupQuestion ? (
                                            <div>
                                                <div className="uk-form-label">
                                                    {t("If Yes, which properties can be mortgaged.")}
                                                </div>
                                                <div className="uk-margin">
                                                    <span className="uk-form-icon">
                                                        <i className="far fa-User icon-medium"></i>
                                                    </span>

                                                    <TextArea
                                                        onChange={this._question5Onchange}
                                                        name="secondaryAnswer"
                                                        id="secondaryAnswer"
                                                        value={this.state.question5.secondaryAnswer}
                                                        className="text-area-height uk-input uk-form-width-large"
                                                        placeholder={t("Describe here")}
                                                        type="text"
                                                        disabled
                                                    />

                                                </div>
                                            </div>
                                        ) : (
                                            null
                                        )}
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question5")}
                                                name="question5feedBack"
                                                id="question5feedBack"
                                                value={this.state.question5.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 5 */}

                                    {/* Begin Question 6 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>32)</b>{" "}
                                            {t("Where does your organization conduct its training activities?")}{" "}
                                        </h4>
                                        {/* <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">Please answer and support the response with additional details.</p> */}

                                        <div className="uk-form-label">
                                            {t("Training Activities")}{"  :  "}
                                            {" "}
                                            <Dropdown
                                                disabled
                                                style={{ marginLeft: "10px" }}
                                                onChange={this._question1jsonOnchange}
                                                name="primaryAnswer"
                                                id="primaryAnswer"
                                                selection
                                                value={this.isEmpty(this.state.question6.primaryAnswer) ? (
                                                    null
                                                ) : (
                                                    this.state.question6.primaryAnswer
                                                )}
                                                placeholder={t("Select location")}
                                                options={[
                                                    {
                                                        key: "Internal",
                                                        text: t("Internal"),
                                                        // value: "Internal"
                                                        value: t("Internal")
                                                    },
                                                    {
                                                        key: "External",
                                                        text: t("External"),
                                                        value: t("External")
                                                    },
                                                    {
                                                        key: "Both the above",
                                                        text: t("Both the above"),
                                                        value: t("Both the above")
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question6score"
                                                    name="question6score"
                                                    value={this.state.question6.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question6")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question6score"
                                                name="question6score"
                                                value={this.isEmpty(this.state.question6.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question6.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question6")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question6")}
                                                name="question6feedBack"
                                                id="question6feedBack"
                                                value={this.state.question6.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>

                                    </div>


                                    {/* End Question 6 */}

                                    {/* Begin Question 7 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>33)</b>{" "}
                                            {t("What data management system(s) does your organization use to collate service statistics?")}{" "}
                                        </h4>
                                        <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    onChange={this._question7Onchange}
                                                    name="primaryAnswer"
                                                    id="primaryAnswer"
                                                    value={this.state.question7.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Describe here")}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question7score"
                                                    name="question7score"
                                                    value={this.state.question7.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question7")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}

                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question7score"
                                                name="question7score"
                                                value={this.isEmpty(this.state.question7.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question7.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question7")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question7")}
                                                name="question7feedBack"
                                                id="question7feedBack"
                                                value={this.state.question7.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 7 */}

                                    {/* Begin Question 8 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>34)</b>{" "}
                                            {t("How does your organization use the data collated from the data management system?")}{" "}
                                        </h4>
                                        <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">{t("Please answer and support the response with additional details.")}</p>
                                        <div>
                                            <div className="uk-form-label"></div>
                                            <div className="uk-margin">
                                                <span className="uk-form-icon">
                                                    <i className="far fa-User icon-medium"></i>
                                                </span>

                                                <TextArea
                                                    disabled
                                                    onChange={this._question8Onchange}
                                                    name="primaryAnswer"
                                                    id="primaryAnswer"
                                                    value={this.state.question8.primaryAnswer}
                                                    className="text-area-height uk-input uk-form-width-large"
                                                    placeholder={t("Describe here")}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question8score"
                                                    name="question8score"
                                                    value={this.state.question8.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question8")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question8score"
                                                name="question8score"
                                                value={this.isEmpty(this.state.question8.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question8.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question8")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question8")}
                                                name="question8feedBack"
                                                id="question8feedBack"
                                                value={this.state.question8.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 8 */}

                                    {/* Begin Question 9 */}
                                    <div className="uk-link-reset paper uk-margin s1q1">
                                        <h4>
                                            <b>35)</b>{" "}
                                            {t("What enterprise resource planning (ERP) systems does your organization use for Finance, HR, other supporting functions, etc.?")}{" "}
                                        </h4>
                                        <div>
                                            <div>
                                                {this._createMulipleQ39()}
                                                <div className="uk-text-right"><button disabled onClick={this._addMoreClick39.bind(this)} className="uk-button uk-button-big uk-button-primary multiple_Field_Plus" type="button">{t("Add One More")}</button></div>
                                            </div>
                                        </div>
                                        <div className="uk-form-label">{t("Score given")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            {/* <input
                                                    type="number"
                                                    placeholder="Enter score here"
                                                    id="question9score"
                                                    name="question9score"
                                                    value={this.state.question9.score || ""}
                                                    maxlength="2"
                                                    onChange={(e) => this._scoreHandler(e, "question9")}
                                                    style={{ width: "20%" }}
                                                    onKeyDown={(e) => {
                                                        if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                /> */}
                                            <Input
                                                style={{ width: "180px" }}
                                                labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                                                type="number"
                                                placeholder={t("Enter score here")}
                                                id="question9score"
                                                name="question9score"
                                                value={this.isEmpty(this.state.question9.score) ? (
                                                    null
                                                ) : (
                                                    this.state.question9.score
                                                )}
                                                onChange={(e) => this._scoreHandler(e, "question9")}
                                                onKeyDown={(e) => {
                                                    if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                                        e.preventDefault();
                                                    }
                                                }}>
                                                <input />
                                                <Label basic>%</Label>
                                            </Input>
                                        </div>
                                        <div className="uk-form-label">{t("Comments/Feedback from SE Hub")}</div>
                                        <div>
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={(e) => this._feedBackHandler(e, "question9")}
                                                name="question9feedBack"
                                                id="question9feedBack"
                                                value={this.state.question9.feedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Comments/Feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    {/* End Question 9 */}
                                    <div
                                        href="help-post-read.html"
                                        className="uk-link-reset paper uk-margin-remove-bottom"
                                    >
                                        <h4 className="uk-margin-remove">
                                            {" "}
                                            {t("Overall section comments/feedback from SE Hub")}
                                        </h4>

                                        <div className="uk-margin">
                                            <span className="uk-form-icon">
                                                <i className="far fa-User icon-medium"></i>
                                            </span>

                                            <TextArea
                                                onChange={this._sectionFeedBackHandler}
                                                name="sectionFeedBack"
                                                id="sectionFeedBack"
                                                value={this.state.sectionFeedBack || ""}
                                                className="uk-textarea"
                                                placeholder={t("Overall section comments/feedback here")}
                                                type="text"
                                            />
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        {(formErrorStatus.status) ? (
                                            localStorage.RAT_LANG == 'ar' ? (
                                                <div className="danger_alert" style={{ paddingRight: "12px" }}>{t(formErrorStatus.message)}</div>

                                            ) : (
                                                <div className="danger_alert">{t(formErrorStatus.message)}</div>

                                            )
                                        ) : ((formSuccessState.status) ? (
                                            localStorage.RAT_LANG == 'ar' ? (
                                                <div className="success_alert" style={{ paddingRight: "12px" }}>{t(formSuccessState.message)}</div>

                                            ) : (
                                                <div className="success_alert">{t(formSuccessState.message)}</div>

                                            )

                                        ) : (''))}
                                    </div>

                                    <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-5 uk-margin-small quick-nav" uk-grid>
                                        {/* <div>
                                                <div className="uk-card">
                                                    <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section1")} href="javascript:void(0)">MA Contact</a></li>
                                                </div>
                                            </div> */}
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li className="uk-active"><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-card">
                                                <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    {/* Begin Footer */}
                                    <div className="uk-grid uk-text-center button_div">
                                        <div className="uk-width-1-3@m">
                                            <Button
                                                basic
                                                className="outline-button blueButton"
                                                onClick={this._goBack}
                                            >
                                                {t("Go Back")}
                                            </Button>
                                        </div>

                                        <div className="uk-width-expand@m">
                                            {!this.state.sectionEmpty ? (
                                                <Button
                                                    basic
                                                    onClick={this._updateSection}
                                                    className="outline-button yellowButton"
                                                    loading={this.state.spinner}
                                                >
                                                    {t("Save")}
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="uk-width-1-3@m">
                                            {!this.state.sectionEmpty ? (
                                                <div>
                                                    <Button
                                                        onClick={this._next}
                                                        className="next-button purpleButton"
                                                        loading={this.state.saveNContinue}
                                                        basic
                                                    >
                                                        {t("Save and Next")}
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Button
                                                        onClick={this._next}
                                                        basic
                                                        className="outline-button"
                                                        loading={this.state.saveNContinue}
                                                    >
                                                        {t("Save & Continue")}
                                                    </Button>
                                                </div>
                                            )}

                                            {/* <div class="spinner"></div>  */}
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </Form>
                            </div>
                        </div>
                        {/* End Dark Header */}
                    </div>
                )
                }
            </div>
        );
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ReviewSection5))));
