import React, { Component, useReducer } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { setFormStatus, setSuccessStatus } from '../../../actions/user-actions';
import { withApollo } from 'react-apollo';
import { Form, Select, Dropdown, Button, Message, Modal, Image, Grid, Segment, Header, Table, Accordion, Icon, Popup, Confirm } from "semantic-ui-react";
import { ADD_SUBMISSION, GET_GENERAL_INFO, ADD_NEW_ASSESSMENT, GET_ALL_USER_ASSESSMENT, GET_ALL_EDIT_SUBMMISSIONS, DOWNLOAD_PDF, DOWNLOAD_USER_PDF, DELETE_USER_SUBMMISSION, REOPEN_SUBMMISSION } from '../../../queries/SubmissionQueries';
import { GET_MY_PROFILE } from "../../../queries/UserQueries";
import { checkReference } from '../../../firebase/FileServices';
import {
    USER_NAME
} from "../../../constants/index";
import { async } from "@firebase/util";
import moment from "moment";
import _, { result } from 'lodash'
// import navigation_img from '../../../../assets/images/navigation_flow.jpg';
// import navigation_img from '../../../assets/images/navigation_flow.jpg';
// import blank_copy from '../../../assets/pdf/blank_copy.pdf';
import { isLoggedIn } from "../../../services/CacheOperations";
import SubmissionsUserLoader from "../../../components/loaders/SubmissionsUserLoader";
import { withNamespaces } from "react-i18next";
const files = require.context('../../../assets/locales', true);

const mapStateToProps = (state, ownProps) => {
    return {
        formErrorStatus: state.custom_reducer.formErrorStatus,
        formSuccessState: state.custom_reducer.formSuccessState,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFormStatus: (status) => {
            dispatch(setFormStatus(status))
        },
        setSuccessStatus: (status) => {
            dispatch(setSuccessStatus(status))
        }
    }
}
class SectionHome extends Component {
    constructor(props) {
        super(props);
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        const query = new URLSearchParams(this.props.location.search);
        const newStatus = query.get('new');
        this.state = {
            loading: false,
            userId: localStorage.USER_ID,
            assessments: [],
            status: { DRAFT: "#DB2828", SUBMITTED: "#28DB82", REVIEWED: "#EAAA00", INACTIVE: "#686C6D", FINAL: "#1A2674" },
            column: null,
            data: [],
            direction: null,
            sections: [],
            activeIndex: 0,
            downloading: false,
            download_sub_id: "",
            open: true,
            size: "small",
            downloadingCopy: false,
            modalVisible: false,
            confirmDelete: false,
            deleteSubmissionId: "",
            deleteSubmissionType: "",
            submissionListLoading: true,
            navigation_img: ""
        };
        this._checkGeneralInfo();

        this.checkRef();
        this._show_all_submissions();
        if (newStatus == "true") {
            this._newAssessment();
        }
    }

    _show_all_submissions = () => {
        this.setState({ assessments: [], submissionListLoading: true })
        this.get_all_user_assessment().then((result) => {
            const temp_result = result.map(({ submission }) => submission)
            this.setState({ assessments: temp_result, submissionListLoading: false });
        });
    }
    setModalVisible(visible) {
        this.setState({ modalVisible: visible, navigation_img: files(`./${this.props.i18n.language === "en-US" ? "en" : this.props.i18n.language}/navigation_flow.jpg`) });
    }

    checkIfNeedOpenModal = async () => {
        try {
            const isFirstOpen = await localStorage.getItem('IS_FIRST_OPEN');
            if (!isFirstOpen || isFirstOpen !== 'true') {
                this.setModalVisible(true)
            }
        } catch (error) {
        }
    }

    saveModalOpen = async () => {
        try {
            await localStorage.setItem('IS_FIRST_OPEN', 'true');
        } catch (error) {
        }
    }
    onModalShow = () => {
        this.saveModalOpen()
    }
    get_all_user_assessment = async () => {
        const { userId } = this.state;
        const result = await this.props.client.query({
            query: GET_ALL_USER_ASSESSMENT,
            variables: { userId },
            fetchPolicy: "network-only",
        });
        return result.data.getAllUserAssessment;
    };

    checkRef = async () => {
        const result = await checkReference(localStorage.USER_NAME);
        console.log('Checking Reference for user ' + result);
    }

    handleImageLoaded() {
        console.log('Loaded');
        this.open();
        this.setState({ imageStatus: "loaded" });
    }

    handleImageErrored() {
        this.setState({ imageStatus: "failed to load" });
    }

    _checkGeneralInfo = () => {
        this.props.client.query({
            query: GET_GENERAL_INFO,
            fetchPolicy: 'network-only'
        }).then(result => {
            // console.log(result);

        }).catch(error => {
            console.log('there was an error calling general info');
            this.setState({ loading: false, });
        })
    }


    _nextWithoutUpdate = () => {
        this.props.history.push(`${this.props.match.url}/section1`);
    }

    showContent(response) {
        console.log(response);
    }

    _handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    componentDidMount() {
        window.scrollTo(0, 0)
        this.checkIfNeedOpenModal()

    }
    handleLoad = () => {
        console.log('loaded');
    };
    _newAssessment = () => {
        this.props.setFormStatus({ status: false, title: '', message: '' });
        this.props.setSuccessStatus({ status: false, title: '', message: '' });
        this.setState({ loading: true });
        this.props.client.mutate({
            mutation: ADD_NEW_ASSESSMENT,
        }).then(result => {
            console.log(result.data.addNewAssessment)
            this.setState({ loading: false });
            this.props.history.push(`${this.props.match.url}/new/section1`);
            // if (result.data.addNewAssessment === true) {
            //     console.log("true")
            //     this.props.history.push(`${this.props.match.url}/new/section1`);
            // } else {
            //     this.props.setFormStatus({
            //         status: true,
            //         title: "Oops!",
            //         message: "Locked!"

            //     });
            //     console.log("false")
            // }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });

    }
    _editAssessment = (submission, submissionType) => {
        if (submissionType === "LATEST") {
            this.props.history.push({ pathname: `${this.props.match.url}/edit/section1`, state: { submissionId: submission.id, submissionType: submissionType } });
        }
        if (submissionType === "HISTORY") {
            this.props.history.push({ pathname: `${this.props.match.url}/edit/section1`, state: { submissionId: submission.submissionId, submissionType: submissionType } });
        }

    }

    _viewAssessment = (submission, submissionType) => {
        if (submissionType === "LATEST") {
            this.props.history.push({ pathname: `${this.props.match.url}/view/section1`, state: { submissionId: submission.id, submissionType: submissionType } });
        }
        if (submissionType === "HISTORY") {
            this.props.history.push({ pathname: `${this.props.match.url}/view/section1`, state: { submissionId: submission.submissionId, submissionType: submissionType } });
        }

    }
    _downloadAssessment = (submission, submissionType) => {
        const submissionId = submissionType === "LATEST" ? submission.id : submission.submissionId;
        this.setState({ downloading: true, download_sub_id: submission.id })
        this.get_pdf(submissionId, submissionType).then(result => {
            const linkSource = `data:application/pdf;base64,${result}`;
            const downloadLink = document.createElement("a");
            const fileName = submission.assessmentName.concat(".pdf");//"download.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            this.setState({ downloading: false, download_sub_id: "" })
        })
    }

    _downloadCoppy = () => {
        this.setState({ downloadingCopy: true })
        const downloadLink = document.createElement("a");
        const fileName = "blank_copy.pdf";
        const blank_copy = files(`./${this.props.i18n.language === "en-US" ? "en" : this.props.i18n.language}/blank_copy.pdf`);
        downloadLink.href = blank_copy
        downloadLink.download = fileName;
        downloadLink.click();
        this.setState({ downloadingCopy: false })
    }

    get_pdf = async (submissionId, submissionType) => {
        const result = await this.props.client.query({
            query: DOWNLOAD_USER_PDF,
            variables: { submissionId: submissionId, submissionType: submissionType, lang: localStorage.RAT_LANG },
            fetchPolicy: 'network-only'
        });
        return result.data.downloadUserPDF;
    };
    get_all_submissions = async (submissionId) => {
        const result = await this.props.client.query({
            query: GET_ALL_EDIT_SUBMMISSIONS,
            variables: { submissionId: submissionId },
            fetchPolicy: 'network-only'
        });
        return result.data.getAllEditSubmissionsByUser;
    };

    _startLoading = () => {
        console.log("Loading.........")
    }

    handleSort = clickedColumn => {
        const { column, data, direction, assessments } = this.state
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                assessments: _.sortBy(assessments, [clickedColumn]),
                direction: 'ascending',
            })

            return
        }

        this.setState({
            assessments: assessments.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });

    };
    close = () => {
        this.saveModalOpen()
        this.setState({ modalVisible: false })
    }

    open = () => this.setState({ open: true })

    _showDeleteModal = (submission, submissionType) => {
        this.setState({ confirmDelete: true, deleteSubmissionId: submissionType === "LATEST" ? submission.id : submission.submissionId, deleteSubmissionType: submissionType })
    }

    handleConfirmDelete = () => {
        const { deleteSubmissionId, deleteSubmissionType } = this.state;
        this._delete_submission(deleteSubmissionId, deleteSubmissionType).then(result => {
            this._show_all_submissions();
            this.setState({ confirmDelete: false, deleteSubmissionId: "" })
        })
    }

    _delete_submission = async (submissionId, submissionType) => {
        const result = await this.props.client.mutate({
            mutation: DELETE_USER_SUBMMISSION,
            variables: { submissionId: submissionId, submissionType: submissionType }
        });
        return result.data.deleteSubmission;
    };

    handleCancelDelete = () => this.setState({ confirmDelete: false, deleteSubmissionId: "" })

    render() {
        const { formErrorStatus, formSuccessState, t, i18n } = this.props;
        const { assessments, status, column, data, direction, sections, activeIndex, download_sub_id, open, size, modalVisible, confirmDelete, deleteSubmissionId, navigation_img } = this.state
        var question_number = 0;
        return (
            <div>
                <div className="uk-section-default uk-section">
                    <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                    </div>
                    <Form success error>
                        <div
                            className="uk-container uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                            uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                        >
                            <div className="paper uk-link-reset " style={{ backgroundColor: "#686c6d" }}>
                                <div className="uk-grid uk-flex-middle">
                                    <div className="uk-width-5-6">
                                        {localStorage.RAT_LANG == 'ar' ? (
                                            <h3 className="uk-margin-remove" style={{ color: "#ffffff", fontSize: "1.4rem" }}>
                                                {" "}
                                                {t("Dashboard")}{" "}
                                            </h3>
                                        ) : (
                                            <h4 className="uk-margin-remove" style={{ color: "#ffffff" }}>
                                                {" "}
                                                {t("Dashboard")}{" "}
                                            </h4>
                                        )}


                                    </div>
                                </div>
                            </div>
                            <div className="uk-link-reset paper uk-margin-remove-bottom">
                                {/* General Details */}
                                <div
                                    id="register"
                                    className="animation: uk-animation-slide-left-medium"
                                >
                                    <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                                        {" "}
                                    </p>

                                    <div className="uk-grid button_div">
                                        {
                                            localStorage.RAT_LANG == 'ar' ? (
                                                <div className="uk-width-expand@m">
                                                    <div className="uk-form-label font_size_Arabic"><b>{t("My Assessments")}</b></div>
                                                </div>

                                            ) : (
                                                <div className="uk-width-expand@m">
                                                    <div className="uk-form-label"><b>{t("My Assessments")}</b></div>
                                                </div>

                                            )
                                        }

                                        < div className="uk-width-1-3@m">
                                        </div>

                                        <div className="uk-width-1-4@m">
                                            <div>
                                                <Button
                                                    type="submit"
                                                    onClick={(e) => this._newAssessment()}
                                                    // className="next-button purpleButton"
                                                    loading={this.state.loading}
                                                    className={localStorage.RAT_LANG == 'ar' ? "next-button purpleButton Dashboard_headings" : "next-button purpleButton"}
                                                    basic>{t("New Assessment")}</Button>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <Button
                                                    onClick={(e) => this._downloadCoppy()}
                                                    content={t("Blank Assessment")}
                                                    icon='right download'
                                                    labelPosition={localStorage.RAT_LANG == 'ar' ? 'left' : 'right'}
                                                    uk-tooltip={t("Blank Assessment")}
                                                    basic
                                                    className={localStorage.RAT_LANG == 'ar' ? 'Dashboard_headings' : ''}>

                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="uk-margin">
                                        {(formErrorStatus.status) ? (
                                            <div className="danger_alert" style={{ backgroundColor: "#da4a4a", borderColor: "#da4a4a", fontWeight: "bold", color: "#ffffff" }}>{formErrorStatus.message}</div>
                                        ) : ((formSuccessState.status) ? (
                                            <div className="success_alert">{formSuccessState.message}</div>
                                        ) : (''))}
                                    </div>
                                    {/* <div className="uk-margin"></div>                                     */}
                                    <Table sortable celled padded className={localStorage.RAT_LANG == 'ar' ? "table_style_arabic" : ""}>
                                        <Table.Header>
                                            <Table.Row style={{ fontSize: "15px" }} className={localStorage.RAT_LANG == 'ar' ? "font_size_Arabic" : ""}>
                                                <Table.HeaderCell sorted={column === 'assessmentName' ? direction : null}
                                                    onClick={(e) => this.handleSort('assessmentName')} style={{ borderLeft: "1px solid rgba(34,36,38,.1)" }}>{t("Assessment Name")}</Table.HeaderCell>
                                                <Table.HeaderCell sorted={column === 'status' ? direction : null}
                                                    onClick={(e) => this.handleSort('status')} >{t("Assessment Status")}</Table.HeaderCell>
                                                <Table.HeaderCell sorted={column === 'scoreDescription' ? direction : null}
                                                    onClick={(e) => this.handleSort('scoreDescription')}>{t("SE Readiness Status")}</Table.HeaderCell>
                                                <Table.HeaderCell style={{ minWidth: "100px" }} sorted={column === 'createdAt' ? direction : null}
                                                    onClick={(e) => this.handleSort('createdAt')}>{t("Created")}</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: "120px" }} sorted={column === 'updatedAt' ? direction : null}
                                                    onClick={(e) => this.handleSort('updatedAt')}>{t("Last Edited")}</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: "135px" }}>{t("Actions")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {this.state.submissionListLoading ? (
                                            <SubmissionsUserLoader />
                                        ) : (
                                            <Table.Body>
                                                {assessments.map((submission) => (
                                                    <Table.Row key={submission.id}>
                                                        <Table.Cell style={{ borderLeft: "1px solid rgba(34,36,38,.1)" }}>{submission.assessmentName}</Table.Cell>
                                                        <Table.Cell style={{ color: status[submission.status], fontWeight: "bold" }}>{t(submission.status)}</Table.Cell>
                                                        {/* <Table.Cell style={{ color: status[submission.status], fontWeight: "bold" }}>{submission.status === "DRAFT" ? <span>{t("DRAFT")}</span> : ""}{submission.status === "SUBMITTED" ? <span>{t("SUBMITTED")}</span> : ""}{submission.status === "REVIEWED" ? <span>{t("REVIEWED")}</span> : ""}{submission.status === "INACTIVE" ? <span>{t("INACTIVE")}</span> : ""}{submission.status === "FINAL" ? <span>{t("FINAL")}</span> : ""}{submission.status === "REOPENED" ? <span>{t("REOPENED")}</span> : ""}</Table.Cell> */}
                                                        <Table.Cell style={{ color: submission.scoreDescriptionColor, fontWeight: "bold" }}>{submission.status === "REVIEWED" || submission.status === "FINAL" ? (<span>{t(submission.seReadinessStatus)}{" "}<i class="fa fa-info-circle" aria-hidden="true" title={`${t(submission.statusClarification)}\n${t(submission.seHubSupportMessage)}\n`} uk-tooltip></i></span>) : ""}</Table.Cell>
                                                        <Table.Cell style={{ minWidth: "100px" }}>{localStorage.RAT_LANG == 'ar' ? (moment(submission.createdAt, "YYYY-MM-DD").format("YYYY-MM-DD")) : localStorage.RAT_LANG == 'en' ? (moment(submission.createdAt).format("DD-MMM-YYYY")) : (moment(submission.createdAt).format("DD-MM-YYYY"))}</Table.Cell>
                                                        <Table.Cell style={{ minWidth: "100px" }}>{localStorage.RAT_LANG == 'ar' ? (moment(submission.updatedAt, "YYYY-MM-DD").format("YYYY-MM-DD")) : localStorage.RAT_LANG == 'en' ? (moment(submission.updatedAt).format("DD-MMM-YYYY")) : (moment(submission.updatedAt).format("DD-MM-YYYY"))}</Table.Cell>
                                                        <Table.Cell textAlign='right' style={{ minWidth: "120px" }}>
                                                            {localStorage.RAT_LANG == 'ar' ? (
                                                                <Grid columns='equal'>
                                                                    <Grid.Row className="grid-column" style={{ overflow: "hidden", direction: "ltr" }}>



                                                                        <Grid.Column>
                                                                            <Button loading={download_sub_id === submission.id} onClick={(e) => this._downloadAssessment(submission, submission.type)} className="uk-button uk-button-danger purple-btn " uk-tooltip={t("Download")} title="" aria-expanded="false"> <i className="fas fa-download uk-visible@m"></i> </Button>
                                                                        </Grid.Column>

                                                                        {/* /// Delete */}

                                                                        <Grid.Column >

                                                                            {submission.status === "INACTIVE" ? (
                                                                                <button disabled class="uk-button uk-button-danger red-btn" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </button>
                                                                            ) : (
                                                                                localStorage.RAT_LANG == 'ar' ? (
                                                                                    <div>
                                                                                        <Button loading={deleteSubmissionId === submission.id} onClick={(e) => this._showDeleteModal(submission, submission.type)} className="uk-button uk-button-danger red-btn-active" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </Button>
                                                                                        <Confirm
                                                                                            className="ArabicFinishModal"
                                                                                            size='tiny'
                                                                                            open={confirmDelete}
                                                                                            onCancel={(e) => this.handleCancelDelete()}
                                                                                            onConfirm={(e) => this.handleConfirmDelete()}
                                                                                            content={t("Are you sure you want to delete this assessment?")}
                                                                                            confirmButton={t("Yes")}
                                                                                            cancelButton={t("No")}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <Button loading={deleteSubmissionId === submission.id} onClick={(e) => this._showDeleteModal(submission, submission.type)} className="uk-button uk-button-danger red-btn-active" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </Button>
                                                                                        <Confirm
                                                                                            size='tiny'
                                                                                            open={confirmDelete}
                                                                                            onCancel={(e) => this.handleCancelDelete()}
                                                                                            onConfirm={(e) => this.handleConfirmDelete()}
                                                                                            content={t("Are you sure you want to delete this assessment?")}
                                                                                            confirmButton={t("Yes")}
                                                                                            cancelButton={t("No")}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </Grid.Column>
                                                                        {/* //// Edit*/}
                                                                        <Grid.Column  >
                                                                            {["REVIEWED", "DRAFT"].includes(submission.status) ? (
                                                                                <button onClick={(e) => this._editAssessment(submission, submission.type)} class="uk-button uk-button-danger grey-btn" uk-tooltip={t("Edit")} title="" aria-expanded="false"> <i className="fas fa-pencil-alt uk-visible@m"></i> </button>
                                                                            ) : (
                                                                                <button disabled class="uk-button uk-button-danger grey-btn" uk-tooltip={t("Edit")} title="" aria-expanded="false"> <i className="fas fa-pencil-alt uk-visible@m"></i> </button>
                                                                            )}
                                                                        </Grid.Column>

                                                                        {/* /// View */}
                                                                        <Grid.Column >
                                                                            <button onClick={(e) => this._viewAssessment(submission, submission.type)} class="uk-button uk-button-danger light-blue-btn" uk-tooltip={t("View")} title="" aria-expanded="false"> <i className="fas fa-eye uk-visible@m"></i> </button>
                                                                        </Grid.Column>

                                                                    </Grid.Row>
                                                                </Grid>
                                                            ) : (
                                                                <Grid columns='equal'>
                                                                    <Grid.Row className="grid-column" style={{ overflow: "hidden" }}>
                                                                        <Grid.Column >
                                                                            <button onClick={(e) => this._viewAssessment(submission, submission.type)} class="uk-button uk-button-danger light-blue-btn" uk-tooltip={t("View")} title="" aria-expanded="false"> <i className="fas fa-eye uk-visible@m"></i> </button>
                                                                        </Grid.Column>
                                                                        <Grid.Column  >
                                                                            {["REVIEWED", "DRAFT"].includes(submission.status) ? (
                                                                                <button onClick={(e) => this._editAssessment(submission, submission.type)} class="uk-button uk-button-danger grey-btn" uk-tooltip={t("Edit")} title="" aria-expanded="false"> <i className="fas fa-pencil-alt uk-visible@m"></i> </button>
                                                                            ) : (
                                                                                <button disabled class="uk-button uk-button-danger grey-btn" uk-tooltip={t("Edit")} title="" aria-expanded="false"> <i className="fas fa-pencil-alt uk-visible@m"></i> </button>
                                                                            )}
                                                                        </Grid.Column>
                                                                        <Grid.Column >

                                                                            {submission.status === "INACTIVE" ? (
                                                                                <button disabled class="uk-button uk-button-danger red-btn" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </button>
                                                                            ) : (
                                                                                localStorage.RAT_LANG == 'ar' ? (
                                                                                    <div>
                                                                                        <Button loading={deleteSubmissionId === submission.id} onClick={(e) => this._showDeleteModal(submission, submission.type)} className="uk-button uk-button-danger red-btn-active" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </Button>
                                                                                        <Confirm
                                                                                            className="ArabicFinishModal"
                                                                                            size='tiny'
                                                                                            open={confirmDelete}
                                                                                            onCancel={(e) => this.handleCancelDelete()}
                                                                                            onConfirm={(e) => this.handleConfirmDelete()}
                                                                                            content={t("Are you sure you want to delete this assessment?")}
                                                                                            confirmButton={t("Yes")}
                                                                                            cancelButton={t("No")}
                                                                                        />
                                                                                    </div>
                                                                                ) : (

                                                                                    <div>
                                                                                        <Button loading={deleteSubmissionId === submission.id} onClick={(e) => this._showDeleteModal(submission, submission.type)} className="uk-button uk-button-danger red-btn-active" uk-tooltip={t("Delete")} title="" aria-expanded="false"> <i className="fas fa-trash uk-visible@m"></i> </Button>
                                                                                        <Confirm
                                                                                            size='tiny'
                                                                                            open={confirmDelete}
                                                                                            onCancel={(e) => this.handleCancelDelete()}
                                                                                            onConfirm={(e) => this.handleConfirmDelete()}
                                                                                            content={t("Are you sure you want to delete this assessment?")}
                                                                                            confirmButton={t("Yes")}
                                                                                            cancelButton={t("No")}
                                                                                        />
                                                                                    </div>)

                                                                            )}
                                                                        </Grid.Column>
                                                                        <Grid.Column>
                                                                            <Button loading={download_sub_id === submission.id} onClick={(e) => this._downloadAssessment(submission, submission.type)} className="uk-button uk-button-danger purple-btn " uk-tooltip={t("Download")} title="" aria-expanded="false"> <i className="fas fa-download uk-visible@m"></i> </Button>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            )}
                                                        </Table.Cell>

                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        )}
                                    </Table>
                                    {this.state.submissionListLoading ? (
                                        ""
                                    ) : (
                                        <div>
                                            <p><span style={{ color: "#DB2828", fontWeight: "bold" }}>{t("DRAFT")}</span> - {t("Assessment is in draft / preliminary version")}</p>
                                            <p><span style={{ color: "#28DB82", fontWeight: "bold" }}>{t("SUBMITTED")}</span> - {t("Submitted to SE Hub for review")}</p>
                                            <p><span style={{ color: "#EAAA00", fontWeight: "bold" }}>{t("REVIEWED")}</span> - {t("Reviewed and comments added by SE Hub")}</p>
                                            <p><span style={{ color: "#686C6D", fontWeight: "bold" }}>{t("INACTIVE")}</span> - {t("Inactive or Deleted")}</p>
                                            <p><span style={{ color: "#1A2674", fontWeight: "bold" }}>{t("FINAL")}</span> - {t("Assessment is finalized with reviews")}</p>
                                        </div>
                                    )}
                                    {/* <div className="uk-margin"></div>
                                    {(formErrorStatus.status) ? (
                                        <div className="danger_alert">{formErrorStatus.message}</div>
                                    ) : ((formSuccessState.status) ? (
                                        <div className="success_alert">{formSuccessState.message}</div>
                                    ) : (''))} */}
                                </div>
                                {/* General Details */}
                            </div>
                        </div>
                    </Form>
                </div >

                <Modal
                    size={size}
                    open={modalVisible}
                >
                    <Modal.Header style={{ textAlign: "right", fontSize: "1em", borderBottom: "0px", padding: "17px" }}><i aria-hidden="true" class="close icon" style={{ cursor: "pointer" }} onClick={this.close}></i></Modal.Header>
                    <Modal.Content image style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <Image className="uk-display-inline-block" uk-cover style={{ width: "100%" }} src={navigation_img} />
                    </Modal.Content>
                    <Modal.Actions style={{ borderTop: "0px" }}><i aria-hidden="true" class="close icon" style={{ cursor: "pointer" }} onClick={this.close}></i></Modal.Actions>
                    {/* <Modal.Actions>
                        <Button positive onClick={this.close}> {t("Ok")} </Button>
                    </Modal.Actions> */}
                </Modal>
            </div >
        );
    }
}

const styles = {
    stickyStyle: {
        zIndex: "980"
    },
    icon: {
        width: "0px"
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SectionHome))));
