import React, { Component, createRef } from "react";
import SectionLoader from "../SectionLoader";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Form,
  Button,
  TextArea,
  Input,
  Label,
  Loader,
  Message,
  Icon,
  Popup,
  Grid,
  Header,
  Dropdown,
  Progress, Sticky, Ref
} from "semantic-ui-react";
import { GET_SECTION_III } from "../../../../queries/SectionQueries";
import {
  GET_SECTION_1,
  SUBMIT_SECTION_1,
  GET_ALL_SUBMMISSIONS
} from "../../../../queries/SubmissionQueries";

import { USER_NAME } from "../../../../constants/index";

import { uploadFile, deleteFile } from "../../../../firebase/FileServices";
import { Promise } from "q";
import { setFormStatus, setSuccessStatus } from '../../../../actions/user-actions';
import { connect } from 'react-redux';
import sectionThreeQuestions from '../../../questions/section3_questions.json';
import { withNamespaces } from "react-i18next";

const mapStateToProps = (state, ownProps) => {
  return {
    formErrorStatus: state.custom_reducer.formErrorStatus,
    formSuccessState: state.custom_reducer.formSuccessState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFormStatus: (status) => {
      dispatch(setFormStatus(status))
    },
    setSuccessStatus: (status) => {
      dispatch(setSuccessStatus(status))
    }
  }
}

const employeeCountptions = [
  {
    key: "0",
    text: "0",
    value: "0"
  },
  {
    key: "1",
    text: "1",
    value: "1"
  },
  {
    key: "2",
    text: "2",
    value: "2"
  },
  {
    key: "3",
    text: "3",
    value: "3"
  },
  {
    key: "4",
    text: "4",
    value: "4"
  },
  {
    key: "5",
    text: "5",
    value: "5"
  },
  {
    key: "6",
    text: "6",
    value: "6"
  },
  {
    key: "7",
    text: "7",
    value: "7"
  },
  {
    key: "8",
    text: "8",
    value: "8"
  },
  {
    key: "9",
    text: "9",
    value: "9"
  },
  {
    key: "10",
    text: "10",
    value: "10"
  },
  {
    key: "11",
    text: "11",
    value: "11"
  },
  {
    key: "12",
    text: "12",
    value: "12"
  },
  {
    key: "13",
    text: "13",
    value: "13"
  },
  {
    key: "14",
    text: "14",
    value: "14"
  },
  {
    key: "15",
    text: "15",
    value: "15"
  },
  {
    key: "16",
    text: "16",
    value: "16"
  },
  {
    key: "17",
    text: "17",
    value: "17"
  },
  {
    key: "18",
    text: "18",
    value: "18"
  },
  {
    key: "19",
    text: "19",
    value: "19"
  },
  {
    key: "20",
    text: "20",
    value: "20"
  },
  {
    key: "21",
    text: "21",
    value: "21"
  }, {
    key: "22",
    text: "22",
    value: "22"
  }, {
    key: "23",
    text: "23",
    value: "23"
  }, {
    key: "24",
    text: "24",
    value: "24"
  }, {
    key: "25",
    text: "25",
    value: "25"
  }, {
    key: "26",
    text: "26",
    value: "26"
  }, {
    key: "27",
    text: "27",
    value: "27"
  }, {
    key: "28",
    text: "28",
    value: "28"
  }, {
    key: "29",
    text: "29",
    value: "29"
  }, {
    key: "30",
    text: "30",
    value: "30"
  }, {
    key: "31",
    text: "31",
    value: "31"
  }, {
    key: "32",
    text: "32",
    value: "32"
  }, {
    key: "33",
    text: "33",
    value: "33"
  }, {
    key: "34",
    text: "34",
    value: "34"
  }, {
    key: "35",
    text: "35",
    value: "35"
  }, {
    key: "36",
    text: "36",
    value: "36"
  }, {
    key: "37",
    text: "37",
    value: "37"
  }, {
    key: "38",
    text: "38",
    value: "38"
  }, {
    key: "39",
    text: "39",
    value: "39"
  }, {
    key: "40",
    text: "40",
    value: "40"
  }, {
    key: "41",
    text: "41",
    value: "41"
  }, {
    key: "42",
    text: "42",
    value: "42"
  }, {
    key: "43",
    text: "43",
    value: "43"
  }, {
    key: "44",
    text: "44",
    value: "44"
  }, {
    key: "45",
    text: "45",
    value: "45"
  }, {
    key: "46",
    text: "46",
    value: "46"
  }, {
    key: "47",
    text: "47",
    value: "47"
  }, {
    key: "48",
    text: "48",
    value: "48"
  }, {
    key: "49",
    text: "49",
    value: "49"
  }, {
    key: "50",
    text: "50",
    value: "50"
  }, {
    key: "51",
    text: "51",
    value: "51"
  }, {
    key: "52",
    text: "52",
    value: "52"
  }, {
    key: "53",
    text: "53",
    value: "53"
  }, {
    key: "54",
    text: "54",
    value: "54"
  }, {
    key: "55",
    text: "55",
    value: "55"
  }, {
    key: "56",
    text: "56",
    value: "56"
  }, {
    key: "57",
    text: "57",
    value: "57"
  }, {
    key: "58",
    text: "58",
    value: "58"
  }, {
    key: "59",
    text: "59",
    value: "59"
  }, {
    key: "60",
    text: "60",
    value: "60"
  }, {
    key: "61",
    text: "61",
    value: "61"
  }, {
    key: "62",
    text: "62",
    value: "62"
  }, {
    key: "63",
    text: "63",
    value: "63"
  }, {
    key: "64",
    text: "64",
    value: "64"
  }, {
    key: "65",
    text: "65",
    value: "65"
  }, {
    key: "66",
    text: "66",
    value: "66"
  }, {
    key: "67",
    text: "67",
    value: "67"
  }, {
    key: "68",
    text: "68",
    value: "68"
  }, {
    key: "69",
    text: "69",
    value: "69"
  }, {
    key: "70",
    text: "70",
    value: "70"
  }, {
    key: "71",
    text: "71",
    value: "71"
  }, {
    key: "72",
    text: "72",
    value: "72"
  }, {
    key: "73",
    text: "73",
    value: "73"
  }, {
    key: "74",
    text: "74",
    value: "74"
  }, {
    key: "75",
    text: "75",
    value: "75"
  }, {
    key: "76",
    text: "76",
    value: "76"
  }, {
    key: "77",
    text: "77",
    value: "77"
  }, {
    key: "78",
    text: "78",
    value: "78"
  }, {
    key: "79",
    text: "79",
    value: "79"
  }, {
    key: "80",
    text: "80",
    value: "80"
  }, {
    key: "81",
    text: "81",
    value: "81"
  }, {
    key: "82",
    text: "82",
    value: "82"
  }, {
    key: "83",
    text: "83",
    value: "83"
  }, {
    key: "84",
    text: "84",
    value: "84"
  }, {
    key: "85",
    text: "85",
    value: "85"
  }, {
    key: "86",
    text: "86",
    value: "86"
  }, {
    key: "87",
    text: "87",
    value: "87"
  }, {
    key: "88",
    text: "88",
    value: "88"
  }, {
    key: "89",
    text: "89",
    value: "89"
  }, {
    key: "90",
    text: "90",
    value: "90"
  }, {
    key: "91",
    text: "91",
    value: "91"
  }, {
    key: "92",
    text: "92",
    value: "92"
  }, {
    key: "93",
    text: "93",
    value: "93"
  }, {
    key: "94",
    text: "94",
    value: "94"
  }, {
    key: "95",
    text: "95",
    value: "95"
  }, {
    key: "96",
    text: "96",
    value: "96"
  }, {
    key: "97",
    text: "97",
    value: "97"
  }, {
    key: "98",
    text: "98",
    value: "98"
  }, {
    key: "99",
    text: "99",
    value: "99"
  }, {
    key: "100",
    text: "100",
    value: "100"
  }, {
    key: "101",
    text: "101",
    value: "101"
  }, {
    key: "102",
    text: "102",
    value: "102"
  }, {
    key: "103",
    text: "103",
    value: "103"
  }, {
    key: "104",
    text: "104",
    value: "104"
  }, {
    key: "105",
    text: "105",
    value: "105"
  }, {
    key: "106",
    text: "106",
    value: "106"
  }, {
    key: "107",
    text: "107",
    value: "107"
  }, {
    key: "108",
    text: "108",
    value: "108"
  }, {
    key: "109",
    text: "109",
    value: "109"
  }, {
    key: "110",
    text: "110",
    value: "110"
  }, {
    key: "111",
    text: "111",
    value: "111"
  }, {
    key: "112",
    text: "112",
    value: "112"
  }, {
    key: "113",
    text: "113",
    value: "113"
  }, {
    key: "114",
    text: "114",
    value: "114"
  }, {
    key: "115",
    text: "115",
    value: "115"
  }, {
    key: "116",
    text: "116",
    value: "116"
  }, {
    key: "117",
    text: "117",
    value: "117"
  }, {
    key: "118",
    text: "118",
    value: "118"
  }, {
    key: "119",
    text: "119",
    value: "119"
  }, {
    key: "120",
    text: "120",
    value: "120"
  }, {
    key: "121",
    text: "121",
    value: "121"
  }, {
    key: "122",
    text: "122",
    value: "122"
  }, {
    key: "123",
    text: "123",
    value: "123"
  }, {
    key: "124",
    text: "124",
    value: "124"
  }, {
    key: "125",
    text: "125",
    value: "125"
  }, {
    key: "126",
    text: "126",
    value: "126"
  }, {
    key: "127",
    text: "127",
    value: "127"
  }, {
    key: "128",
    text: "128",
    value: "128"
  }, {
    key: "129",
    text: "129",
    value: "129"
  }, {
    key: "130",
    text: "130",
    value: "130"
  }, {
    key: "131",
    text: "131",
    value: "131"
  }, {
    key: "132",
    text: "132",
    value: "132"
  }, {
    key: "133",
    text: "133",
    value: "133"
  }, {
    key: "134",
    text: "134",
    value: "134"
  }, {
    key: "135",
    text: "135",
    value: "135"
  }, {
    key: "136",
    text: "136",
    value: "136"
  }, {
    key: "137",
    text: "137",
    value: "137"
  }, {
    key: "138",
    text: "138",
    value: "138"
  }, {
    key: "139",
    text: "139",
    value: "139"
  }, {
    key: "140",
    text: "140",
    value: "140"
  }, {
    key: "141",
    text: "141",
    value: "141"
  }, {
    key: "142",
    text: "142",
    value: "142"
  }, {
    key: "143",
    text: "143",
    value: "143"
  }, {
    key: "144",
    text: "144",
    value: "144"
  }, {
    key: "145",
    text: "145",
    value: "145"
  }, {
    key: "146",
    text: "146",
    value: "146"
  }, {
    key: "147",
    text: "147",
    value: "147"
  }, {
    key: "148",
    text: "148",
    value: "148"
  }, {
    key: "149",
    text: "149",
    value: "149"
  }, {
    key: "150",
    text: "150",
    value: "150"
  }
];
class NewSection4 extends Component {
  contextRef = createRef()
  constructor(props) {
    super(props);

    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });

    this.state = {
      loading: true,
      spinner: false,
      saveNContinue: false,
      sectionEmpty: true,
      regexp: /[\+\-\.\,\e\E]$/,
      section: {
        id: "",
        sectionNumber: "",
        title: "",
        subTitle: "",
        description: "",
        sequence: "",
        createdAt: "",
        updatedAt: ""
      },
      // question1: {
      //   showFollowupQuestion: true,
      //   primaryQuestion: "Number of employees currently in service?",
      //   primaryAnswer: "",
      //   secondaryQuestion: "",
      //   secondaryAnswer: "",
      //   primaryJson: { coreStaff: "", projectBased: "" },
      //   tertiaryQuestion: "Part time",
      //   tertiaryAnswer: "0",
      //   weightFactor: "-0.2",
      //   uploads: [],
      //   validated: false,
      //   feedBack: "",
      //   score: "",
      //   isScore: true
      // },
      // question2: {
      //   showFollowupQuestion: false,
      //   primaryQuestion: "Does your organization have a staff recruitment policy? ",
      //   primaryAnswer: false,
      //   secondaryQuestion:
      //     "If Yes, please attach a copy of the policy. If No, specify the reasons.",
      //   secondaryAnswer: "",
      //   uploads: [],
      //   fileUrl: null,
      //   fileRef: null,
      //   tempFile: "",
      //   weightFactor: "0.2",
      //   isUploading: false,
      //   validated: false,
      //   feedBack: "",
      //   score: "",
      //   isScore: false
      // },
      // question3: {
      //   showFollowupQuestion: true,
      //   primaryQuestion: "Does your organization have a Human Resources (HR) Division or personnel working on HR?",
      //   primaryAnswer: false,
      //   secondaryQuestion: "If No, specify the reason.",
      //   secondaryAnswer: "",
      //   weightFactor: "0.2",
      //   uploads: [],
      //   validated: false,
      //   feedBack: "",
      //   score: "",
      //   isScore: false
      // },
      // question4: {
      //   showFollowupQuestion: false,
      //   primaryQuestion:
      //     "Does your organization have human resource management processes/policies in place?",
      //   primaryAnswer: false,
      //   secondaryQuestion: "",
      //   secondaryAnswer: "",
      //   weightFactor: "0.2",
      //   uploads: [],
      //   validated: true,
      //   feedBack: "",
      //   score: "",
      //   isScore: false
      // },
      // question5: {
      //   showFollowupQuestion: true,
      //   primaryQuestion:
      //     "What is the annual staff turnover in your organization for the last 3 years?",
      //   primaryAnswer: "",
      //   secondaryQuestion: "",
      //   secondaryAnswer: "",
      //   primaryJson: { Year1: "", Year2: "", Year3: "" },
      //   weightFactor: "-0.4",
      //   uploads: [],
      //   validated: true,
      //   feedBack: "",
      //   score: "",
      //   isScore: true
      // },
      // question6: {
      //   showFollowupQuestion: true,
      //   primaryQuestion:
      //     "What is the academic/professional qualification of the Executive Director/CEO of your organization?",
      //   primaryAnswer: "",
      //   secondaryQuestion:
      //     "",
      //   secondaryAnswer: "",
      //   weightFactor: "-0.3",
      //   uploads: [],
      //   validated: false,
      //   feedBack: "",
      //   score: "",
      //   isScore: true
      // },
      // question7: {
      //   showFollowupQuestion: false,
      //   primaryQuestion:
      //     "Does your organization employ a head of finance/finance officer?",
      //   primaryAnswer: false,
      //   secondaryQuestion: "What is their academic/professional qualification in finance?",
      //   secondaryAnswer: "",
      //   weightFactor: "0.3",
      //   uploads: [],
      //   validated: true,
      //   feedBack: "",
      //   score: "",
      //   isScore: false
      // },
      // question8: {
      //   showFollowupQuestion: false,
      //   primaryQuestion:
      //     "Are there employees at mid-management level and above who have business degrees or are experienced in business activities?",
      //   primaryAnswer: false,
      //   secondaryQuestion: "If Yes, in what capacity does the employee work at your organization? Will they support the social enterprise? How?",
      //   secondaryAnswer: "",
      //   weightFactor: "0.3",
      //   uploads: [],
      //   validated: true,
      //   feedBack: "",
      //   score: "",
      //   isScore: false
      // },
      ...sectionThreeQuestions,
      sectionFeedBack: "",
      validation_arr: [],
      progress: 0,
      formChanged: false
    };

    this._getSection3();
    this._getSection3Submissions();

  }
  read_progress = async () => {
    this.setState({
      progress: 0,
      validation_arr: []
    });
    this.get_all_submissions().then(submission => {
      submission.map(item => {
        Object.keys(item.submission).map(key => {
          if (item.submission[key]['validated'] !== undefined && item.submission[key]['validated'] === true) {
            this.state.validation_arr.push(item.submission[key]['validated']);
          }
        })
      });
      const percent_progress = parseFloat((this.state.validation_arr.length / 48) * 100).toFixed(0)
      this.setState({
        progress: percent_progress
      });
    });
  }
  get_all_submissions = async () => {
    const result = await this.props.client.query({
      query: GET_ALL_SUBMMISSIONS,
      fetchPolicy: 'network-only'
    });
    return result.data.getAllSubmissionsByUser;
  };
  async componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
    this.timerID = setInterval(
      () => { if (this.state.formChanged === true) { this._updateSection(true) } },//this._updateSection(),
      5000
    );
    await this.read_progress()
  }
  componentWillUnmount() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
  }
  _goBack = () => {
    this.props.history.goBack();
  };

  _next = () => {
    this.setState({
      saveNContinue: true
    });
    this._submitSection(true, false);
  };

  _nextWithoutUpdate = () => {
    this.props.history.push(`/assessment/new/section5`);
  };

  _updateSection = (autoMode) => {
    this.setState({
      spinner: true
    });
    this._submitSection(false, autoMode);
  };

  _submitSection = (flag, autoMode) => {
    this.props.setFormStatus({ status: false, title: '', message: '' });
    this.props.setSuccessStatus({ status: false, title: '', message: '' });


    const submission = {
      question1: this.state.question1,
      question2: this.state.question2,
      question3: this.state.question3,
      question4: this.state.question4,
      question5: this.state.question5,
      question6: this.state.question6,
      question7: this.state.question7,
      question8: this.state.question8
    };

    this._saveSection(submission, flag, autoMode);
  };

  _saveSection = (submission, flag, autoMode) => {
    this.props.client.mutate({
      mutation: SUBMIT_SECTION_1,
      variables: {
        submission: submission,
        sectionId: "ck1gm0lvs16l60b8869dwmzb3",
        actionType: flag
      }
    }).then(result => {
      if (!autoMode) {
        this._getSection3Submissions();
      }
      // this._getSection3Submissions();
      this.setState({
        spinner: false,
        saveNContinue: false,
        formChanged: false,
        sectionEmpty: false
      });

      this.props.setSuccessStatus({
        status: true,
        title: "",
        message: autoMode ? "Saved!" : "Saved successfully!"
      });
      this.read_progress()
      if (flag) {
        this.props.history.push(`/assessment/new/section5`);
      }
    }).catch(error => {
      this.setState({
        spinner: false,
        saveNContinue: false,
      });

      this.props.setFormStatus({
        status: true,
        title: "Oops!",
        message:
          "There was an error while trying to update data"
      });
    });
  };

  _getSection3 = () => {
    this.props.client
      .query({
        query: GET_SECTION_III
      })
      .then(result => {
        this.setState({
          section: { ...result.data.getSection1 }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  _getSection3Submissions = () => {
    this.props.client
      .query({
        query: GET_SECTION_1,
        variables: { sectionId: "ck1gm0lvs16l60b8869dwmzb3" },
        fetchPolicy: 'network-only'
      })
      .then(result => {
        console.log(result);
        if (
          result.data.getSectionSubmission === null ||
          result.data.getSectionSubmission.length === 0
        ) {
          console.log("there is nothing here");
        } else {
          // Apply values
          this.setState({
            question1: result.data.getSectionSubmission.submission.question1
              ? { ...result.data.getSectionSubmission.submission.question1, weightFactor: this.state.question1.weightFactor }
              : this.state.question1,
            question2: result.data.getSectionSubmission.submission.question2
              ? { ...result.data.getSectionSubmission.submission.question2, weightFactor: this.state.question2.weightFactor }
              : this.state.question2,
            question3: result.data.getSectionSubmission.submission.question3
              ? { ...result.data.getSectionSubmission.submission.question3, weightFactor: this.state.question3.weightFactor }
              : this.state.question3,
            question4: result.data.getSectionSubmission.submission.question4
              ? { ...result.data.getSectionSubmission.submission.question4, weightFactor: this.state.question4.weightFactor }
              : this.state.question4,
            question5: result.data.getSectionSubmission.submission.question5
              ? { ...result.data.getSectionSubmission.submission.question5, weightFactor: this.state.question5.weightFactor }
              : this.state.question5,
            question6: result.data.getSectionSubmission.submission.question6
              ? { ...result.data.getSectionSubmission.submission.question6, weightFactor: this.state.question6.weightFactor }
              : this.state.question6,
            question7: result.data.getSectionSubmission.submission.question7
              ? { ...result.data.getSectionSubmission.submission.question7, weightFactor: this.state.question7.weightFactor }
              : this.state.question7,
            question8: result.data.getSectionSubmission.submission.question8
              ? { ...result.data.getSectionSubmission.submission.question8, weightFactor: this.state.question8.weightFactor }
              : this.state.question8,
            sectionEmpty: false
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  _sec4qus1onchange = (event, data) => {
    this.setState({
      question1: {
        ...this.state.question1,
        [data.id]: data.value
      },
      formChanged: true
    });
  };

  _question1jsonOnchange = (event, data) => {
    this.setState({
      question1: {
        ...this.state.question1,
        primaryJson: {
          ...this.state.question1.primaryJson,
          [data.id]: data.value,
        },
        validated: (this.state.question1.primaryJson.coreStaff === "" && this.state.question1.primaryJson.projectBased === "") ? false : true
      },
      formChanged: true
    });
  };

  _question1Onchange = event => {
    this.setState({
      question1: {
        ...this.state.question1,
        [event.target.id]: event.target.value
      },
      formChanged: true
    });
  };

  // // Question1 Stuff
  // _question1Followup = state => {
  //   state
  //     ? this.setState({
  //         question1: {
  //           ...this.state.question1,
  //           showFollowupQuestion: true,
  //           primaryAnswer: true
  //         }
  //       })
  //     : this.setState({
  //         question1: {
  //           ...this.state.question1,
  //           showFollowupQuestion: false,
  //           primaryAnswer: false
  //         }
  //       });
  // };

  // _question1Onchange = event => {
  //   this.setState({
  //     question1: {
  //       ...this.state.question1,
  //       [event.target.id]: event.target.value
  //     }
  //   });
  // };

  // Question 2 stuff
  _question2Followup = state => {
    state
      ? this.setState({
        question2: {
          ...this.state.question2,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: (this.state.question2.fileUrl === null || this.state.question2.fileUrl === undefined) ? false : true
        },
        formChanged: true
      })
      : this.setState({
        question2: {
          ...this.state.question2,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: this.state.question2.secondaryAnswer.trim() === "" ? false : true
        },
        formChanged: true
      });
  };

  _question2FileOnchange = event => {
    this.setState({
      question2: {
        ...this.state.question2,
        tempFile: event.target.files[0],
        isUploading: true,
      },
      formChanged: true
    });
    this._startUpload(event.target.files[0]);
    this.setState({
      isUploading: true
    });
  };

  _startUpload = async file => {
    const result = await uploadFile(file, localStorage.USER_NAME);
    if (result.status) {
      this.setState({
        isUploading: false,
        question2: {
          ...this.state.question2,
          fileRef: "uploads/" + localStorage.USER_NAME + "/" + file.name,
          fileUrl: result.url,
          isUploading: false,
          validated: true
        },
        formChanged: true
      });
    }
  };

  _deleteFile = async path => {
    const deleteRef = await deleteFile(this.state.question2.fileRef);
    this.setState({
      question2: {
        ...this.state.question2,
        validated: false
      },
      formChanged: true
    });
    return deleteRef;
  };
  _question2Onchange = event => {
    this.setState({
      question2: {
        ...this.state.question2,
        secondaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      },
      formChanged: true
    });
  };

  // Question 3 stuff
  _question3Followup = state => {
    state
      ? this.setState({
        question3: {
          ...this.state.question3,
          showFollowupQuestion: false,
          primaryAnswer: true,
          validated: true
        },
        formChanged: true
      })
      : this.setState({
        question3: {
          ...this.state.question3,
          showFollowupQuestion: true,
          primaryAnswer: false,
          validated: this.state.question3.secondaryAnswer.trim() === "" ? false : true
        },
        formChanged: true
      });
  };

  _question3Onchange = event => {
    this.setState({
      question3: {
        ...this.state.question3,
        secondaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      },
      formChanged: true
    });
  };

  //Question 4 stuff
  _question4Followup = state => {
    state
      ? this.setState({
        question4: {
          ...this.state.question4,
          showFollowupQuestion: true,
          primaryAnswer: true
        },
        formChanged: true
      })
      : this.setState({
        question4: {
          ...this.state.question4,
          showFollowupQuestion: false,
          primaryAnswer: false
        },
        formChanged: true
      });
  };
  // _question4FileOnchange = event => {
  //   this.setState({
  //     question4: {
  //       ...this.state.question4,
  //       tempFile: event.target.files[0]
  //     }
  //   });
  //   console.log(event.target.files[0]);
  //   console.log(this.state);
  //   this._startUpload(event.target.files[0]);
  //   this.setState({
  //     isUploading: true
  //   });
  // };

  // _startUpload = async file => {
  //   const result = await uploadFile(file, localStorage.USER_NAME);
  //   if (result.status) {
  //     this.setState({
  //       isUploading: false,
  //       question4: {
  //         ...this.state.question4,
  //         fileRef: "uploads/" + localStorage.USER_NAME + "/" + file.name,
  //         fileUrl: result.url
  //       }
  //     });
  //   }
  // };

  // _deleteFile = async path => {
  //   const deleteRef = await deleteFile(this.state.question2.fileRef);
  //   return deleteRef;
  // };

  _question4Onchange = event => {
    this.setState({
      question4: {
        ...this.state.question4,
        secondaryAnswer: event.target.value
      },
      formChanged: true
    });
  };

  // Question 5 stuff
  _question5Followup = state => {
    state
      ? this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: true,
          primaryAnswer: true
        },
        formChanged: true
      })
      : this.setState({
        question5: {
          ...this.state.question5,
          showFollowupQuestion: false,
          primaryAnswer: false
        },
        formChanged: true
      });
  };

  _question5jsonOnchange = e => {
    if (e.target.value > 100 || e.target.value < 0) {
      e.preventDefault();
    } else {
      let fields = this.state.question5.primaryJson;
      fields[e.target.id] = e.target.value;
      this.setState({
        question5: {
          ...this.state.question5,
          validated: (this.state.question5.primaryJson.Year1.trim() === "" || this.state.question5.primaryJson.Year2.trim() === "" || this.state.question5.primaryJson.Year3.trim() === "") ? false : true
        },
        formChanged: true
      });
      this.setState({ fields });

      // this.setState({
      //   question5: {
      //     ...this.state.question5,
      //     primaryJson: {
      //       ...this.state.question5.primaryJson,
      //       [e.target.id]: e.target.value
      //     }
      //   }
      // });

    }
  };

  _question5FileOnchange = event => {
    console.log("file selected");
  };
  _question5Onchange = event => {
    this.setState({
      question5: {
        ...this.state.question5,
        secondaryAnswer: event.target.value
      },
      formChanged: true
    });
  };

  //Question 6 stuff
  _question6Followup = state => {
    state
      ? this.setState({
        question6: {
          ...this.state.question6,
          showFollowupQuestion: true,
          primaryAnswer: true
        },
        formChanged: true
      })
      : this.setState({
        question6: {
          ...this.state.question6,
          showFollowupQuestion: false,
          primaryAnswer: false
        },
        formChanged: true
      });
  };
  _question6Onchange = event => {
    this.setState({
      question6: {
        ...this.state.question6,
        primaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      },
      formChanged: true
    });
  };

  //Question 7 Stuff
  _question7Followup = state => {
    state
      ? this.setState({
        question7: {
          ...this.state.question7,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question7.secondaryAnswer.trim() === "" ? false : true
        },
        formChanged: true
      })
      : this.setState({
        question7: {
          ...this.state.question7,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        },
        formChanged: true
      });
  };
  _question7Onchange = event => {
    this.setState({
      question7: {
        ...this.state.question7,
        secondaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      },
      formChanged: true
    });
  };

  // Question 8 stuff
  _question8Followup = state => {
    state
      ? this.setState({
        question8: {
          ...this.state.question8,
          showFollowupQuestion: true,
          primaryAnswer: true,
          validated: this.state.question8.secondaryAnswer.trim() === "" ? false : true
        },
        formChanged: true
      })
      : this.setState({
        question8: {
          ...this.state.question8,
          showFollowupQuestion: false,
          primaryAnswer: false,
          validated: true
        },
        formChanged: true
      });
  };
  _question8Onchange = event => {
    this.setState({
      question8: {
        ...this.state.question8,
        secondaryAnswer: event.target.value,
        validated: event.target.value.trim() === "" ? false : true
      },
      formChanged: true
    });
  };

  // Question 9 stuff
  _question9Followup = state => {
    state
      ? this.setState({
        question9: {
          ...this.state.question9,
          showFollowupQuestion: false,
          primaryAnswer: true
        },
        formChanged: true
      })
      : this.setState({
        question9: {
          ...this.state.question9,
          showFollowupQuestion: false,
          primaryAnswer: false
        },
        formChanged: true
      });
  };

  // Question 10 stuff
  _qeustion10OnChange = event => {
    this.setState({
      question10: {
        ...this.state.question10,
        primaryAnswer: event.target.value
      },
      formChanged: true
    });
  };

  //Question 11 stuff
  _qeustion11OnChange = event => {
    this.setState({
      question11: {
        ...this.state.question11,
        primaryAnswer: event.target.value
      },
      formChanged: true
    });
  };

  _qeustion12OnChange = event => {
    this.setState({
      question12: {
        ...this.state.question12,
        primaryAnswer: event.target.value
      },
      formChanged: true
    });
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  _pagi = (path) => {
    this.props.history.push({ pathname: "/assessment/new/".concat(path) });
  }
  render() {
    const { formErrorStatus, formSuccessState, t, i18n } = this.props;
    const { regexp, progress } = this.state;
    return (
      <div>
        {this.state.loading ? (
          <SectionLoader />
        ) : (
          <div>
            <Ref innerRef={this.contextRef}>
              {/* Begin Dark Header */}
              <div className="uk-section-default uk-section">
                <div className="uk-container-small uk-margin-auto uk-margin-medium-top">
                  <h3 className=" uk-text-light">
                    {" "}
                    {t("Section")} {this.state.section.sectionNumber}{" "}
                    {/* {this.state.section.title}{" "} */}
                  </h3>
                </div>
                <hr />

                <div
                  className="uk-container-small uk-margin-auto uk-margin-small-top uk-padding-small paper-read"
                  uk-scrollspy="target: > .paper; cls:uk-animation-slide-bottom-small; delay: 200"
                >
                  <Sticky context={this.contextRef}>
                    <div className="paper uk-link-reset" style={{ backgroundColor: "#00A9E0" }}>
                      <div className="uk-grid uk-flex-middle">
                        <div className="uk-width-1-6 uk-flex-first uk-text-center">
                          <i className="far fa-comment-alt icon-xxlarge"></i>
                        </div>
                        <div className="uk-width-5-6">
                          {localStorage.RAT_LANG == 'ar' ? (
                            <h3 className="uk-margin-remove" style={{ color: "#ffffff", fontSize: "1.4rem" }} >
                              {" "}
                              {t(this.state.section.title)}{" "}
                            </h3>
                          ) : (
                            <h4 className="uk-margin-remove" style={{ color: "#ffffff" }} >
                              {" "}
                              {t(this.state.section.title)}{" "}
                            </h4>
                          )}

                          {/* <p className="uk-margin-remove">
                          {" "}
                          {this.state.section.description}{" "}
                        </p> */}
                        </div>
                      </div>
                    </div>

                    <Progress style={{ margin: 0 }} percent={progress} progress indicating size='medium' />
                  </Sticky>
                  <Form success error className="uk-margin">
                    {/* Begin Question 1 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>19)</b>{" "}
                        {t("Number of employees currently in service?")}{" "}
                      </h4>
                      {this.state.question1.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            {t("Core staff")}{":  "}
                            {" "}
                            <Dropdown
                              className={localStorage.RAT_LANG == 'en' ? "dropdown_english" : localStorage.RAT_LANG == 'fr' ? "dropdown_french" : localStorage.RAT_LANG == 'sp' ? "dropdown_spanish" : "dropdown_arabic"}
                              // style={{ marginLeft: "37px" }}
                              onChange={this._question1jsonOnchange}
                              name="coreStaff"
                              id="coreStaff"
                              selection
                              value={this.isEmpty(this.state.question1.primaryJson) ? (
                                null
                              ) : (
                                this.state.question1.primaryJson.coreStaff
                              )}
                              placeholder={t("Select count")}
                              options={employeeCountptions}
                            />
                          </div>
                          <div className="uk-form-label">
                            {t("Project-based")}{":  "}
                            <Dropdown
                              // style={{ marginLeft: "35px" }}
                              className={localStorage.RAT_LANG == 'en' ? "dropdown_two_english" : localStorage.RAT_LANG == 'fr' ? "dropdown_two_french" : localStorage.RAT_LANG == 'sp' ? "dropdown_two_spanish" : "dropdown_two_arabic"}
                              onChange={this._question1jsonOnchange}
                              name="projectBased"
                              id="projectBased"
                              selection
                              value={this.isEmpty(this.state.question1.primaryJson) ? (
                                null
                              ) : (
                                this.state.question1.primaryJson.projectBased
                              )}
                              placeholder={t("Select count")}
                              options={employeeCountptions}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* End Question 1 */}

                    {/* Begin Question2 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4><b>20)</b> {t("Does your organization have a staff recruitment policy?")}  </h4>
                      {localStorage.RAT_LANG == 'ar' ? (
                        <Button.Group size="large" className="buttons_arabic_style" >


                          <Button positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(false)}>
                            {t("NO")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(true)}>
                            {t("YES")}
                          </Button>
                        </Button.Group>
                      ) : (
                        <Button.Group size="large" >
                          <Button positive={this.state.question2.showFollowupQuestion ? true : false} onClick={() => this._question2Followup(true)}>
                            {t("YES")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question2.showFollowupQuestion ? false : true} onClick={() => this._question2Followup(false)}>
                            {t("NO")}
                          </Button>
                        </Button.Group>
                      )}

                      {this.state.question2.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            {t("If Yes, please attach a copy of the policy.")}
                          </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            {/* File Component */}
                            {this.state.question2.fileUrl !== null &&
                              this.state.question2.fileUrl !== undefined ? (
                              <div className="file-upload-group">
                                <Popup
                                  trigger={
                                    <Button
                                      className="file-icon"
                                      circular
                                      icon="attach"
                                    />
                                  }
                                  flowing
                                  hoverable
                                >
                                  <Grid centered divided columns={2}>
                                    <Grid.Column textAlign="center">
                                      <Button
                                        circular
                                        icon="download"
                                        onClick={() => {
                                          window.open(
                                            this.state.question2.fileUrl,
                                            "_blank"
                                          );
                                        }}
                                      />
                                    </Grid.Column>
                                    <Grid.Column textAlign="center">
                                      <Button
                                        circular
                                        icon="trash alternate outline"
                                        onClick={() => {
                                          this._deleteFile(
                                            this.state.question2.fileRef
                                          ).then(result => {
                                            console.log(result);
                                            this.setState({
                                              question2: {
                                                ...this.state.question2,
                                                fileUrl: null,
                                                fileRef: null
                                              }
                                            });
                                          });
                                        }}
                                      />
                                    </Grid.Column>
                                  </Grid>
                                </Popup>
                              </div>
                            ) : (
                              <Input
                                onChange={this._question2FileOnchange}
                                name="q4upload"
                                id="q4upload"
                                value={this.state.question2.uploads}
                                className="text-area-height uk-input uk-form-width-large"
                                placeholder={t("Upload here")}
                                type="file"
                              />
                            )}

                            {/* File Component */}
                          </div>
                          {this.state.question2.isUploading ? (
                            <Message icon>
                              <Icon name="circle notched" loading />
                              <Message.Content>
                                <Message.Header>{t("Just a moment…")}</Message.Header>
                                {t("We are uploading your document.")}
                              </Message.Content>
                            </Message>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className="uk-form-label">
                            {t("If No, specify the reasons.")}
                          </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question2Onchange}
                              name="q2secondaryAnswer"
                              id="q2secondaryAnswer"
                              value={this.state.question2.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder={t("Describe here")}
                              type="text"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* End Question 2 */}

                    {/* Begin Question 3 */}

                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4><b>21)</b> {t("Does your organization have a Human Resources (HR) Division or personnel working on HR?")} </h4>
                      {localStorage.RAT_LANG == 'ar' ? (
                        <Button.Group size="large" className="buttons_arabic_style">
                          <Button positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(false)}>
                            {t("NO")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(true)}>
                            {t("YES")}
                          </Button>
                        </Button.Group>
                      ) : (
                        <Button.Group size="large" >
                          <Button positive={this.state.question3.showFollowupQuestion ? false : true} onClick={() => this._question3Followup(true)}>
                            {t("YES")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question3.showFollowupQuestion ? true : false} onClick={() => this._question3Followup(false)}>
                            {t("NO")}
                          </Button>
                        </Button.Group>
                      )}

                      {this.state.question3.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            {t("If No, specify the reason.")}
                          </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question3Onchange}
                              name="q3secondaryAnswer"
                              id="q3secondaryAnswer"
                              value={this.state.question3.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder={t("Describe here")}
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* End Question 3 */}

                    {/* Begin Question 4 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>22)</b>{" "}
                        {t("Does your organization have human resource management processes/policies in place?")}{" "}
                      </h4>
                      {localStorage.RAT_LANG == 'ar' ? (
                        <Button.Group size="large" className="buttons_arabic_style" >


                          <Button positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                            {t("NO")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                            {t("YES")}
                          </Button>
                        </Button.Group>
                      ) : (
                        <Button.Group size="large" >
                          <Button positive={this.state.question4.showFollowupQuestion ? true : false} onClick={() => this._question4Followup(true)}>
                            {t("YES")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question4.showFollowupQuestion ? false : true} onClick={() => this._question4Followup(false)}>
                            {t("NO")}
                          </Button>
                        </Button.Group>
                      )}

                      {this.state.question4.showFollowupQuestion ? (
                        ""
                        // <div>
                        //   <div className="uk-form-label">
                        // </div>
                        //   <div className="uk-margin">
                        //     <span className="uk-form-icon">
                        //       <i className="far fa-User icon-medium"></i>
                        //     </span>

                        //     <TextArea
                        //       onChange={this._question4Onchange}
                        //       name="q4secondaryAnswer"
                        //       id="q4secondaryAnswer"
                        //       value={this.state.question4.secondaryAnswer}
                        //       className="text-area-height uk-input uk-form-width-large"
                        //       placeholder="Describe here"
                        //       type="text"
                        //     />
                        //   </div>
                        // </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* End Question 4 */}

                    {/* Begin Question 5 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>23)</b>{" "}
                        {t("What is the annual staff turnover in your organization for the last 3 years?")}{" "}
                      </h4>
                      {this.state.question5.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            <text className={localStorage.RAT_LANG == 'ar' ? "text_arabic" : "text_others"}> {t("Year")} 1{"  :  "}</text>
                            <Input
                              className={localStorage.RAT_LANG == 'ar' ? "year_input_arabic" : "year_input_others"}
                              labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                              type="number"
                              placeholder=""
                              id="Year1"
                              name="Year1"
                              value={this.isEmpty(this.state.question5.primaryJson) ? (
                                null
                              ) : (
                                this.state.question5.primaryJson.Year1
                              )}
                              onChange={(e) => this._question5jsonOnchange(e)}
                              onKeyDown={(e) => {
                                if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                  e.preventDefault();
                                }
                              }}>
                              <input />
                              <Label basic>%</Label>
                            </Input>
                          </div>
                          <div className="uk-form-label">
                            <text className={localStorage.RAT_LANG == 'ar' ? "text_arabic" : "text_others"}> {t("Year")} 2{"  :  "} </text>
                            <Input
                              style={{ width: "90px" }}
                              labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                              type="number"
                              placeholder=""
                              id="Year2"
                              name="Year2"
                              value={this.isEmpty(this.state.question5.primaryJson) ? (
                                null
                              ) : (
                                this.state.question5.primaryJson.Year2
                              )}
                              onChange={(e) => this._question5jsonOnchange(e)}
                              onKeyDown={(e) => {
                                if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                  e.preventDefault();
                                }
                              }}
                            >
                              <input />
                              <Label basic>%</Label>
                            </Input>
                          </div>
                          <div className="uk-form-label">
                            <text className={localStorage.RAT_LANG == 'ar' ? "text_arabic" : "text_others"}> {t("Year")} 3{"  :  "} </text>
                            <Input
                              style={{ width: "90px" }}
                              labelPosition={localStorage.RAT_LANG == 'ar' ? "left" : "right"}
                              type="number"
                              placeholder=""
                              id="Year3"
                              name="Year3"
                              value={this.isEmpty(this.state.question5.primaryJson) ? (
                                null
                              ) : (
                                this.state.question5.primaryJson.Year3
                              )}
                              onChange={(e) => this._question5jsonOnchange(e)}
                              onKeyDown={(e) => {
                                if (/[\+\-\.\,]/.test(e.key) || e.key.toUpperCase() === "E") {
                                  e.preventDefault();
                                }
                              }}
                            >
                              <input />
                              <Label basic>%</Label>
                            </Input>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* End Question 5 */}

                    {/* Begin Question 6 */}
                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>24)</b>{" "}
                        {t("What is the academic/professional qualification of the Executive Director/CEO of your organization?")}{" "}
                      </h4>
                      <p class="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">({t("eg")}: {t("Medical, Marketing, Legal, Finance, etc.")})</p>

                      <div>
                        <div className="uk-margin">
                          <span className="uk-form-icon">
                            <i className="far fa-User icon-medium"></i>
                          </span>

                          <TextArea
                            onChange={this._question6Onchange}
                            name="q6secondaryAnswer"
                            id="q6secondaryAnswer"
                            value={this.state.question6.primaryAnswer}
                            className="text-area-height uk-input uk-form-width-large"
                            placeholder={t("Describe here")}
                            type="text"
                          />
                        </div>
                      </div>

                    </div>
                    {/* End Question 6 */}

                    {/* Begin Question 7 */}

                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>25)</b>{" "}
                        {t("Does your organization employ a head of finance/finance officer?")}{" "}
                      </h4>
                      {localStorage.RAT_LANG == 'ar' ? (
                        <Button.Group size="large" className="buttons_arabic_style" >


                          <Button positive={this.state.question7.showFollowupQuestion ? false : true} onClick={() => this._question7Followup(false)}>
                            {t("NO")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question7.showFollowupQuestion ? true : false} onClick={() => this._question7Followup(true)}>
                            {t("YES")}
                          </Button>
                        </Button.Group>
                      ) : (
                        <Button.Group size="large" >
                          <Button positive={this.state.question7.showFollowupQuestion ? true : false} onClick={() => this._question7Followup(true)}>
                            {t("YES")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question7.showFollowupQuestion ? false : true} onClick={() => this._question7Followup(false)}>
                            {t("NO")}
                          </Button>
                        </Button.Group>
                      )}

                      {this.state.question7.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            {t("What is their academic/professional qualification in finance?")}
                          </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question7Onchange}
                              name="q7secondaryAnswer"
                              id="q7secondaryAnswer"
                              value={this.state.question7.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder={t("Describe here")}
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                      }
                    </div>
                    {/* End Question 7 */}

                    {/* Begin Question 8 */}

                    <div className="uk-link-reset paper uk-margin s1q1">
                      <h4>
                        <b>26)</b>{" "}
                        {t("Are there employees at mid-management level and above who have business degrees or are experienced in business activities?")}{" "}
                      </h4>
                      {localStorage.RAT_LANG == 'ar' ? (
                        <Button.Group size="large" className="buttons_arabic_style" >


                          <Button positive={this.state.question8.showFollowupQuestion ? false : true} onClick={() => this._question8Followup(false)}>
                            {t("NO")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question8.showFollowupQuestion ? true : false} onClick={() => this._question8Followup(true)}>
                            {t("YES")}
                          </Button>
                        </Button.Group>
                      ) : (
                        <Button.Group size="large" >
                          <Button positive={this.state.question8.showFollowupQuestion ? true : false} onClick={() => this._question8Followup(true)}>
                            {t("YES")}
                          </Button>
                          {/* <Button.Or /> */}
                          <Button positive={this.state.question8.showFollowupQuestion ? false : true} onClick={() => this._question8Followup(false)}>
                            {t("NO")}
                          </Button>
                        </Button.Group>
                      )}

                      {this.state.question8.showFollowupQuestion ? (
                        <div>
                          <div className="uk-form-label">
                            {t("If Yes, in what capacity does the employee work at your organization? Will they support the social enterprise? How?")}
                          </div>
                          <div className="uk-margin">
                            <span className="uk-form-icon">
                              <i className="far fa-User icon-medium"></i>
                            </span>

                            <TextArea
                              onChange={this._question8Onchange}
                              name="q8primaryAnswer"
                              id="q8primaryAnswer"
                              value={this.state.question8.secondaryAnswer}
                              className="text-area-height uk-input uk-form-width-large"
                              placeholder={t("Describe here")}
                              type="text"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* End Question 8 */}

                    <div className="uk-margin">
                      {(formErrorStatus.status) ? (
                        localStorage.RAT_LANG == 'ar' ? (
                          <div className="danger_alert" style={{ paddingRight: "12px" }}>{t(formErrorStatus.message)}</div>
                        ) : (
                          <div className="danger_alert">{t(formErrorStatus.message)}</div>
                        )
                      ) : ((formSuccessState.status) ? (
                        localStorage.RAT_LANG == 'ar' ? (
                          <div className="success_alert" style={{ paddingRight: "12px" }}>{t(formSuccessState.message)}</div>
                        ) : (
                          <div className="success_alert">{t(formSuccessState.message)}</div>
                        )
                      ) : (''))}
                    </div>

                    <div className="uk-text-center uk-grid uk-grid-small uk-child-width-1-6 uk-margin-small quick-nav" uk-grid>
                      <div>
                        <div className="uk-card">
                          <li><a className={localStorage.RAT_LANG == "fr" ? "fr_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1" : localStorage.RAT_LANG == 'ar' ? "Ar_MA_contact uk-button uk-button-default uk-button-small uk-width-1-1" : localStorage.RAT_LANG == "sp" ? "uk-button uk-button-default uk-button-small uk-width-1-1 sp_MA_contact" : "uk-button uk-button-default uk-button-small uk-width-1-1"} onClick={(e) => this._pagi("section1")} href="javascript:void(0)">{t("MA Contact")}</a></li>
                        </div>
                      </div>
                      <div>
                        <div className="uk-card">
                          <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section2")} href="javascript:void(0)">{t("Operations")}</a></li>
                        </div>
                      </div>
                      <div>
                        <div className="uk-card">
                          <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section3")} href="javascript:void(0)">{t("Financial")}</a></li>
                        </div>
                      </div>
                      <div>
                        <div className="uk-card">
                          <li className="uk-active"><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section4")} href="javascript:void(0)">{t("HR")}</a></li>
                        </div>
                      </div>
                      <div>
                        <div className="uk-card">
                          <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section5")} href="javascript:void(0)">{t("Resources")}</a></li>
                        </div>
                      </div>
                      <div>
                        <div className="uk-card">
                          <li><a className="uk-button uk-button-default uk-button-small uk-width-1-1" onClick={(e) => this._pagi("section6")} href="javascript:void(0)">{t("Other")}</a></li>
                        </div>
                      </div>
                    </div>

                    <hr />
                    {/* Begin Footer */}
                    <div className="uk-grid uk-text-center button_div">
                      <div className="uk-width-1-3@m">
                        <Button
                          basic
                          className="outline-button blueButton"
                          onClick={this._goBack}
                        >
                          {t("Go Back")}
                        </Button>
                      </div>

                      <div className="uk-width-expand@m">
                        {!this.state.sectionEmpty ? (
                          <Button
                            basic
                            onClick={(e) => this._updateSection(false)}
                            className="outline-button yellowButton"
                            loading={this.state.spinner}
                          >
                            {t("Save")}
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="uk-width-1-3@m">
                        {!this.state.sectionEmpty ? (
                          <div>
                            <Button
                              // onClick={this._nextWithoutUpdate}
                              onClick={this._next}
                              className="next-button purpleButton"
                              loading={this.state.saveNContinue}
                              basic
                            >
                              {t("Save and Next")}
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              onClick={this._next}
                              basic
                              className="outline-button "
                              loading={this.state.saveNContinue}
                            >
                              {t("Save & Continue")}
                            </Button>
                          </div>
                        )}

                        {/* <div class="spinner"></div>  */}
                      </div>
                    </div>
                    {/* End Footer */}
                  </Form>
                </div>
              </div>
              {/* End Dark Header */}
            </Ref>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(NewSection4))));
